import React, { Component } from 'react'
import { connect } from 'react-redux'
import api from '../util/api_v5'
import Api from 'api'
import FaxContent from './FaxContent'
import FaxSelector from './FaxSelector'
import ConfirmDeleteModal from 'confirm-delete-modal'
import PhoneComUser from 'phone-com-user'
import { updateFaxes, switchFax, addFax, updateFax, deleteFax, updateFaxLinks, addContactsToFaxes, removeContactFromFaxes } from '../actions/faxes'
import { switchExtension, setIsFaxConfigured } from '../actions/pdcuser'
import { switchView } from '../actions/view.js'
import ResizeAware from 'react-resize-aware'
import { setSmallView } from '../actions/view'
import LoaderFull from 'loader-full'
import { withStyles } from '@material-ui/core'
import {pushNotification} from 'notification-pusher'
import NewFaxPanel from './NewFaxPanel'
import StartNewButton from 'start-new-button'
import { theme } from 'get-theme'
let threshold = 768

const mapStateToProps = state => ({
	currentFax:				state.currentFax,
	faxes:					state.faxes,
	smallView:				state.smallView,
	currentView:			state.currentView,
	currentExtensionRedux:	state.currentExtension,
	isFaxConfigured:		state.isFaxConfigured
})

const mapDispatchToProps = dispatch => ({
	updateFaxes:			faxes		=> dispatch(updateFaxes(faxes)),
	switchFax:				fax			=> dispatch(switchFax(fax)),
	addFax:					fax			=> dispatch(addFax(fax)),
	updateFax:				fax			=> dispatch(updateFax(fax)),
	deleteFax:				fax			=> dispatch(deleteFax(fax)),
	setSmallView:			boolVal		=> dispatch(setSmallView(boolVal)),
	switchView:				view		=> dispatch(switchView(view)),
	switchExtension:		extension	=> dispatch(switchExtension(extension)),
	setIsFaxConfigured:		isConf		=> dispatch(setIsFaxConfigured(isConf)),
	updateFaxLinks:			links		=> dispatch(updateFaxLinks(links)),
	addContactsToFaxes:		contacts	=> dispatch(addContactsToFaxes(contacts)),
	removeContactFromFaxes:	contactId	=> dispatch(removeContactFromFaxes(contactId))
})

const styles = theme => ({
	appWrapper: {
		display: 'flex',
		height: '100%'
	},
	loadingDiv: theme.loadingDiv,
	faxesPanel: {
		display:		'flex',
		flexDirection:	'column',
		minWidth:	theme.selector.width,
		boxShadow:		'0 0 0 1px #ccd6db',
		'&.small-view': {
			width:	'100%'
		},
		'&:not(.small-view)': {
			maxWidth:		theme.selector.width,
		}
	}
})

class App extends Component {

	constructor(props) {
		super(props)

		this.state = {
			loading:						false,
			loadedOnce:						false,
			isConfirmDeleteFaxModalShown:	false,
			newFax:							null,
			switchTab:						null,
			deletedIncomingFaxes:			{num: 0, randomString: ''},
			deletedOutgoingFaxes:			{num: 0, randomString: ''}
		}

		this.props.resetSubscription(true)
		if (props.extension && props.extension.extension_id !== PhoneComUser.getExtensionId()) {
			PhoneComUser.changeExtension(props.extension.extension_id)
		}
	}

	componentDidMount() {
		this._ismounted = true
		this.props.subscribeForNotifications('fax', this.faxUpdate, true)
		this.initialLoad()
		this.checkIfFaxConfigured()
		this.props.contactsUtil.reload()
	}

	componentWillUnmount() {
		this._ismounted = false
	}

	checkIfFaxConfigured = () => {
		api.isFaxConfigured().then(response => {
			console.log('isFaxConfigured returns:', response)
			if (!this._ismounted) return
			this.props.setIsFaxConfigured(Boolean(response))
		})
	}

	componentDidUpdate(prevProps) {
		if (this.props.extension && this.props.extension.extension_id !== PhoneComUser.getExtensionId()) {
			PhoneComUser.changeExtension(this.props.extension.extension_id)
			let phoneNumbers = Object.keys(this.props.extension.phone_number)
			PhoneComUser.changePhoneNumber(!phoneNumbers.length ? '' : phoneNumbers)
			this.props.switchView('select')
			this.props.contactsUtil.reload()
			this.props.resetSubscription(true)
			this.checkIfFaxConfigured()
			this.initialLoad(true)
		}

		if (this.props.currentFax &&
			(!prevProps.currentFax || (prevProps.currentFax.id !== this.props.currentFax.id)) &&
			this.props.currentFax.is_new &&
			!this.props.currentFax.justSent
		) {
			this.changeReadStatus()
		}

		if (prevProps.isOffline && !this.props.isOffline) this.adjustView()

		this.updateContactsInfoInFaxes()
	}

	updateContactsInfoInFaxes = () => {
		if (!this.props.contactsUtil.extraContactsLoaded) return
		let numNewContacts	= 0
		let faxesIncoming	= this.props.faxes['fax-incoming']
		let faxesOutgoing	= this.props.faxes['fax-outgoing']
		if (!faxesIncoming || !faxesOutgoing) return
		let faxItemsArrays	= [faxesIncoming.items, faxesOutgoing.items]
		faxItemsArrays.forEach(faxItems => {
			let contactIdsToBeRemoved = []
			faxItems.forEach(fax => {
				let theOtherParticipant = fax.direction === 'in' ? 'from' : 'to'
				let extraContact = this.props.contactsUtil.extraContacts.find(contact => contact.numbers.find(n => n.number === fax[theOtherParticipant].number))
				if (extraContact && !fax[theOtherParticipant].contact_id) {
					numNewContacts++
				} else if (!extraContact && fax[theOtherParticipant].contact_id) {
					contactIdsToBeRemoved.push(fax[theOtherParticipant].contact_id)
				}
			})
			if (numNewContacts > 0) {
				this.props.addContactsToFaxes(this.props.contactsUtil.extraContacts)
			}
			if (contactIdsToBeRemoved.length) {
				contactIdsToBeRemoved.forEach(contactId => this.props.removeContactFromFaxes(contactId))
			}
		})
	}

	adjustView = () => {
		this.initialLoad(true)
	}

	initialLoad = force => {
		let extensionSwitched = Boolean(!this.props.currentExtensionRedux || this.props.extension.extension_id !== this.props.currentExtensionRedux.extension_id)
		if (!extensionSwitched && (this.props.faxes['fax-incoming'] && (this.props.faxes['fax-incoming'].items || this.props.faxes['fax-outgoing'].items)) && !force) return this.setState({loadedOnce: true})
		if (extensionSwitched) {
			this.props.switchExtension(this.props.extension)
			this.props.updateFaxLinks({})
		}

		this.setState({loading: true})
		// limit so based on height so it always gets filled
		let limit = Math.max(parseInt(window.innerHeight / 50), 15)
		Promise.all([
			api.loadFaxes(0, limit, {message_type: 'fax-incoming'}),
			api.loadFaxes(0, limit, {message_type: 'fax-outgoing'})
		]).then(async responses => {
			if (!this._ismounted) return // console.log('Faxes App.js got unmounted')
			if (responses[0] === 'network-error' || responses[1] === 'network-error') return
			this.props.updateFaxes(responses[0])
			this.props.updateFaxes(responses[1])
			await this.loadExtraContacts()
			this.setState({loading: false, loadedOnce: true})
			this.props.onLoaded()

			let faxesIncoming = this.props.faxes['fax-incoming']
			let faxesOutgoing = this.props.faxes['fax-outgoing']
			if (this.props.faxes && (faxesIncoming.items || faxesOutgoing.items)) {
				if (this.openUrlFax()) return
			}

			if (this.props.smallView) {
				return this.props.switchFax()
			}

			let themeDefaultTab	= theme.faxesApp.tabs.defaultTab
			let notDefaultTab	= themeDefaultTab === 'sent' ? 'received' : 'sent'
			this.setState({switchTab: notDefaultTab}, () => this.setState({switchTab: themeDefaultTab}))
			let shownFaxes = themeDefaultTab === 'sent' ? faxesOutgoing : faxesIncoming
			if (shownFaxes) {
				let fax = shownFaxes.items.length ? shownFaxes.items[0] : null
				this.props.switchFax(fax)
			}
		})
	}

	loadExtraContacts = async () => {
		this.props.addContactsToFaxes(this.props.contactsUtil.extraContacts)
		let incomingFaxes		= this.props.faxes['fax-incoming']
		let incomingFaxItems	= incomingFaxes.items
		let outgoingFaxes		= this.props.faxes['fax-outgoing']
		let outgoingFaxItems	= outgoingFaxes.items
		let phoneNumbers		= []
		// Collect all of the phone numbers which are not connected to a contact
		incomingFaxItems.forEach(f => {
			if (f.from.contact_id) return
			let extraContacts = this.props.contactsUtil.extraContacts
			let alreadyLoaded = false
			extraContacts.forEach(ec => {
				ec.numbers.forEach(ecn => ecn.number === f.from.number ? alreadyLoaded = true : null)
			})
			if (!alreadyLoaded) phoneNumbers.push(f.from.number)
		})
		outgoingFaxItems.forEach(f => {
			if (f.to.contact_id) return
			let extraContacts = this.props.contactsUtil.extraContacts
			let alreadyLoaded = false
			extraContacts.forEach(ec => {
				ec.numbers.forEach(ecn => ecn.number === f.to.number ? alreadyLoaded = true : null)
			})
			if (!alreadyLoaded) phoneNumbers.push(f.to.number)
		})
		if (phoneNumbers.length === 0) return
		// Remove duplicates
		phoneNumbers	= Array.from(new Set(phoneNumbers))
		let filters		= {keyword: phoneNumbers}
		await this.props.contactsUtil.loadExtraContacts(filters)
		this.props.addContactsToFaxes(this.props.contactsUtil.extraContacts)
	}

	openUrlFax = () => {
		// If the url path links to a fax then open it
		let pathname		= window.location.pathname
		let pathnameSplit	= pathname.split('/').filter(e => e)
		let faxId			= undefined
		if (pathnameSplit.length > 2 && pathnameSplit[1] === 'faxes') {
			faxId = pathnameSplit[2].substring(1)
		}
		let incomingFaxItems = this.props.faxes['fax-incoming'].items
		let outgoingFaxItems = this.props.faxes['fax-outgoing'].items
		if (!incomingFaxItems && !outgoingFaxItems) return false

		let pathFax = incomingFaxItems.find(v => `${v.id}` === faxId)
		if (pathFax) {
			this.props.switchFax(pathFax)
			this.setState({switchTab: 'received'})
			this.props.switchView('content')
			return true
		}
		pathFax = outgoingFaxItems.find(v => `${v.id}` === faxId)
		if (pathFax) {
			this.props.switchFax(pathFax)
			this.setState({switchTab: 'sent'})
			this.props.switchView('content')
			return true
		}
		return false
	}

	faxUpdate = updatedFax => {

		updatedFax.direction = updatedFax.fax_type === 'fax-incoming' ? 'in' : 'out'

		if (updatedFax.type === 'delete') {
			return this.faxDeleted(updatedFax)
		}

		if (updatedFax.type === 'read_status') {
			let faxIds		= updatedFax.fax_id
				faxIds.forEach(faxId => {
				let isNew	= updatedFax.is_new
				let fax		= this.props.faxes[updatedFax.fax_type].items.find(updatedFax => updatedFax.id === faxId)
				if (!fax || Boolean(fax.is_new) === Boolean(isNew)) return
				this.changeReadStatus(fax, false)
				if (fax.id === this.props.currentFax.id) this.props.switchFax(fax)
			})
			return
		}

		if (updatedFax.type === 'delivery_status') {
			let faxId	= updatedFax.id
			let fax		= this.props.faxes[updatedFax.fax_type].items.find(updatedFax => updatedFax.id === faxId)
			if (!fax) return
			fax.delivery_status		= updatedFax.delivery_status
			fax.delivery_message	= updatedFax.delivery_message
			delete fax.justSent
			this.props.updateFax(fax)
			if (updatedFax.delivery_status === 'sent') {
				fax.is_new = true
				this.changeReadStatus(fax, false)
			}
			if (this.props.currentFax && fax.id === this.props.currentFax.id) this.props.switchFax(fax)
			return
		}

		let incomingFaxItems = this.props.faxes['fax-incoming'].items
		for (let fax of incomingFaxItems) {
			if (fax.id === updatedFax.id) {
				return this.faxUpdated(updatedFax)
			}
		}
		let outgoingFaxItems = this.props.faxes['fax-outgoing'].items
		for (let fax of outgoingFaxItems) {
			if (fax.id === updatedFax.id) {
				return this.faxUpdated(updatedFax)
			}
		}
		this.newFaxReceived(updatedFax)
	}

	newFaxReceived = fax => {
		this.props.addFax(fax)
		this.setState({newFax: fax.id})

		if (window.V5PHONECOM.features.has('fax')) {
			let extensionId	= this.props.extension.extension_id
			let faxId		= fax.id
			let url			= `https://${window.document.location.host}/e${extensionId}/fax/f${faxId}`
			let title		= 'New fax from: ' + fax.fromNumber
			pushNotification(title, fax.transcript || '', url,null,'My Phone.com')
		}
	}

	setNoNewFax = () => {
		this.setState({newFax: null})
	}

	faxUpdated = fax => {
		this.props.updateFax(fax)
	}

	faxDeleted = fax => {
		let currentFaxId	= this.props.currentFax.id
		let currentFaxType	= this.props.currentFax.direction === 'out' ? 'fax-outgoing' : 'fax-incoming'
		let faxIds			= fax.fax_ids.map(f => `${f}`)
		let incomingFaxes	= this.props.faxes['fax-incoming'].items.filter(f => faxIds.includes(`${f.id}`))
		let outgoingFaxes	= this.props.faxes['fax-outgoing'].items.filter(f => faxIds.includes(`${f.id}`))
		incomingFaxes.forEach(f => this.props.deleteFax(f))
		outgoingFaxes.forEach(f => this.props.deleteFax(f))

		if (faxIds.includes(`${currentFaxId}`)) {
			let faxItems = this.props.faxes[currentFaxType].items
			let switchFax = null
			if (faxItems.length) switchFax = faxItems[0]
			this.props.switchFax(switchFax)
		}

		let deletedIncomingFaxes = {num: incomingFaxes.length, randomString: `${Math.floor(Math.random() * 1000000)}${(new Date()).getTime()}`}
		let deletedOutgoingFaxes = {num: outgoingFaxes.length, randomString: `${Math.floor(Math.random() * 1000000)}${(new Date()).getTime()}`}
		if (incomingFaxes.length) this.setState({deletedIncomingFaxes})
		if (outgoingFaxes.length) this.setState({deletedOutgoingFaxes})
	}

	handleResize = size => {
		let needToExpand = this.props.smallView && ((this.props.standalone && size.width >= threshold) || !this.props.screenViewType.isMobileView)
		let needToShrink = !this.props.smallView && ( (this.props.standalone && size.width < threshold) || this.props.screenViewType.isMobileView)
		if (needToShrink) {
			this.props.setSmallView(true)
		} else if (needToExpand) {
			this.props.setSmallView(false)
		}
	}

	deleteFax = async () => {
		let currentFax		= this.props.currentFax
		let currentFaxId	= currentFax.id
		let incomingFaxes	= this.props.faxes['fax-incoming']
		let outgoingFaxes	= this.props.faxes['fax-outgoing']
		let isIncoming		= currentFax.direction === 'in'

		this.props.deleteFax(currentFax)
		let faxItems	= isIncoming ? incomingFaxes.items : outgoingFaxes.items
		let switchFax	= null
		if (faxItems.length > 0) {
			switchFax	= faxItems[0]
			if (switchFax.id === currentFaxId) {
				if (faxItems.length > 1) {
					switchFax = faxItems[1]
				} else {
					switchFax = null
				}
			}

			this.props.switchFax(switchFax)
		}

		await api.deleteFax(currentFax.id, currentFax.direction)
		this.setState({deletedFaxes: {id: currentFaxId, direction: isIncoming ? 'in' : 'out'}})

		if (isIncoming) {
			let deletedIncomingFaxes = {num: 1, randomString: `${Math.floor(Math.random() * 1000000)}${(new Date()).getTime()}`}
			this.setState({deletedIncomingFaxes})
		} else {
			let deletedOutgoingFaxes = {num: 1, randomString: `${Math.floor(Math.random() * 1000000)}${(new Date()).getTime()}`}
			this.setState({deletedOutgoingFaxes})
		}
	}

	changeReadStatus = (fax, backendChange=true) => {
		fax			= fax || this.props.currentFax
		let isNew	= !fax.is_new
		fax.is_new	= isNew
		this.props.changeFaxReadStatus(isNew ? 'unread' : 'read', 1)
		let faxType	= fax.direction === 'in' ? 'fax-incoming' : 'fax-outgoing'
		if (backendChange) {
			api.markFaxRead(fax.id, isNew, faxType).then(statusChanged => {
				if (!statusChanged) return console.error(`Error while marking fax ${isNew ? '' : 'un'}read`)
				this.props.updateUnreadCounts()
			})
		} else {
			this.props.updateUnreadCounts()
		}
		this.props.updateFax(fax)
		let faxes		= this.props.faxes[`fax-${fax.direction === 'in' ? 'incom' : 'outgo'}ing`]
		faxes.unread	= faxes.unread + (isNew ? + 1 : - 1)
		this.props.updateFaxes(faxes)
		if (this.props.currentFax && fax.id === this.props.currentFax.id) this.props.switchFax(fax)
	}

	onCreateFaxClick = () => {
		this.props.switchView('new_fax')
		this.props.switchFax()
		let tagsInput = document.getElementById('tags-input')
		if (tagsInput) tagsInput.focus()
	}

	renderFaxSelector = () => {
		const { classes } = this.props
		let hiddenClass = ''
		if (this.props.smallView && this.props.currentView !== 'select') hiddenClass = 'hidden'
		return (
			<div className={`${classes.faxesPanel} ${hiddenClass} ${this.props.smallView ? 'small-view' : ''}`}>
				<StartNewButton app='faxes' title='Send New Fax' onClick={this.onCreateFaxClick}/>
				<FaxSelector
					newFax					= {this.state.newFax}
					setNoNewFax				= {this.setNoNewFax}
					smallView				= {this.props.smallView}
					screenViewType			= {this.props.screenViewType}
					deleteFax				= {this.deleteFax}
					changeReadStatus		= {this.changeReadStatus}
					switchTab				= {this.state.switchTab}
					isFaxConfigured			= {this.props.isFaxConfigured}
					extraContacts			= {this.props.contactsUtil.extraContacts}
					loadExtraContacts		= {this.loadExtraContacts}
					deletedIncomingFaxes	= {this.state.deletedIncomingFaxes}
					deletedOutgoingFaxes	= {this.state.deletedOutgoingFaxes}
				/>
			</div>
		)
	}

	render() {
		let { classes } = this.props
		return (
			<div className='App'>
				<ResizeAware
					style		= {{height: this.props.standalone ? 'calc(100% - 60px)' : '100%'}}
					onResize	= {this.handleResize}
				>
					{this.state.loading ?
						<div className={classes.loadingDiv}>
							<LoaderFull styles={{loaderFull: {left: this.props.smallView ? '50%' : 'calc(50% + 120px)'}}} size='big'/>
						</div>
					: null}
					{this.state.loadedOnce &&
						<div className={classes.appWrapper}>
							{this.renderFaxSelector()}
							{this.props.currentView === 'new_fax' ?
								<NewFaxPanel
									switchTab			= {tab => this.setState({switchTab: tab})}
									extension			= {this.props.extension}
									contactsUtil		= {this.props.contactsUtil}
									loadExtraContacts	= {this.loadExtraContacts}
								/>
							: null}
							{(!this.props.smallView && ['content', 'select'].includes(this.props.currentView)) || this.props.currentView === 'content' ?
								<FaxContent
									extension			= {this.props.extension}
									deleteFax			= {this.deleteFax}
									changeReadStatus	= {this.changeReadStatus}
									redirect			= {this.props.redirect}
									extraContacts		= {this.props.contactsUtil.extraContacts}
									contactGroupTypes	= {this.props.contactsUtil.groupTypes}
									updateContact		= {this.props.contactsUtil.updateContact}
									deleteContact		= {this.props.contactsUtil.deleteContact}
									addGroup			= {this.props.contactsUtil.addGroup}
									makeCall			= {this.props.makeCall}
								/>
							: null}
						</div>
					}
				</ResizeAware>
				<ConfirmDeleteModal
					itemType					= 'fax'
					isShown						= {this.state.isConfirmDeleteFaxModalShown}
					acknowledgedTitle			= 'Permanently delete fax?'
					notAcknowledgedTitle		= 'Permanently delete fax?'
					acknowledgedMainContent		= ''
					notAcknowledgedMainContent	= ''
				/>
			</div>
		)
	}
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))