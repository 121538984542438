import { withStyles } from "@material-ui/core"
import { theme } from "get-theme"
import React, { Component } from "react"
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk"
import CallMergeIcon from "@material-ui/icons/CallMerge"

import Timer from "timer"

const styles = (theme) => ({
	container: {
		width: "100%",
		height: 30,
		maxHeight: 30,
		textAlign: "center",
		color: "white",
		// TODO: not in pallete, changing
		// backgroundColor: "#5ABF46",
		// backgroundColor: theme.palette.primary["0"],
		position: "relative",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	activeCallBar: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		// TODO: not in pallete, changing
		// backgroundColor: "#5ABF46",
		backgroundColor: theme.palette.primary["0"],
		'&:active': {
			backgroundColor: theme.palette.primary["-100"],
			opacity: 0.65
		}
	},
	pendingCallBar: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: theme.palette.important["0"],
		'&:active': {
			backgroundColor: theme.palette.important["-100"],
			opacity: 0.65
		}
	}
})

class CallBarActiveCallBar extends Component {
	renderActiveCallState = () => {
		const { currentCall, classes } = this.props
		if (currentCall) {
			// active call active - green
			if(currentCall.callState === 'ACTIVE'){
				return (
					<div className={classes.activeCallBar} onClick={this.onTap} data-test-id={'call-bar-active-call-bar'}>
						<div>
							{currentCall.participants.length > 1 ? (
								<CallMergeIcon style={{ width: 22, height: 22 }} />
								
							) : (
								<PhoneInTalkIcon style={{ width: 22, height: 22 }} />
							)}
						</div>

						<div style={{ marginLeft: 10, marginRight: 10, fontSize: 14 }}>{"Active Call"}</div>
						<div style={{ fontSize: 12 }}>
							{currentCall && currentCall.callState === "ACTIVE" ? (
								<Timer startTime={currentCall.callStartTime} />
							) : null}
						</div>
					</div>
				)
			}
			else if(currentCall.callState === 'INACTIVE'){
				return (
					<div className={classes.pendingCallBar} onClick={this.onTap} data-test-id={'call-bar-active-call-bar'}>
						<div>
							<PhoneInTalkIcon style={{ width: 22, height: 22 }} />
						</div>
						<div style={{ marginLeft: 10, marginRight: 10, fontSize: 14 }}>{"Ringing"}</div>
					</div>
				)
			}
			else if(currentCall.callState === 'CONNECTING'){
				return (
					<div className={classes.pendingCallBar} onClick={this.onTap} data-test-id={'call-bar-active-call-bar'}>
						<div>
							<PhoneInTalkIcon style={{ width: 22, height: 22 }} />
						</div>
						<div style={{ marginLeft: 10, marginRight: 10, fontSize: 14 }}>{"Connecting"}</div>
					</div>
				)
			}
		}

		// if (true) {
		// 	// active call on hold?
		// 	return (<div></div>);
		// }

		if (this.props.backgroundCalls.length > 0) {
			// no active call but background calls exist
			return (
				<div className={classes.pendingCallBar} onClick={this.onTap} data-test-id={'call-bar-active-call-bar'}>
					<div>
						<PhoneInTalkIcon style={{ width: 28, height: 28 }} />
					</div>

					<div style={{ marginLeft: 10, marginRight: 10, fontSize: 14 }}>{"Pending Calls"}</div>
					<div style={{ fontSize: 12 }}>
						{currentCall && currentCall.callState === "ACTIVE" ? (
							<Timer startTime={currentCall.callStartTime} />
						) : null}
					</div>
				</div>
			)
		}
	}

	onTap = () => {
		this.props.toggleShowCallSessionSelector()
	}
	
	render() {
		const { classes, currentCall, visible } = this.props
		return <>{visible ? <div className={classes.container}>{this.renderActiveCallState()}</div> : null}</>
	}
}
export default withStyles(styles)(CallBarActiveCallBar)
