import React, { Component } from 'react'
import { connect } from 'react-redux'
import api from '../util/api_v5.js'
import CallSelectorItem from './CallSelectorItem.js'
import { updateCalls, updateCall, switchCall } from '../actions/calls'
import { switchView } from '../actions/view'
import ScrollerWrapper from 'scroller-wrapper'
import { withStyles } from '@material-ui/core'
import gtmDataPush from 'gtm-events'

const GTM_APP_NAME = 'calls-selector'
const GTM_MAP = {ALREADY_ACTIVE: 0, NOT_ACTIVE: 1}

const mapStateToProps = state => ({
	calls:			state.calls,
	currentCall:	state.currentCall
})

const mapDispatchToProps = dispatch => ({
	updateCalls:	calls => dispatch(updateCalls(calls)),
	updateCall:		call => dispatch(updateCall(call)),
	switchCall:		call => dispatch(switchCall(call)),
	switchView:		view => dispatch(switchView(view))
})

const styles = theme => ({
	newCallLogInfoWrapper: {
		position:		'absolute',
		top:			0,
		left:			0,
		right:			0,
		height:			'60px',
		display:		'flex',
		zIndex:			1,
		background:		'linear-gradient(gray, transparent)',
		justifyContent:	'center',
		alignItems:		'center',
		transition:		'top .25s',
		'&>span': {
			fontWeight:		'bold',
			color:			'green',
			background:		'rgba(255,255,255,0.7)',
			padding:		'3px 10px',
			border:			'2px solid green',
			borderRadius:	'17px'
		},
		'&.disappear': {
			top: '-60px'
		}
	},
	noCallMessage: {
		marginTop:		'15px',
		padding:		'0 10px',
		display:		'inline-block',
		width:			'100%',
		height:			'100%',
		verticalAlign:	'middle',
		textAlign:		'center',
		'&>span': {
			color:		'#b6c0c5',
			fontWeight:	'bold',
			lineHeight:	1.29
		}
	}
})

class CallsSelector extends Component {

	state = {}

	isActive = callLog => {
		if (!this.props.currentCall) return false
		let sameCallId = callLog.id === this.props.currentCall.id
		let sameStartTime = callLog.start_time === this.props.currentCall.start_time
		return sameCallId && sameStartTime
	}

	onCallClick = callLog => {
		gtmDataPush({
			PDC_Action:	GTM_APP_NAME,
			PDC_Label:	'call-item-click',
			PDC_Value:	(!this.props.currentCall || callLog.id !== this.props.currentCall.id) ? GTM_MAP.NOT_ACTIVE : GTM_MAP.ALREADY_ACTIVE
		})
		this.props.switchCall(callLog)
		this.props.switchView('content')
	}

	loadMore = async () => {
		let calls			= this.props.calls
		let cursor			= calls.items[calls.items.length - 1].cursor
		let responseCalls	= await api.loadCalls({}, 15, cursor)
		if (responseCalls === 'network-error') return
		responseCalls.items.forEach(c => {
			c.recording.loading = true
			c.voicemail.loading = true
			this.props.updateCall(c);
		})
		this.props.getRecordings(responseCalls.items)
		// Load the new storred contacts of each newly-loaded call and store them in redux
		this.props.loadExtraContacts()
	}

	scrollerUpdateCalls = callItems => {
		let calls = this.props.calls
		calls.items = callItems
		this.props.updateCalls(calls)
		calls.items.forEach((c) => {
			this.props.updateCall(c);
		});
	}

	render() {

		let { classes }	= this.props
		let calls		= this.props.calls
		let callItems	= calls.items || []
		let hasMore		= callItems.length ? calls.total - calls.items.length > 0 : false

		return (
			<div style={{minHeight: 0, height: '100%'}}>
				{calls.items.length === 0 ?
					<div className={classes.noCallMessage}>
						<span>
							You have no calls
							{/* <br/>Start a call by clicking on the button above. */}
						</span>
					</div>
				:
					<>
					<div className={`${classes.newCallLogInfoWrapper} ${this.state.newCall ? '' : 'disappear'}`}>
						<span>New Call Log Received</span>
					</div>
					<ScrollerWrapper
						reverseScroll	= {false}
						loadMore		= {this.loadMore}
						hasMore			= {hasMore}
						items			= {this.props.calls.items}
						updateItems		= {this.scrollerUpdateCalls}
						smallView		= {this.props.smallView}
					>
						{callItems.map((callLog, index) =>
							<CallSelectorItem
								key					= {`${callLog.id}${callLog.start_time}`}
								call				= {callLog}
								onClick				= {() => this.onCallClick(callLog)}
								active				= {this.isActive(callLog)}
								screenViewType		= {this.props.screenViewType}
								deleteCall			= {this.props.deleteCall}
								changeReadStatus	= {this.props.changeReadStatus}
								extraContacts		= {this.props.extraContacts}
								placeholderHeight	= {callLog.placeholderHeight}
								dontShow			= {callLog.dontShow}
								placeCall			={this.props.placeCall}
								openDialer			={this.props.openDialer}
								canPlaceOutgoingCall={this.props.canPlaceOutgoingCall}
								dataTestId			={`call-selector-item-${index}`}
								extension			={this.props.extension}
								myCallerInfo		={this.props.myCallerInfo}
								setMyCallerInfo		={this.props.setMyCallerInfo}
							/>
						)}
					</ScrollerWrapper>
					</>
				}
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CallsSelector))