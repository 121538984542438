import { mainTheme } from 'themes'
import { webComTheme } from 'web-com-theme'
import { revenuewellTheme } from 'revenuewell-theme'

let theme = mainTheme

const url_string = window.location.href
const url = new URL(url_string)
const theme_type = (url.searchParams && url.searchParams.get('theme')) || process.env.REACT_APP_THEME
const app_type = process.env.REACT_APP_APP_TYPE

if (theme_type === 'webCom') { // todo replace with however we are detecting web.com theme change
  theme = webComTheme

  window.localStorage.setItem('first_my_phone_com', 'true')
} else if (theme_type === 'Revenuewell') {
  theme = revenuewellTheme
}
try {
  document.getElementsByName('favicon')[0].href = theme.favicon.default
} catch (e) {
  console.warn('could not change favicon in theme')
}

if (app_type === 'configure') {
  window.document.title = `${(theme.configAppName || 'Configure - Phone.com')}`
} else {
  window.document.title = `${(theme.appName || 'My Phone.com')}`
}

export { theme }
