import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { CallsNavIcon } from 'pdc-svg-icons'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import ChatIcon from '@material-ui/icons/Chat'
import { withStyles } from '@material-ui/core'
import PhoneComUser from 'phone-com-user'

import Popover from '@material-ui/core/Popover'
import Tooltip from '@material-ui/core/Tooltip'
import api from 'users-app/src/util/api_v5'

import CallProvider, { PdcCallConsumer } from '../../../../pages/communicator-app/src/PdcCallProvider'

const styles = theme => ({
  helpPopoverWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  helpPopoverMain: {
    width: 300,
    boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.2), 0px 20px 35px rgba(0, 0, 0, 0.15), 0px 10px 45px rgba(0, 0, 0, 0.1)',
    borderRadius: 10
  },
  tooltip: {
    backgroundColor: '#48B9D5',
    fontSize: 12
  },
  expressServiceCodeRow: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
    paddingLeft: 15,
    paddingRight: 15,

    '& span': {
      fontSize: 13,
      marginLeft: 35
    }
  },
  userGuideRow: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.palette.secondary['-200']}`,
    height: 50,
    paddingLeft: 15,
    paddingRight: 15,
    '& span': {
      fontWeight: 'bold',
      fontSize: 14,
      marginLeft: 15,
      marginRight: 15
    },
    '&:hover': {
      background: theme.palette.primary['-300']
    }
  },
  contactSupportRow: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    height: 50,
    paddingLeft: 15,
    paddingRight: 15,

    '& span': {
      fontWeight: 'bold',
      fontSize: 14,
      marginLeft: 10
    },
    '&:hover': {
      background: theme.palette.primary['-300']
    }
  }

})

function HelpPopup (props) {
  const { classes } = props
  const [expressCode, setExpressCode] = useState('Loading...')
  const [sfChat, setsetSFChat] = useState(false)
  const [calliogSupport, setCallingSupport] = useState(false)

  const supportNumber = '+18582607860'

  useEffect(() => {
    if (!window.V5PHONECOM || !window.V5PHONECOM.role || window.V5PHONECOM.role !== 'account') { return setExpressCode(null) }

    api.getExpressServiceCode().then(setExpressCode)
  }, [])

  useEffect(() => {
    const script = document.createElement('script')
    document.body.appendChild(script)
    script.onload = function () {
      if (!window.embedded_svc) {
        return
      }
      window.embedded_svc.settings.displayHelpButton = false
      window.embedded_svc.settings.avatarImgURL = 'https://www.phone.com/images/SF-Chat/phone_logo_40px_optimized.png'
      window.embedded_svc.settings.enabledFeatures = ['LiveAgent']
      window.embedded_svc.settings.entryFeature = 'LiveAgent'
      window.embedded_svc.init(
        'https://na75.salesforce.com',
        'https://phone.secure.force.com',
        null,
        '00D41000002iMoz',
        'Support_Chat',
        {
          baseLiveAgentContentURL: 'https://c.la2-c1-ia2.salesforceliveagent.com/content',
          deploymentId: '5721K000000PgaS',
          buttonId: '5731K000000Pk8g',
          baseLiveAgentURL: 'https://d.la2-c1-ia2.salesforceliveagent.com/chat',
          eswLiveAgentDevName: 'Support_Chat',
          isOfflineSupportEnabled: true
        }
      )

      window.embedded_svc.addEventHandler('onSettingsCallCompleted', (data) => {
        const sfChatElement = document.getElementsByClassName('embeddedServiceHelpButton')[0]
        setsetSFChat(sfChatElement !== undefined)
      })
    }
    script.src = 'https://na75.salesforce.com/embeddedservice/5.0/esw.min.js'
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const showSFChat = (closeCB) => {
    const sfChatElement = document.getElementsByClassName('embeddedServiceHelpButton')[0]
    if (!sfChatElement) {
      return
    }
    const sfChatOpenElement = document.querySelector('.embeddedServiceHelpButton .helpButton .helpButtonEnabled.uiButton')
    sfChatElement.style.display = 'block'
    sfChatOpenElement.click()
    closeCB()
  }

  const callSupport = (context) => {
    if (calliogSupport) return

    const phoneNumbers = PhoneComUser.getPhoneNumber()
    const phoneNumber = Array.isArray(phoneNumbers) && phoneNumbers.length > 0 ? phoneNumbers[0] : 'PRIVATE'

    setCallingSupport(true)
    context.call(supportNumber, phoneNumber)
      .finally(() => { setCallingSupport(false); props.onClose(false) })
  }

  const getCallSupport = (context) => {
    const callFeatures = context.supports(props.extension ? props.extension.is_virtual : true)
    return callFeatures.get('outboundCalling')
  }

  const getLink = (url) => {
    const helpLinks = {
      default: 'http://www.phone.com/phone-support'
    }
    return helpLinks[url] || helpLinks.default
  }

  const openDocs = () => {
    // .. prepping implementing dyn links
    const link = getLink('')
    window.open(link, 'support')
  }

  const content = (
    <PdcCallConsumer>
      {(context) => (
        <div>

          <div style={{ display: getCallSupport(context) ? 'block' : 'none' }} className={classes.helpPopoverWrapper}>
            <div className={classes.userGuideRow} onClick={() => callSupport(context)}>
              <CallsNavIcon />
              {calliogSupport
                ? <span>Calling support now...</span>
                : <span>Talk with support!</span>}
            </div>
          </div>

          {sfChat
            ? <div className={classes.helpPopoverWrapper}>
              <div className={classes.userGuideRow} onClick={() => showSFChat(props.onClose)}>
                <ChatIcon />
                <span>Chat with support</span>
              </div>
              </div>
            : null}

          <div className={classes.helpPopoverWrapper}>
            <div className={classes.userGuideRow} onClick={() => openDocs()}>
              <LibraryBooksIcon />
              <span>View documentation</span>
            </div>
          </div>

          {expressCode
            ? <div className={classes.expressServiceCodeRow}><span>Your express code: {expressCode}</span></div>
            : null}
          <div className={classes.expressServiceCodeRow}><span>Our support line: {supportNumber}</span></div>
        </div>
      )}
    </PdcCallConsumer>
  )
  return (
    <Popover
      open={props.display}
      onClose={props.onClose}
      anchorEl={props.anchorRef.current}
      classes={{ paper: classes.helpPopoverMain }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      {content}
    </Popover>
  )
};

HelpPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  extension: PropTypes.object.isRequired
}

export default withStyles(styles)(HelpPopup)
