import Cookies from 'js-cookie'
import 'whatwg-fetch'
import ajax from 'ajax'
import reportError from 'error-reporter'

// Load app config, then load user info and set it to window
const initializePhoneCom = async config => {
  const APP_CONFIG = config || await getAppConfig()
  const queryString = window.location.search.substring(1)
  const url = APP_CONFIG.get_user_info_url

  let cookieCP
  let cookieAC
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
  // Check if a CP Session ID is passed in the URL - check here first
  // before checking cookies to support multitab logins for CSRs
  if (window.location.href.indexOf('_sid') !== -1) {
    const queryString = window.location.search.substring(1)
    const parsedQueryString = parseQuery(queryString, ';') // CP uses ';' to separate query string params
    let cpSessionFromUrl = parsedQueryString._sid
    if (cpSessionFromUrl) {
      cpSessionFromUrl = cpSessionFromUrl.replace('#', '') // CP sometimes appends # to URL
      cookieCP = cpSessionFromUrl
    }
  } else if (window.location.href.indexOf('access_token') !== -1) {
    // ?access_token=A70j1cx7rbHaTHiqspKN7qqVOO0XesBHA2eStkQij4J1DYNE&token_type=Bearer&expires_in=2592000
    const queryParamsSplit = window.location.hash.substring(1).split('&')
    queryParamsSplit.forEach(queryParam => {
      const qps = queryParam.split('=')
      if (qps[0] === 'access_token') {
        cookieAC = qps[1]
      }
    })

    window.location.href.substr(0, window.location.href.indexOf('#'))
  } else {
    if (APP_CONFIG.ac_session_id_cookie_name) {
      cookieAC = Cookies.get(APP_CONFIG.ac_session_id_cookie_name)
    }

    if (!cookieAC) {
      cookieCP = Cookies.get(APP_CONFIG.cp_session_id_cookie_name)
    }

    if (window.cordova && (!cookieAC || !cookieCP)) {
      cookieAC = localStorage.getItem(APP_CONFIG.ac_session_id_cookie_name)
      cookieCP = localStorage.getItem(APP_CONFIG.cp_session_id_cookie_name)
    }

    if (!cookieAC && !cookieCP && isLocalhost) {
      cookieCP = APP_CONFIG.cp_session_id // this is to allow local testing never put a session_id in the app.config for a deployment
      cookieAC = APP_CONFIG.ac_session_id // this is to allow local testing never put a session_id in the app.config for a deployment
    }
  }

  const cookieDomain = (isLocalhost) ? '' : 'phone.com'
  if (APP_CONFIG.ac_session_id_cookie_name && cookieAC) {
    Cookies.set(APP_CONFIG.ac_session_id_cookie_name, cookieAC, { domain: cookieDomain, expires: 60 })

    if (window.cordova) {
      localStorage.setItem(APP_CONFIG.ac_session_id_cookie_name, cookieAC)
    }
  } else if (cookieCP) {
    Cookies.set(APP_CONFIG.cp_session_id_cookie_name, cookieCP, { domain: cookieDomain, expires: 60 })

    if (window.cordova) {
      localStorage.setItem(APP_CONFIG.cp_session_id_cookie_name, cookieCP)
    }
  }

  if (!cookieCP && !cookieAC) {
    // todo SignInRedirect();
  }

  window.cook = cookieAC
  let fetchCredentials = 'include'
  const headers = {
    'Content-Type': 'application/json'
  }

  if (cookieAC) {
    headers.Authorization = `Bearer ${cookieAC}`
    fetchCredentials = 'omit'
  } else if (cookieCP) {
    headers.Authorization = `CP ${cookieCP}`
    fetchCredentials = 'omit'
  }

  return fetch(url, {
    method: 'GET',
    headers: headers,
    credentials: fetchCredentials
  })
    .then((response) => {
      return response.json().then(
        res => {
          // check response success
          if (res.account_id) {
            // only account users are alloed in configure.phone.com
            if (window.location.hostname === 'configure.phone.com' && res.role !== 'account') {
              redirectLogin()
            }
            window.V5PHONECOM = {
              stage: APP_CONFIG.stage,
              v4ApiRoot: APP_CONFIG.v4ApiRoot,
              v5ApiRoot: APP_CONFIG.v5ApiRoot,
              v5ToolsRoot: APP_CONFIG.v5ToolsRoot,
              redirect_url: APP_CONFIG.redirect_url,
              cp_token: res.cp_session_token || cookieCP || cookieAC, // we should not use ACtoken for long here might stop working some day
              ac_token: res.ac_session_token || cookieAC,
              avatar_url: res.avatar_url || '',
              first_name: res.first_name || '',
              last_name: res.last_name || '',
              email: res.email || '',
              extension: res.extension ? res.extension.extension : null,
              direct_number: res.direct_number,
              features: new Set(res.features),
              phone_number: res.phone_numbers,
              voip_id: res.account_id,
              voip_phone_id: res.extension_id,
              user_default_extension_id: res.extension_id,
              user_id: res.user_id,
              role: res.role,
              user_tiered: res.user_tiered
            }

            registerCordovaDeviceId(APP_CONFIG.v5ApiRoot)
            return res
          } else {
            redirectLogin()
          }
        }
      )
    }).catch((err) => {
      console.log(err)
      redirectLogin()
    })
}

const redirectLogin = () => {
  getAppConfig().then((APP_CONFIG) => {
    const location = window.location
    const host = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
    window.location.href = `${APP_CONFIG.redirect_url}${host}`
  }
  )
}

const getPhoneCom = () => {
  if (window.window.V5PHONECOM) {
    return new Promise(function (resolve, reject) {
      resolve(window.window.V5PHONECOM)
    })
  }
  return initializePhoneCom().then(phoneCom => {
    return new Promise(function (resolve, reject) {
      resolve(window.window.V5PHONECOM)
    })
  })
}

// Load app config and set it to the window
const getAppConfig = () => {
  if (window.APP_CONFIG) {
    return new Promise(function (resolve, reject) {
      resolve(window.APP_CONFIG)
    })
  }
  let configUrl = '/app.config'
  if (process.env.PUBLIC_URL) {
    configUrl = `${process.env.PUBLIC_URL}/app.config`
  }

  return fetch(configUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(res => {
      window.APP_CONFIG = res
      return res
    })
    .catch((err) => {
      console.log(err)
    })
}

function parseQuery (queryString, delimiterChar) {
  const query = {}
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split(delimiterChar)
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

// Load app config and set it to the window
const logout = async () => {
  const appConfig = await getAppConfig()
  const phoneCom = await getPhoneCom()

  const baseUrl = phoneCom.v4ApiRoot

  Cookies.remove(window.APP_CONFIG.ac_session_id_cookie_name, { domain: 'phone.com', path: '/' })
  Cookies.remove(window.APP_CONFIG.cp_session_id_cookie_name, { domain: 'phone.com', path: '/' })
  if (window.cordova) {
    localStorage.removeItem(window.APP_CONFIG.ac_session_id_cookie_name)
    localStorage.removeItem(window.APP_CONFIG.cp_session_id_cookie_name)
  }

  const link = `${phoneCom.v5ApiRoot.replace('services', 'app')}/communicator/account/logout/?account_id=${phoneCom.voip_id}&extension_id=${phoneCom.voip_phone_id}`
  const cp_services_logout = fetch(link, { method: 'GET', headers: { Authorization: `CP ${phoneCom.cp_token}` } })
  const cp_logout = fetch(`${appConfig.cpBase}/login?action=logout&_sid=${phoneCom.cp_token}`, { method: 'GET', mode: 'no-cors' })
  const ac_logout = ajax.delete(`${baseUrl}/oauth/access-token?offset=0&limit=25`, {}, 'Bearer')

  await cp_services_logout
  await cp_logout
  await ac_logout

  redirectLogin()
}

// register FCM token if the device is
const registerCordovaDeviceId = async (base_url) => {
  /*
        cordova-plugin-device:  This plugin defines a global device object
        cordova-plugin-firebasex:  This plugin defines a global FirebasePlugin object
    */
  if (window.cordova && window.FirebasePlugin && window.device) {
    window.FirebasePlugin.getToken(fcmToken => {
      const registerDevice = {
        push_type: 'aws-my-phone-com',
        device_id: window.device.uuid,
        device_token: fcmToken,
        user_agent: navigator.userAgent,
        app_version: '22.00.04', // TODO: ask john if we could get it dynamically
        application_identifier: 'my.phone.com'
      }

      ajax
      // TODO: to replace hard code url to v5ApiRoot
        .post(`${base_url}/notifications/push-notifications/register-device`, registerDevice)
        .catch(err => {
          reportError(err)
        })
    })
  }
}

export { getAppConfig, initializePhoneCom, getPhoneCom, logout, redirectLogin, registerCordovaDeviceId }
