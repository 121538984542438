import React, { Component } from 'react'
import Spinner from 'spinner'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	loaderFull: theme.loaderFull,
	spinnerText: {
		fontSize: 	14,
		fontWeight:	500,
		lineHeight:	'21px',
		marginTop:	12

	}
})

class LoaderFull extends Component {

	renderText = () => {
		let { classes } = this.props
		if(!this.props.text) return null
		return <span className={classes.spinnerText}>{this.props.text}</span>
	}

	render() {
		let { classes } = this.props
		let styles = this.props.styles || {}
		let colorProp = this.props.color ? {color: this.props.color} : {}
		return (
			<div className={classes.loaderFull} style={styles.loaderFull}>
				<Spinner {...colorProp} size={this.props.size}/>
				{this.renderText()}
			</div>
		)
	}
}

export default withStyles(styles)(LoaderFull)