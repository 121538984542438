import React from 'react'
// import { SpinnerIcon } from 'pdc-svg-icons'
import { SpinnerIcon } from '../pdc-svg-icons/SvgIcons'
import { makeStyles } from '@material-ui/styles'
import './spinner.css'
const useStyles = makeStyles(theme => ({
	image: {
		width:		'30px',
		height:		'30px',
		animation:	'rotate-spinner-3298fadkj 1s infinite steps(12)'
	},
	bigImage: {
		width:	'50px !important',
		height:	'50px !important',
	},
	biggerImage: {
		width:	'60px !important',
		height:	'60px !important',
	}
}))
export default function Spinner(props) {
	const classes = useStyles()
	let colorProp = props.color ? {color: props.color} : {}
	return <SpinnerIcon {...colorProp} className={`${classes.image} ${props.size === 'big' ? classes.bigImage : ''} ${props.size === 'bigger' ? classes.biggerImage : ''}`}/>
}