import React, { Component } from "react"

import { getShortFormattedTime } from "time-format"
import { iconBackgroundStyle } from "colors"

import { avatarSingleSvg, avatarCoupleSvg, avatarTrioSvg, noNumberIconSvg } from "pdc-icons"

import { formatPhoneNumber } from "phone-numbers"
import { withStyles } from "@material-ui/core"
import CallEndIcon from "@material-ui/icons/CallEnd"
import Button from 'button'
const styles = (theme) => ({
	selectorItem: theme.selector.selectorItem,
	newItemInfo: {
		position: "absolute",
		right: "10px",
		top: "2px",
		fontSize: "11px",
		fontWeight: "bold",
		color: "#4c86bd",
	},
	mainContent: {
		marginLeft: "12px",
		position: "relative",
		width: "100%",
	},
	topSide: {
		position: "absolute",
		width: "100%",
		top: 0,
		display: "flex",
		overflow: "hidden",
		lineHeight: 1.31,
		letterSpacing: -0.1,
	},
	participants: {
		margin: "0 8px 0 0",
		maxWidth: "80%",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		fontSize: "16px",
	},
	timeInfo: {
		whiteSpace: "nowrap",
		marginLeft: "auto",
		position: "absolute",
		right: 0,
		fontSize: 10.5,
		fontWeight: 500,
		color: theme.messagesApp.conversationSelector.timeColor,
		lineHeight: 1.05,
		letterSpacing: 0.2,
		textTransform: "uppercase",
	},
	bottomSide: {
		position: "absolute",
		width: "100%",
		bottom: 0,
		display: "flex",
		alignItems: "center",
		overflow: "hidden",
		lineHeight: 1.36,
		letterSpacing: -0.1,
	},
	mainText: {
		margin: "0 5px 0 0",
		maxWidth: "95%",
		width: "95%",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		color: "grey",
		display: "flex",
	},
	additionalText: {
		color: "gray",
		fontSize: "11px",
	},
	itemIcon: {
		position: "relative",
		width: "40px",
		height: "40px",
		margin: "auto",
		backgroundColor: "#eee",
		borderRadius: "50%",
		boxSizing: "border-box",
	},
	iconImage: {
		borderRadius: "50px",
	},
	dropdown: {
		marginLeft: "auto",
	},
	infoText: {
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
	bold: {
		"&>span": {
			fontWeight: "bold",
			color: "black",
		},
		"& .time-info span": {
			fontWeight: 600,
		},
	},
	detailButton: {
		height: 24,
		width: 60,
		minWidth: 60,
		padding: 0
	}
})

class SelectorItem extends Component {
	constructor(props) {
		super(props)
		let timestamp = this.props.timestamp
		this.state = { timeInfo: timestamp ? getShortFormattedTime(timestamp) : "" }
	}

	componentDidMount() {
		this.timeRefreshInterval = setInterval(this.formatTime, 6000)
	}

	formatTime = () => {
		let timestamp = this.props.timestamp
		if (!timestamp) return ""
		let formattedTime = getShortFormattedTime(timestamp)
		if (formattedTime !== this.state.timeInfo) {
			this.setState({ timeInfo: formattedTime })
		}
	}

	componentDidUpdate(prevProps) {
		if (!this.props.timestamp && prevProps.timestamp) this.setState({ timeInfo: "" })
	}

	componentWillUnmount() {
		clearInterval(this.timeRefreshInterval)
	}

	renderIcon() {
		let participants = this.props.participants
		const iconSwitch = (count) =>
			({
				0: noNumberIconSvg,
				1: avatarSingleSvg,
				2: avatarSingleSvg,
				3: avatarCoupleSvg,
			}[count] || avatarTrioSvg)
		let icon = iconSwitch(participants.length)
		let dids = participants.filter((p) => !p.mine).map((n) => n.number)
		let iconColor = iconBackgroundStyle(dids)

		let { classes } = this.props
		return (
			<div className={classes.itemIcon} style={iconColor}>
				<img className={classes.iconImage} src={icon} alt="User Icon" />
			</div>
		)
	}

	separateNums = () => {
		let participants = this.props.participants
		participants.sort((p) => (p.mine ? 1 : -1)) // If the number is mine put it last
		let allNums = participants.map((p) => formatPhoneNumber(p.number))
		let myNums = participants.filter((p) => p.mine).map((p) => p.name || formatPhoneNumber(p.number))
		let notMyNums = participants.filter((p) => !p.mine).map((p) => p.name || formatPhoneNumber(p.number))
		return [allNums, myNums, notMyNums]
	}

	renderParticipants = () => {
		let [allNums, myNums, notMyNums] = this.separateNums()
		return (
			<span title={allNums.join(", ")}>
				{notMyNums.map((p, i) => {
					return [i > 0 && ", ", <span key={`${p}${i}`}>{p}</span>]
				})}

				{this.props.showMyNums && Boolean(notMyNums.length) && [", "]}

				{(this.props.showMyNums || !Boolean(notMyNums.length)) &&
					myNums.map((n, i) => {
						return [
							i > 0 && ", ",
							<span key={`${n}${i}`}>
								<em>{n}</em>
							</span>,
						]
					})}
			</span>
		)
	}

	renderInfoPanel() {
		let { classes } = this.props
		let styles = this.props.styles || {}
		let bold = this.props.bold ? classes.bold : ""

		return (
			<div className={classes.mainContent} style={styles.mainContent}>
				<div className={`${classes.topSide} ${bold}`} style={styles.toSide}>
					<span className={`${classes.participants} fs-block`} style={styles.participants}>
						{this.renderParticipants()}
					</span>
					{this.props.renderDropdownMenu ? (
						<div className={`${classes.dropdown} dropdown`}>{this.props.renderDropdownMenu()}</div>
					) : (
						<span className={`${classes.timeInfo} time-info`} style={styles.timeInfo}>
							{this.state.timeInfo}
						</span>
					)}
				</div>
				<div className={classes.bottomSide} style={styles.bottomSide}>
					<div className={`${classes.mainText} fs-block`} style={styles.mainText}>
						{this.props.beforeMainText || null}
						<div className={`${bold} ${classes.infoText}`}>{this.props.mainText}</div>
					</div>
					{this.props.additionalText ? (
						<span className={classes.additionalText} style={styles.additionalText}>
							{this.props.additionalText}
						</span>
					) : null}
					{this.props.onDetailButtonClick ? (
						<Button
							className={classes.detailButton}
							onClick={this.props.onDetailButtonClick}
							disabled={this.props.isDetailButtonDisabled}
							// variant={'outlined'}
							data-test-id='selector-call-button'
						>
							<div style={{ fontSize: 9, marginTop: 3, color: "white" }}>Call</div>
						</Button>
					) : null}
				</div>
				{this.props.renderBadge ? this.props.renderBadge() : null}
			</div>
		)
	}

	onClick = (e) => {
		let target = e.target
		let targetClasses = Array.from(target.classList)
		let currentElement = target
		while (true) {
			if (currentElement.getAttribute("role") === "presentation") return
			if (currentElement.nodeName === "BODY") break
			currentElement = currentElement.parentElement
		}
		if (targetClasses.includes("dropdown") || targetClasses.includes("selector-dropdown-btn")) return
		this.props.onClick(e)
	}

	render() {
		const { classes } = this.props
		let styles = this.props.styles || {}
		let wrapperStyles = {}
		let classNames = classes.selectorItem
		Object.assign(wrapperStyles, styles.selectorItem)
		if (this.props.active) {
			classNames += " selected-item"
			Object.assign(wrapperStyles, styles.selectedItem)
		}

		return (
			<div ref={this.props.setRef} className={classNames} style={wrapperStyles}>
				<div className="main-view" onClick={this.onClick} data-test-id={this.props.dataTestId}>
					{this.renderIcon()}
					{this.renderInfoPanel()}
					{this.props.isNew && (
						<span className={classes.newItemInfo} style={styles.newItemInfo}>
							NEW
						</span>
					)}
				</div>
				{this.props.renderExpandedArea ? this.props.renderExpandedArea() : null}
			</div>
		)
	}
}

export default withStyles(styles)(SelectorItem)
