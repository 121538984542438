import { CallerInfo } from "../interfaces/CallerInfo"
import { CallEventsDelegate } from "../interfaces/CallEventsDelegate"
import { CallState } from "../enums/CallState"

export default abstract class CallSession {
	public abstract participants: Array<CallerInfo>
	public abstract myCallInfo: CallerInfo | null
	public abstract callType: string
	public abstract callId: string
	public abstract callStartTime?: number
	public abstract callEndTime?: number
	public abstract callState: CallState | null = CallState.INACTIVE
	public abstract isNewCall: boolean = false
	public abstract isMerged: boolean = false
	public abstract isMutedLocal: boolean = false
	public abstract isMutedRemote: boolean = false
	public abstract isOnHold: boolean = false
	public abstract showCallStats(): void
	public abstract mergedCallIDs: { [key: string]: string } = {}

	public abstract supports(): Map<string, boolean>
	
	// protected abstract activeCallerId?: string
	// public abstract setActiveCallerId(callerId: string): Promise<any>;
	// public getActiveCallerId(): string | undefined {
    //     return this.activeCallerId
    // }
}
