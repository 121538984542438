import React, { Component, createContext } from "react"
import SipCallManager from "./pdc-calls/SipCallManager"
import { CallManager, CallFeatures } from "./pdc-calls/CallManager"
import { CallerInfo } from "./interfaces/CallerInfo"
import { CallManagerEvents } from "./enums/CallManagerEvents"
import { CallType } from "./enums/CallType"
import { CallState } from "./enums/CallState"
interface Props {
	//do we need to receive anything? user-related info?
	disableAutoConnect?: boolean
	someAuthToken?: string
	callMode: string
}

interface CallManagerState {
	callState: string
	callInfo: CallerInfo
	callAnswered: false
	callStartTime?: number
	topicCallbacks: Array<Object>
	recentConnectionStats: Array<Object>
}

interface SipCallManagerState extends CallManagerState {}

//PdcCallProvider??
const MyContext = createContext({})
export const PdcCallConsumer = MyContext.Consumer

//todo:  PDcCallcprovider - call-agnostic. change on configuration settings
const CALLMODE = ""

declare global {
	interface Window {
		pdcCall: CallManager | null
	}
}
class PdcCallProvider extends Component<any, any> {
	//singleton observable.
	callManager: CallManager

	constructor(props: Props) {
		super(props)

		this.state = {
			didConnect: false,
			callStats: null,
			canPlaceOutgoingCall: true
		}

		switch (props.callMode) {
			case CallType.SIP:
				this.callManager = new SipCallManager()
				break
			case CallType.CLK2C:
			default:
				this.callManager = new SipCallManager()
				break
		}
		this.callManager = new SipCallManager()
	}

	public async componentDidMount() {
		window.pdcCall = this.callManager
		this.callManager.on(CallManagerEvents.CALL_CREATED, () => {
			this.forceUpdate()
		})
		this.callManager.on(CallManagerEvents.CALL_RECEIVED, (e: any) => this.forceUpdate())
		this.callManager.on(CallManagerEvents.CONNECTING, (e: any) => this.forceUpdate())
		this.callManager.on(CallManagerEvents.CALL_ANSWERED, (e: any) => this.forceUpdate())
		this.callManager.on(CallManagerEvents.CALL_HANGUP, (e: any) => {
			if (!this.callManager.activeCallId) {
				this.setState({ callStats: null })
			}
			this.forceUpdate()
		})

		this.callManager.on(CallManagerEvents.CALL_STAT_UPDATE, (e: any) => {
			const { callId, stat } = e
			//call stats are being tracked every n seconds from each session. but only update the ui if its the active call.
			if (this.callManager.activeCallId === callId) this.setState({ callStats: stat })
		})

		this.callManager.on(CallManagerEvents.SWITCH_CALL, (e: any) => this.forceUpdate())

		this.callManager.on(CallManagerEvents.MERGE_CALL, (e: any) => this.forceUpdate())

		this.callManager.on(CallManagerEvents.STATE_UPDATE, (e: any) => this.forceUpdate())

		if (!this.props.disableAutoConnect) {
			await this.connect()
		}
	}

	public componentWillUnmount() {
		window.pdcCall = null
	}

	public connect = async () => {
		//TODO: await this and return empty resolved promise to not expose simpleuser.
		if (!this.state.didConnect) {
			return await this.callManager.connect().then(() => {
				this.setState({ didConnect: true })
			})
		}
	}

	public call = async (callee: string) => {
		await this.callManager.call(callee)
	}

	public setMyCallerInfo = async (callerId: string) =>
	{
		this.setState({canPlaceOutgoingCall : false})
		await this.callManager.setMyCallerInfo(callerId);
		this.setState({canPlaceOutgoingCall : true})
	}

	//TODO: should this be simplified into a single hold() func, letting call managers determine based on isOnHold field if the action should be a hold or unhold. ui is not concerned with specifics.
	public hold = async () => {
		await this.callManager.hold()
	}

	public unhold = async () => {
		await this.callManager.unhold()
	}

	public hangupById = (id: string, endAll: boolean = false): void => {
		this.callManager.hangupById(id, endAll)
	}

	public answerById = async (id: string): Promise<any> => {
		return this.callManager.answerById(id)
	}

	public switchCall = async (id: string): Promise<void> => {
		await this.callManager.switchCall(id)
	}

	public mergeCall = async (id: string) => {
		await this.callManager.mergeCall(id)
	}

	public muteLocal = (isMuted: boolean) => {
		this.callManager.muteCurrentLocal(isMuted)
	}

	public muteRemote = (isMuted: boolean) => {
		this.callManager.muteCurrentRemote(isMuted)
	}

	public sendDTMF = (tone: string) => {
		this.callManager.sendDTMF(tone)
	}

	public getIncomingCalls = (): Array<any> => {
		return Object.values(this.callManager.calls)
			.filter((call) => call.callState === CallState.INCOMING)
			.sort((a, b) => b.callStartTime! - a.callStartTime!)
	}

	public getActiveCalls = (): Array<any> => {
		return Object.values(this.callManager.calls).filter((call) => call.callState === CallState.ACTIVE)
	}

	public getIsMutedLocal = () => {
		let isMutedLocal = true
		const id = this.callManager.activeCallId
		//is sip the exception to rule or is c2c?  && this.callManager.callMode !== CallType.C2C
		if (id && this.callManager.calls[id] && this.callManager.callMode === CallType.SIP) {
			const currentCall = this.callManager.calls[id]
			if(!currentCall.isMutedLocal){
				isMutedLocal = false
			} else {
				for(let number in currentCall.mergedCallIDs){
					let p_id = currentCall.mergedCallIDs[number]
					if(this.callManager.calls[p_id] && !this.callManager.calls[p_id].isMutedLocal) isMutedLocal = false
				}
			}
		} else {
			isMutedLocal = false
		}
		return isMutedLocal
	}

	public getIsMutedRemote = () => {
		let isMutedRemote = true
		const id = this.callManager.activeCallId
		//is sip the exception to rule or is c2c?  && this.callManager.callMode !== CallType.C2C
		if (id && this.callManager.calls[id] && this.callManager.callMode === CallType.SIP) {
			const currentCall = this.callManager.calls[id]
			if(!currentCall.isMutedRemote){
				isMutedRemote = false
			} else {
				for(let number in currentCall.mergedCallIDs){
					let p_id = currentCall.mergedCallIDs[number]
					if(this.callManager.calls[p_id] && !this.callManager.calls[p_id].isMutedRemote) isMutedRemote = false
				}
			}
		} else {
			isMutedRemote = false
		}
		return isMutedRemote
	}

	static getWindowGlobal() {}

	public supportsById = (callId: string): any => {
		return this.callManager.supportsById(callId)
	}

	public supports = (is_extension_virtual: boolean): Map<CallFeatures, boolean> => {
		return this.callManager ? this.callManager.supports(is_extension_virtual) : new Map<CallFeatures, boolean>();
	}


	render() {
		const {
			callManager,
			call,
			hangupById,
			hold,
			unhold,
			answerById,
			switchCall,
			connect,
			muteLocal,
			muteRemote,
			mergeCall,
			sendDTMF,
			getActiveCalls,
			getIncomingCalls,
			supportsById,
			supports,
			setMyCallerInfo
		} = this

		const myCallerInfo = callManager.myCallerInfo
		const activeCallId = callManager.activeCallId
		const nonStateCalls = { ...callManager.calls } // this is necessary for nested obj updates in react

		const incomingCallsCnt = this.getIncomingCalls().length
		const activeCallsCnt = this.getActiveCalls().length

		const currentCall = nonStateCalls[activeCallId!] || null
		const currentCallState = activeCallId ? this.callManager.calls[this.callManager.activeCallId!] : null
		const backgroundCalls = currentCall
			? this.getActiveCalls().filter((c) => c.callId !== currentCall.callId)
			: this.getActiveCalls()
		const incomingCalls = this.getIncomingCalls()
		const callsOnHold = this.callManager.getCallsArray().filter(c => c.isOnHold).filter(c => !c.isMerged)
		let isMutedLocal = this.getIsMutedLocal()
		let isMutedRemote = this.getIsMutedRemote()
		let canPlaceOutgoingCall = this.state.canPlaceOutgoingCall && this.state.didConnect &&
			this.callManager.getCallsArray().filter((c) => c.callState === CallState.INACTIVE).length === 0 && !callManager.callSetupInProgress
		const deniedAudioPermissions = callManager.deniedAudioPermissions
		const noDeviceFound	= callManager.noDeviceFound
		return (
			<MyContext.Provider
				value={{
					...this.state,
					//methods
					call,
					hangupById,
					connect,
					answerById,
					muteLocal,
					muteRemote,
					switchCall,
					mergeCall,
					sendDTMF,
					getActiveCalls,
					getIncomingCalls,
					hold,
					unhold,
					supportsById,
					supports,

					myCallerInfo,
					activeCallId,
					currentCall,
					callsCnt: Object.keys(nonStateCalls).length,
					calls: nonStateCalls,
					callsOnHold,
					incomingCallsCnt,
					activeCallsCnt,
					backgroundCalls,
					incomingCalls,
					isMutedLocal,
					isMutedRemote,
					canPlaceOutgoingCall,
					deniedAudioPermissions,
					noDeviceFound,
					setMyCallerInfo
				}}
			>
				{this.props.children}
			</MyContext.Provider>
		)
	}
}
export default PdcCallProvider
