import React, { Component } from "react"
import { connect } from "react-redux"
import StartNewPanel from "start-new-panel"
import PhoneComUser from "phone-com-user"
import api from "../util/api_v5.js"
import { switchView } from "../actions/view"
import { updateCall, switchCallerId } from "../actions/calls"

import PhoneIcon from "@material-ui/icons/Phone"
import PhonelinkRingIcon from "@material-ui/icons/PhonelinkRing"
import { withStyles } from "@material-ui/core"
import Dialer from "dialer"
import BackButton from "back-button"
import NoSetup from "no-setup"
import { PdcCallConsumer } from "../../../../pages/communicator-app/src/PdcCallProvider.tsx"
import gtmDataPush from 'gtm-events'
import Spinner from 'spinner'

const GTM_APP_NAME = 'make-call'

const styles = (theme) => ({
	contentWrapper: {
		padding: 30,
	},
	placeCallWrapper: {
		display: "flex",
		alignItems: "center",
		fontSize: 25,
		fontWeight: 500,
		cursor: "pointer",
		width: "fit-content",
		padding: "5px 20px 5px 10px",
		"&:hover": {
			background: "#f1f1f1",
			color: theme.palette.primary.main,
		},
	},
	phoneIcon: {
		color: theme.palette.primary.main,
		marginRight: 10,
		fontSize: 50,
	},
	callMadeInfo: {
		display: "flex",
		flexDirection: "column",
		"& span": {
			marginTop: 20,
			fontSize: 16,
			fontWeight: "bold",
		},
	},
	phoneRingingIcon: {
		fontSize: 100,
	},
})

const mapStateToProps = (state) => ({
	smallView: state.smallView,
})

const mapDispatchToProps = (dispatch) => ({
	switchView: (view) => dispatch(switchView(view)),
	updateCall: (call) => dispatch(updateCall(call)),
	switchCallerId: (callerId) => dispatch(switchCallerId(callerId))
})
class MakeCall extends Component {
	constructor(props) {
		super(props)
		this.state = {
			callee: null,
			caller: null,
			callIsMade: false,
		}
		console.log(this.state)
	}

	componentDidMount() {
		console.log(this.props.extension.caller_id)
		if(this.props.myCallerInfo) this.setState({ caller: this.props.myCallerInfo.callerId })
		else this.setState({ caller: this.props.extension.caller_id })
		// add do not focus if iOS use os-detector
		if(!window.ios){
			let tags = document.getElementById('tags-input')
			if(tags) tags.focus()
		}
	}

	makeCall = async () => {
		await this.props.connect()
		let callee = this.state.callee
		if (callee[0] === "#") callee = callee.substring(1)
		this.props.makeCall(callee)
		this.props.switchView("dialer")
	}

	updateCallee = (recipients) => this.setState({ callee: recipients.length ? recipients[0].number : null })

	updateFromNumber = async phoneNumber => {
		gtmDataPush({
			PDC_Action:	GTM_APP_NAME,
			PDC_Label:	'from-number-change'
		})
		//hit endpoint before settings state
		await this.props.setMyCallerInfo(phoneNumber)
		this.setState({caller: phoneNumber})
	}

	renderStartNewPanel = () => {
		const { classes } = this.props
		let phoneNumbers = PhoneComUser.getPhoneNumber()
		let inputNotAllowed = Boolean(phoneNumbers === undefined || phoneNumbers.length === 0) && false
		return (
			<PdcCallConsumer>
				{(context) => (
					<StartNewPanel
						extension={this.props.extension}
						contactsUtil={this.props.contactsUtil}
						startChatButton={null}
						startChatButtonDisabled={null}
						singleContact={true}
						showDialpad={true}
						renderChildrenIfContactSelected={true}
						addRecipientsAsYouType={true}
						showSendToField={false}
						allowShortCodes={false}
						allowExtensions={true}
						inputNotAllowed={inputNotAllowed}
						goBackText="New Call"
						updateContacts={this.props.updateContacts}
						goBack={() => this.props.switchView("select")}
						updateRecipients={this.updateCallee}
						updateFromNumber={this.updateFromNumber}
						updateContacts={this.props.updateContacts}
						goBack={() => this.props.openCallLogList()}
						updateRecipients={this.updateCallee}
						origin={GTM_APP_NAME}
						myCallerInfo={this.props.myCallerInfo}
					>
						<div className={classes.contentWrapper}>
							{this.state.callIsMade ? (
								// <div className={classes.callMadeInfo}>
								// 	<PhonelinkRingIcon classes={{root: classes.phoneRingingIcon}}/>
								// 	<span>
								// 		Your cell phone should be ringing now.<br/>
								// 		Answer the call on your mobile app,<br/>
								// 		and we'll connect you to the recipient.
								// 	</span>
								// </div>
								<Dialer recipient={this.state.callee} setIsDialerOpen={this.props.setIsDialerOpen} />
							) : (
								!this.props.disabled ? 
								<div className={classes.placeCallWrapper} onClick={this.makeCall}>
									<PhoneIcon classes={{ root: classes.phoneIcon }} />
									<span>Place Call</span>
								</div> 
								: <Spinner/>
							)}
						</div>
					</StartNewPanel>
				)}
			</PdcCallConsumer>
		)
	}

	render() {
		return this.props.extension.is_virtual && !(window.V5PHONECOM && window.V5PHONECOM.user_id) ? (
			<div>
				{this.props.smallView ? (
					<div style={{ padding: 20 }}>
						<BackButton onClick={() => this.props.switchView("select")} />
					</div>
				) : null}
				<NoSetup type="callsVirtual" />
			</div>
		) : (
			this.renderStartNewPanel()
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MakeCall))
