import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { MakeCallIcon, StartConversationIcon, SendFaxIcon } from 'pdc-svg-icons'

const useStyles = makeStyles(theme => ({
	startNewButton: {
		backgroundColor:	theme.palette.primary.backgroundColor,
		color:				theme.messagesApp.startConversation.textColor,
		boxShadow:			theme.palette.primary.flatBottomShadow,
		cursor:				'pointer',
		fontSize:			'20px',
		fontWeight:			600,
		lineHeight:			1.05,
		letterSpacing:		'-0.3px',
		padding:			'5px 15px',
		display:			'flex',
		alignItems:			'center',
		minHeight:			60,
		transition:			'all .3s ease-in-out',
		background:			'white',
		zIndex:				3,
		borderWidth: 0
	},
	iconContainer:{
		marginRight:	'10px',
		width:			'43px'
	},
	startNewIcon: {
		width:		'43px',
		height:		'32px',
		objectFit:	'contain'
	}
}))

export default function StartNewButton(props) {
	const classes = useStyles()
	const [hovered, setHovered] = useState(false)
	let Icon = null
	let dataTestId = ''
	switch (props.app) {
		case 'calls':
			Icon		= MakeCallIcon
			dataTestId	= 'start-conversation'
			break
		case 'messages':
			Icon		= StartConversationIcon
			dataTestId	= 'start-conversation'
			break
		case 'faxes':
			Icon		= SendFaxIcon
			dataTestId	= 'start-conversation'
			break
		default:
			return null
	}
	return (
		<button
			className		= {classes.startNewButton}
			onClick			= {props.onClick}
			onMouseEnter	= {() => setHovered(true)}
			onMouseLeave	= {() => setHovered(false)}
			data-test-id	= {dataTestId}
			disabled={props.disabled}
		>
			<div className={classes.iconContainer}>
				<Icon className={classes.startNewIcon} hover={hovered ? 1 : 0}/>
			</div>
			<span>{props.title}</span>
		</button>
	)
}