import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import ConfirmModal from 'confirm-modal'
import TextField from 'pdc-text-field'
import api from '../../util/api_v5'
import Spinner from 'spinner'

const styles = theme => ({
	modalContainer: {
		minHeight:	170
	},
	modalHeader: {
		fontSize: 24,
		marginBottom: 15,
		fontWeight: 'bold'
	},
	modalContent: {
		display: 'flex',
		flexDirection: 'column',
	},
	infoContainer: {
		marginBottom: 25,
	},
	spinnerRow: {
		position:	'absolute',
		top:		'30%',
		left:		'41%',
		'& svg': {
			color:	theme.palette.primary[0],
			width:	100,
			height:	100
		}	
	}
})

const mapStateToProps = state => ({
	smallView:	state.smallView,
})

const mapDispatchToProps = dispatch => ({

})

class InviteUserModal extends Component {	
	
	constructor(props) {
		super(props)
		this.state = {
			email_code: '',
			mobile_code: '',
			email_code_error: false,
			mobile_code_error: false,
			loading: false
		}
	}

	updateFieldValue = (field, value) => {
		value = value.trim()
		let obj = {}
		obj[field] = value
		obj[field+'_error'] = false
		this.setState(obj)
	}

	verifyConfirm = async () => {
		// TODO call backend to verify
		let emailError = false
		let mobileError = false
		if (this.props.verifyEmail){
			if (this.state.email_code.length === 0){
				this.setState({email_code_error: 'Please enter a valid code'})
				emailError = true
			}
		}
		if (this.props.verifyMobile){
			if (this.state.mobile_code.length === 0){
				this.setState({mobile_code_error: 'Please enter a valid code'})
				mobileError = true
			}
		}
		if (mobileError || emailError) return
		this.setState({loading: true})
		if(this.props.verifyEmail){
			let confirmEmailResponse = await api.confirmContact(this.props.user.id, 'email', this.props.user.email, this.state.email_code)
			if (!confirmEmailResponse || confirmEmailResponse.error){
				this.setState({email_code_error: 'Please enter a valid code'})
				emailError = true
			}
		}
		if(this.props.verifyMobile){
			let confirmMobileResponse = await api.confirmContact(this.props.user.id, 'cellphone', this.props.user.personal_phone_number, this.state.mobile_code)
			if (!confirmMobileResponse || confirmMobileResponse.error){
				this.setState({mobile_code_error: 'Please enter a valid code'})
				mobileError =  true
			}
		}
		this.setState({loading: false})
		if (!emailError && !mobileError){
			this.props.saveChangesToUser()
		}
	}

	render(){
		const { classes } = this.props

		let infoText = ''
		let headerText = ''
		if (this.props.verifyEmail && this.props.verifyMobile) {
			headerText = 'Verify your new email and mobile.'
			infoText = 'For additinal security we require verification of email and mobile number.Please provide the verifications codes sent to your email and mobile number.'
		}else if (this.props.verifyEmail) {
			headerText = 'Verify your new email.'
			infoText = 'For additinal security we require verification of email.Please provide the verifications code sent to your email.'
		}
		else if (this.props.verifyMobile) {
			headerText = 'Verify your new mobile number'
			infoText = 'For additinal security we require verification of your mobile number.Please provide the verifications code sent to your mobile number.'
		}
		let modalContent = (
			<div className={classes.modalContainer}>
				<div className={classes.modalHeader}>
					
				</div>
				<div className={classes.modalContent}>
					<div className={classes.infoContainer}>
						{infoText}
					</div>
					<div className={classes.inputsContainer}>
						{this.props.verifyEmail ?
							<TextField
								onInputChange	= {value => this.updateFieldValue('email_code', value)}
								content={this.state.email_code}
								label={'Email verification code'}
								variant='filled'
								value={''} 
								id={'email-verify'}
								error={this.state.email_code_error}
								helperText={this.state.email_code_error}
								disabled={this.state.loading}
							/>
						: null}
						{this.props.verifyMobile ?
							<TextField
								onInputChange	= {value => this.updateFieldValue('mobile_code', value)}
								content={this.state.mobile_code}
								label={'Mobile verification code'}
								variant='filled'
								value={''} 
								id={'phone-verify'}
								error={this.state.mobile_code_error}
								helperText={this.state.mobile_code_error}
								disabled={this.state.loading}
							/>
						: null}
					</div>
				</div>
				{this.state.loading ?
					<div className={classes.spinnerRow}>
						<Spinner color='currentColor'/>
					</div>
				: null}
			</div>
		)

	
		return (
			<ConfirmModal
				title			= ''
				isShown			= {this.props.showModal}
				content			= {modalContent}
				noButtonText	= 'Cancel'
				yesButtonText	= 'Confirm'
				yesButtonColor  = {this.state.loading ? 'secondary' : 'primary'} 
				onReject		= {() => this.state.loading ? console.log('loading') : this.props.onCloseModal()}
				onConfirm		= {() => this.state.loading ? console.log('loading') : this.verifyConfirm()}
				size			= 'size3'
			/>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InviteUserModal))