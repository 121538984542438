import { withStyles } from "@material-ui/core"
import React, { Component } from "react"
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback"
import { formatPhoneNumber } from "phone-numbers"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import PauseIcon from "@material-ui/icons/Pause"
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk"
const styles = (theme) => ({
	container: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "rgb(244, 245, 246, 0.6)",
		color: "white",
		// opacity: '0.6',
		zIndex: "1000",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		width: "100%",
	},
	incomingBtn: {
		height: 72,
		width: 354,
		background: `linear-gradient(96.54deg, ${theme.palette.tertiary["0"]} 30.3%, ${theme.palette.tertiary["-100"]} 72.1%)`,
		//TODO: color 2 not in palette
		// background: "linear-gradient(96.54deg, #2A9BB7 30.3%, #39B0CD 72.1%)",
		display: "flex",
		flexDirection: "row",
		borderRadius: 36,
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		opacity: 1,
		"&:active": {
			//TODO: not in palette
			background: `linear-gradient(96.54deg, ${theme.palette.tertiary["+100"]} 30.3%, ${theme.palette.tertiary["0"]} 72.1%)`,
			// background: "linear-gradient(95.01deg, #228199 22.61%, #2A97B2 71.4%)",
		},
		cursor: "pointer",
		animation: `$incomingPulsate 1.5s infinite`,
		margin: 100,
		marginBottom: 40,
		//TODO:
		// backgroundColor: 'rgba(72, 185, 213, 0.65)'
	},
	"@keyframes incomingPulsate": {
		"0%": {
			boxShadow: `0 0 0 0 , 0 0 0 0 ${theme.palette.tertiary["0"]}`,
		},
		"40%": {
			boxShadow: `0 0 0 15px rgba(72, 185, 213, 0), 0 0 0 0 ${theme.palette.tertiary["0"]}`,
		},
		"80%": {
			boxShadow: "0 0 0 15px rgba(72, 185, 213, 0), 0 0 0 10px rgba(72, 185, 213, 0)",
		},
		"100%": {
			boxShadow: "0 0 0 0 rgba(72, 185, 213, 0), 0 0 0 10px rgba(72, 185, 213, 0)",
		},
	},
	dismissBtn: {
		height: 44,
		width: 164,
		background: `linear-gradient(278.18deg, ${theme.palette.attention["-100"]} 7.94%, ${theme.palette.attention["0"]} 66.26%)`,
		borderRadius: 19,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"&:active": {
			background: `linear-gradient(278.18deg, ${theme.palette.attention["0"]} 7.94%, ${theme.palette.attention["+100"]}  66.26%)`,
		},
		cursor: "pointer",
		// animation: `$dismissPulsate 3s infinite`,
	},
	"@keyframes dismissPulsate": {
		"0%": {
			//TODO: find replacement for rgb opacity
			boxShadow: "0 0 0 0 rgba(255, 108, 92, 0.65), 0 0 0 0 rgba(255, 108, 92, 0.65)",
		},
		"40%": {
			boxShadow: "0 0 0 30px rgba(72, 185, 213, 0), 0 0 0 0 rgba(255, 108, 92, 0.65)",
		},
		"80%": {
			boxShadow: "0 0 0 30px rgba(72, 185, 213, 0), 0 0 0 15px rgba(72, 185, 213, 0)",
		},
		"100%": {
			boxShadow: "0 0 0 0 rgba(72, 185, 213, 0), 0 0 0 15px rgba(72, 185, 213, 0)",
		},
	},
})

class IncomingCallModal extends Component {
	// componentDidUpdate(oldProps) {
	// 	if (this.props.oldestIncomingCall)
	// }

	answerCall = () => {
		const { oldestIncomingCall } = this.props

		if (oldestIncomingCall) {
			this.props.answerById(oldestIncomingCall.callId)
		}
	}

	denyCall = () => {
		const { oldestIncomingCall } = this.props

		if (oldestIncomingCall) {
			this.props.hangupById(oldestIncomingCall.callId)
		}
	}

	render() {
		const { oldestIncomingCall, classes } = this.props
		let callerId = 'Unknown'
		let calleeId = ''
		if(oldestIncomingCall){
			callerId = (oldestIncomingCall.participants[0].callerId && oldestIncomingCall.participants[0].callerId !== '') ?
				oldestIncomingCall.participants[0].callerId : formatPhoneNumber(oldestIncomingCall.participants[0].phoneNumber)
			calleeId = (oldestIncomingCall.myCallInfo.callerId && oldestIncomingCall.myCallInfo.callerId !== oldestIncomingCall.myCallInfo.phoneNumber) ? 
				oldestIncomingCall.myCallInfo.callerId : formatPhoneNumber(oldestIncomingCall.myCallInfo.phoneNumber)
		}
		if(callerId.length > 17){
			callerId = callerId.substring(0, 17) + '...'
		}
		return (
			<>
				{oldestIncomingCall ? (
					<div className={classes.container}>
						{/* incoming call modal placeholder */}
						<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
							{/* answer button */}
							<div
								className={classes.incomingBtn}
								onClick={this.answerCall}
								data-test-id={"incoming-call-answer-button"}
							>
								<PhoneCallbackIcon style={{ width: 34, height: 34 }} />

								{/* left content */}
								<div style={{ display: "flex", flexDirection: "column" }}>
									<div style={{ fontSize: 12 }}>{"INCOMING CALL FROM"}</div>
									<div data-test-id="incoming-call-answer-button-info">
										<span style={{ fontSize: 16 }}>
											{callerId}
										</span>
									</div>
								</div>

								<div
									style={{
										width: 1,
										height: 40,
										backgroundColor: "white",
										marginLeft: 13,
										marginRight: 13,
									}}
								></div>

								{/* right content */}
								<div style={{ display: "flex", flexDirection: "column" }}>
									<div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
										<PauseIcon style={{ width: 26, height: 26 }} />

										<ArrowForwardIcon style={{ width: 26, height: 26, opacity: 0.6 }} />
										<PhoneInTalkIcon style={{ width: 26, height: 26 }} />
									</div>
									<span style={{ fontSize: 15 }}>
										{this.props.oldestIncomingCall ? "ANSWER" : "HOLD AND ANSWER"}
									</span>
									<span style={{ marginLeft: -4, fontSize: 10 }}>
										{"AS " + calleeId}
									</span>
								</div>
							</div>

							{/* ignore button */}
							<div
								className={classes.dismissBtn}
								onClick={this.denyCall}
								data-test-id={"incoming-call-dismiss-button"}
							>
								{"DISMISS"}
							</div>
						</div>
					</div>
				) : null}
			</>
		)
	}
}

export default withStyles(styles)(IncomingCallModal)
