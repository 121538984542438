import React, { Component } from 'react'
import { ExpandTriangle2, PersonAvatar, XIcon } from 'pdc-svg-icons'
import Button from 'button'
import TextField from 'pdc-text-field'
import { formatPhoneNumber } from 'phone-numbers'
import PersonAvatarComponent from 'person-avatar-component'

import styles from './styles'
import { ClickAwayListener, withStyles } from '@material-ui/core'
import { logout } from 'phonecom'
import { FSPrivate } from 'privacy'

class IdentityArea extends Component {

	state = {
		isExtensionSelectorExpanded:	false,
		showIdentityPopup:				false,
		filterExtensionsValue:			''
	}

	onClosePopup = () => this.setState({showIdentityPopup: false})

	signOut = () => logout()

	renderIdentityPopup = () => {
		let links = [
			{
				name: 'Help',
				url: `https://www.phone.com/support/`
			},
			{
				name: 'Get The Mobile App',
				url: `https://www.phone.com/mobile/`
			}
		]

		if (!process.env.REACT_APP_DOWNLOAD_LINKS_HIDDEN) {
			links = [
			{
				name: 'Get Windows My Phone.com App',
				url: `https://mypdc-dist-${process.env.REACT_APP_STAGE}.s3.amazonaws.com/phonedotcom/win/phonedotcom-latest.exe`
			},
			{
				name: 'Get Mac My Phone.com App',
				url: `https://mypdc-dist-${process.env.REACT_APP_STAGE}.s3.amazonaws.com/phonedotcom/mac/phonedotcom-latest.dmg`
			},
			{
				name: 'Get Linux My Phone.com App',
				url: `https://mypdc-dist-${process.env.REACT_APP_STAGE}.s3.amazonaws.com/phonedotcom/linux/phonedotcom-latest.AppImage`
			}
		].concat(links)
	}

		if (!this.state.showIdentityPopup) return null
		const { classes }		= this.props
		let avatarUrl			= window.V5PHONECOM.avatar_url || null
		let { name, number }	= this.getUserInfo()
		let isBJ				= Boolean(window.V5PHONECOM.user_id)
		console.log(this.props.screenViewType)
		let { isMobileView, isTabletView } = this.props.screenViewType || false
		return (
			<ClickAwayListener onClickAway={this.onClosePopup}>
				<div className={`${classes.identityPopup} ${isMobileView ? classes.identityPopupMobilePosition: classes.identityPopupDesktopPosition}`}>
					<div className='identity-popup-user-info'>
						<PersonAvatarComponent url={avatarUrl} size={100} color='tertiary'/>
						<span className='main-text'>{name}</span>
						<span className='secondary-text'>{number}</span>
					</div>
					<div className='identity-popup-options'>
						{links.map((link, i) => (
							<div
								key			= {i}
								className	='identity-popup-option'
								onClick		= {() => window.open(link.url, link.name)}
							>{link.name}</div>
						))}
					</div>
					<div className='identity-popup-actions'>
						<Button data-test-id='signout-button' onClick={this.signOut}>Sign out</Button>
					</div>
					<div data-test-id='identity-popup-close-button' className='x-icon-wrapper' onClick={this.onClosePopup}>
						<XIcon className='x-icon'/>
					</div>
				</div>
			</ClickAwayListener>
		)
	}

	onIdentityClick = () => {
		this.setState({showIdentityPopup: true})
	}

	renderIdentitySection = () => {
		let { name, number }	= this.getUserInfo()
		let avatarUrl			= window.V5PHONECOM.avatar_url || null
		return (
			<div data-test-id='identity-section' className='identity-section' onClick={this.onIdentityClick}>
				<div className='identity-text'>
					<div className='main-identity-text'>{name}</div>
					<div className='secondary-identity-text'>{number}</div>
				</div>
				<div className='identity-avatar'>
					<PersonAvatarComponent url={avatarUrl} size={45} color='tertiary'/>
				</div>
			</div>
		)
	}

	onExtensionHeaderClick	= () => this.toggleExtensionSelector(!this.state.isExtensionSelectorExpanded)
	toggleExtensionSelector	= isExtensionSelectorExpanded => this.setState({isExtensionSelectorExpanded})

	getExtensionName = extension => {
		let isBJ			= Boolean(window.V5PHONECOM.user_id)
		let extensionName	= extension.extension_name
		let extensionNumber	= extension.extension
		if (isBJ) return extension.is_company ? 'Company inbox' : 'My inbox'
		else return `Ext ${extensionNumber} - ${extensionName}`
	}

	getUserInfo = () => {
		let isBJ	= Boolean(window.V5PHONECOM.user_id)
		let name	= isBJ ? `${window.V5PHONECOM.first_name} ${window.V5PHONECOM.last_name}` : this.props.extension_name
		let number	= isBJ ? formatPhoneNumber(window.V5PHONECOM.direct_number) : `Ext. ${this.props.userInfo.extension.extension}`
		return { name, number }
	}

	onExtensionSelect = extension => {
		this.setState({isExtensionSelectorExpanded: false})
		let isBJ = Boolean(window.V5PHONECOM.user_id)
		if (isBJ) return null
		if (extension.extension_id === this.props.userInfo.extension.extension_id) return
		this.props.onExtensionSelect(extension)
	}

	renderExtensionSelector = () => {
		if (this.props.hideInboxSelector) return null
		let { userInfo } = this.props
		let mainText = this.getExtensionName(userInfo.extension)
		return (
			<ClickAwayListener onClickAway={() => this.toggleExtensionSelector(false)}>
				<div className={`extension-selector-section ${this.state.isExtensionSelectorExpanded ? 'expanded' : ''}`}>
					<div
						data-test-id	= 'extension_selector-header'
						className		= 'extension-selector-header'
						onClick			= {this.onExtensionHeaderClick}
					>
						<span>{mainText}</span>
						<ExpandTriangle2/>
					</div>
					{/* We need 2 wrappers over the extension items because we need some margin on the right of the scrollbar */}
					<div className='extension-selector-wrapper'>
						<div className='extension-selector-items-wrapper'>
							{userInfo.extensions.length > 5?
								<FSPrivate>
									<TextField
										data-test-id 	= {`extension_selector-search-box`}
										label			= 'Search extensions'
										content			= {this.state.filterExtensionsValue}
										onInputChange	= {filterExtensionsValue => this.setState({filterExtensionsValue})}
									/>
								</FSPrivate>
							: null}
							{userInfo.extensions.map(e => {

								let extensionItemName	= this.getExtensionName(e)
								let isSelected			= e.extension_id === userInfo.extension.extension_id
								let isBJ				= Boolean(window.V5PHONECOM.user_id)
								let TagName				= (isBJ && !isSelected) ? 'a' : 'div'
								let href				= `${window.location.origin}/e${e.extension_id}`
								let aProps				= TagName === 'a' ? {target: `my.phone.com-${e.extension_id}`, href} : {}
								let filter 				= this.state.filterExtensionsValue
								if(filter && !extensionItemName.toLowerCase().includes(filter.toLowerCase())){
									return
								}
								return (
									<FSPrivate key={e.extension_id}>
										<TagName
											data-test-id = {`extension_selector-item ${extensionItemName}`}
											className	= {`extension-selector-item ${isSelected ? 'selected' : ''}`}
											onClick		= {() => this.onExtensionSelect(e)}
											title		= {extensionItemName}
											{...aProps}
										>{extensionItemName}</TagName>
									</FSPrivate>
								)
							})}
						</div>
					</div>
				</div>
			</ClickAwayListener>
		)
	}

	render() {
		let { classes, extension } = this.props
		return (
			<div className={classes.identityWrapper}>
				{this.renderIdentitySection()}
				{this.renderExtensionSelector()}
				{this.renderIdentityPopup()}
			</div>
		)
	}
}

export default withStyles(styles)(IdentityArea)