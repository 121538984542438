import React, { Component } from "react"
import WifiIcon from "@material-ui/icons/Wifi"
import { DefaultArrowTooltip } from "tooltips"
import { iconBackgroundStyle } from "colors"
import PropTypes from "prop-types"
import {
	wifiLevel0Icon,
	wifiLevel1Icon,
	wifiLevel2Icon,
	wifiLevel3Icon,
	wifiLevel0MobileIcon,
	wifiLevel1MobileIcon,
	wifiLevel2MobileIcon,
	wifiLevel3MobileIcon,
} from "pdc-icons"

class SignalInfo extends Component {
	signalLevels = {
		3: "GREAT",
		2: "OK",
		1: "BAD",
		0: "Not Connected",
	}

	renderIcon = () => {
		const { height, width, mosScore, color, isMobile } = this.props

		if (!this.props.isActive) {
			return <img src={isMobile ? wifiLevel0MobileIcon : wifiLevel0Icon} alt="User Icon" />
		}

		//adjust mos score (1-5) to fit 1-3 scenario for signal strength indicator
		if (mosScore > 3.4) {
			return <img src={isMobile ? wifiLevel3MobileIcon : wifiLevel3Icon} alt="User Icon" />
		} else if (mosScore > 1.7) {
			return <img src={isMobile ? wifiLevel2MobileIcon : wifiLevel2Icon} alt="User Icon" />
		} else {
			return <img src={isMobile ? wifiLevel1MobileIcon : wifiLevel1Icon} alt="User Icon" />
		}
	}

	render() {
		const { jitter, packetsLost, avgLatency, mosScore, sendCodec, recvCodec } = this.props
		console.log(mosScore)
		let signalLevel = null
		if (mosScore > 3.4) {
			signalLevel = 3
		} else if (mosScore > 1.7) {
			signalLevel = 2 
		} else if (mosScore > 0) {
			signalLevel = 1
		} else {
			signalLevel = 0
		}

		return (
			<div onClick={this.props.onClick}>
				<DefaultArrowTooltip
					title={
						this.props.isActive ? (
							<div>
								Quality: {this.signalLevels[signalLevel]}
								<br />
								latency: {avgLatency || avgLatency === 0 ? avgLatency.toFixed(3) * 1000 : "N/A"} ms
								<br />
								jitter: {jitter || jitter === 0 ? jitter.toFixed(3) * 1000 : "N/A"} ms
								<br />
								packet loss: {packetsLost}%<br />
								send codec: {sendCodec}
								<br />
								recv codec: {recvCodec}
							</div>
						) : (
							"No Active Call"
						)
					}
					placement="top"
				>
					<div data-test-id={"signal-strength-indicator-icon"}>{this.renderIcon()}</div>
				</DefaultArrowTooltip>
			</div>
		)
	}
}
SignalInfo.propTypes = {
	/**
	 * jitter of connection
	 */
	jitter: PropTypes.number,
	/**
	 packet lost percentage
	 */
	packetsLost: PropTypes.number,
	/**
	 average of last 5 latency stats
	 */
	avgLatency: PropTypes.number,
	/**
	 * mos score 1-5
	 */
	mosScore: PropTypes.number,
	/**
	 * codec types
	 */
	sendCodec: PropTypes.string,
	recvCodec: PropTypes.string,
	isActive: PropTypes.bool,
}

SignalInfo.defaultProps = {
	jitter: 0,
	packetsLost: 0,
	avgLatency: 0,
	mosScore: 0,
	sendCodec: "N/A",
	recvCodec: "N/A",
	height: 24,
	width: 24,
	color: "white",
	isActive: false,
}

export default SignalInfo
