import { parsePhoneNumber, AsYouType } from 'libphonenumber-js'

// Takes a string and attempts to parse it as a phone number
// Returns arg if not a phone number
function formatPhoneNumber(phoneNumber) {
	let ret
	try {
		let parsedNum = parsePhoneNumber(phoneNumber, 'US')
		if (phoneNumber.includes('+1') || phoneNumber[0] !== '+')
			ret = parsedNum.formatNational()
		else
			ret = parsedNum.formatInternational()
	} catch (error) {
		ret = phoneNumber
	}
	return ret
}

function convertNumberToE164(phoneNumber) {
	let ayt = new AsYouType('US')
	ayt.input(phoneNumber)
	let e164PhoneNumber = ayt.getNumber().number
	return e164PhoneNumber
}


function isValidPhoneNumber(phoneNumber) {
	let ayt = new AsYouType('US')
	ayt.input(phoneNumber)
	let numberObject = ayt.getNumber()
	return numberObject ? numberObject.isValid() : false
}

function formatAsYouType(phoneNumber) {
	if (phoneNumber.length > 3) {
		let numberArray = Array.from(phoneNumber)
		numberArray.reverse()
		if (numberArray[0] !== ')' && numberArray[3] === '(') {
			phoneNumber = phoneNumber.substring(0, phoneNumber.length - 1)
		}
	}
	if (phoneNumber.length < 2) return phoneNumber
	if (phoneNumber[0] === '+' && phoneNumber[1] !== '1') {
		phoneNumber = formatPhoneNumber(phoneNumber)
		return phoneNumber
	}
	if (phoneNumber.substring(0, 2) === '+1') phoneNumber = phoneNumber.substring(2)
	phoneNumber = new AsYouType('US').input(phoneNumber)
	return phoneNumber
}

export { formatPhoneNumber, convertNumberToE164, isValidPhoneNumber, formatAsYouType }
