import PhoneComUser from 'phone-com-user'

export function currentConversation (state = null, action) {
  switch (action.type) {
    case 'SWITCH_CONVERSATION':
      const conversation	= action.conversation
      const extensionId		= parseInt(window.location.pathname.split('/')[1].substring(1))
      if (conversation) {
        const convId = conversation.id
        sessionStorage.setItem('session_conversation_id', convId)
        window.history.replaceState(`ConversationId: ${convId}`, 'Conversation Switched', `/e${extensionId}/messages/c${convId}`)
      } else {
        window.history.replaceState('Conversation Selector', 'Conversation Closed', `/e${extensionId}/messages`)
      }
      return conversation
    default:
      return state
  }
};

export function selectedSendNumber (state = null, action) {
  switch (action.type) {
    case 'SWITCH_SEND_NUMBER':
      if (Array.isArray(action.number)) return [...action.number]
      return action.number
    default:
      return state
  }
}

export function totalConversations (state = null, action) {
  switch (action.type) {
    case 'UPDATE_TOTAL_CONVERSATIONS':
      return action.total
    default:
      return state
  }
}

function areArraysSame (array1, array2) {
  if (array1.length !== array2.length) {
    return false
  }
  const differentElements = array1.filter(element => !array2.includes(element))
  return !differentElements.length
}

function filterParticipants (conversation) {
  const lastMessage		= conversation.last_message
  let participants	= JSON.parse(JSON.stringify(lastMessage.to))
  participants.forEach(p => {
    delete p.delivered_at
    delete p.delivery_status
    delete p.sent_at
  })
  if (!participants.find(p => p.number === lastMessage.from_contact.number)) { participants	= participants.concat([lastMessage.from_contact]) }
  const myPhoneNumbers	= PhoneComUser.getPhoneNumber()
  const fromNumbers		= []
  participants.filter(r => myPhoneNumbers.includes(r.number)).forEach(r => {
    if (!fromNumbers.find(n => n.number === r.number)) {
      fromNumbers.push(r)
    }
  })
  const recipients = participants.filter(r => !myPhoneNumbers.includes(r.number))
  return [participants, fromNumbers, recipients]
}

function updateNumbers (conversation) {
  [conversation.participants, conversation.from, conversation.to] = filterParticipants(conversation)
}

export function conversations (state = [], action) {
  let newConvs = null
  let conv_ind = null
  let newState = null
  let messages = null
  switch (action.type) {
    case 'UPDATE_CONVERSATION':
      newConvs = [...state]
      conv_ind = newConvs.findIndex(conv => conv.id === action.conversation.id)
      if (conv_ind >= 0) {
        newConvs[conv_ind] = action.conversation
        messages = newConvs[conv_ind].messages
        if (messages) {
          if (messages.length) newConvs[conv_ind].last_message = messages[messages.length - 1]
          else newConvs[conv_ind].last_message = {}
        }
        newConvs[conv_ind].participants = [...newConvs[conv_ind].participants]
      }
      return newConvs
    case 'UPDATE_CONVERSATIONS':
      newState = []
      newConvs = [...action.conversations]
      newConvs.map(c => updateNumbers(c))
      // Update each conversation if there exists another with the same not_my_numbers
      newConvs.forEach(conversation => {
        conversation.hasDuplicate = false
        const sameNots = newState.find(c => areArraysSame(c.not_my_nums.map(c => c.number), conversation.not_my_nums.map(c => c.number)))
        if (sameNots) {
          // This means that there is loaded at least one more conversation that has same recipients
          // We need that info in order to show the senders, along with the recipients, for those conversations in the selector
          sameNots.hasDuplicate		= true
          conversation.hasDuplicate	= true
        }
        newState.push(JSON.parse(JSON.stringify(conversation)))
      })
      return newState
    case 'ADD_CONVERSATION':
      newState = state.slice()
      if (!action.conversation.to && !action.conversation.from) { updateNumbers(action.conversation) }
      newState.unshift(action.conversation)
      return newState
    case 'ADD_CONVERSATIONS':
      newState = state.slice()
      newConvs = action.conversations.slice()
      newConvs.map(c => updateNumbers(c))
      // Update each conversation if there exists another with the same not_my_numbers
      newConvs.forEach(conversation => {
        conversation.hasDuplicate = false
        const sameNots = newState.find(c => areArraysSame(c.not_my_nums.map(c => c.number), conversation.not_my_nums.map(c => c.number)))
        if (sameNots) {
          // This means that there is loaded at least one more conversation that has same recipients
          // We need that info in order to show the senders, along with the recipients, for those conversations in the selector
          sameNots.hasDuplicate = true
          conversation.hasDuplicate = true
        }
        if (!state.find(c => c.id === conversation.id)) {
          newState.push(conversation)
        }
      })
      return newState
    case 'DELETE_CONVERSATIONS':
      newState = state.slice()
      const conversationIds = action.conversations.map(c => c.id)
      newState = newState.filter(c => !conversationIds.includes(c.id))
      return newState
    case 'ADD_MESSAGE':
      // Create the conversation if it is not there
      newState = state.slice()
      conv_ind = newState.findIndex(conv => conv.id === action.conversation_id)

      // Create a conversation object from the newest message
      if (conv_ind === -1) {
        return newState
      }
      // Change conversation last_message if needed
      if ((!newState[conv_ind].last_message) ||
				(newState[conv_ind].last_message.created_at < action.message.created_at)) {
        newState[conv_ind].last_message = action.message
      }

      newState[conv_ind].total += 1

      if (action.message.direction === 'in') { newState[conv_ind].unread_messages += 1 }

      if (action.unread_messages) { newState[conv_ind].unread_messages = action.unread_messages }

      if (newState[conv_ind].messages == null) { newState[conv_ind].messages = [] }
      newState[conv_ind].messages.push(action.message)

      if (action.message.isPending !== true && action.message.media.length && newState[conv_ind].mediaInfo) {
        storeNewMedia(newState[conv_ind], action.message)
      }

      // pop and push to move this conversation to the top of the list (last_message)
		   newState.unshift(newState.splice(conv_ind, 1)[0])
		   return newState

    case 'UPDATE_MESSAGE':
      newState = state.slice()
      conv_ind = newState.findIndex(conv => conv.id === action.conversation_id)
      const updateMessageTag = action.message.tag
      let messageToBeUpdated = newState[conv_ind].messages.find(m => {
        return m.tag && m.tag === updateMessageTag
      })

      if (messageToBeUpdated) {
        Object.assign(messageToBeUpdated, action.message)
        messageToBeUpdated.isPending = false
        newState[conv_ind].last_message = action.message

        if (hasNewMedia(newState[conv_ind], action.message)) {
          storeNewMedia(newState[conv_ind], action.message)
        }

        return newState
      } else {
        const messageToBeUpdatedIndex = newState[conv_ind].messages.findIndex(m => m.message_id === action.message.message_id)
        messageToBeUpdated = newState[conv_ind].messages[messageToBeUpdatedIndex]

        if (!messageToBeUpdated) {
          console.error('No message to update for message:', action.message)
          return state
        }

        if (Boolean(newState[conv_ind].messages[messageToBeUpdatedIndex].read_at) !== Boolean(action.message.read_at)) {
          if (action.message.read_at) {
            newState[conv_ind].unread_messages--
          } else {
            newState[conv_ind].unread_messages++
          }
        }

        newState[conv_ind].messages[messageToBeUpdatedIndex] = action.message
        return newState
      }

    case 'UPDATE_MESSAGE_STATUS':
      newState = state.slice()
      conv_ind = newState.findIndex(conv => conv.id === action.conversation_id)
      messageToBeUpdated = newState[conv_ind].messages.find(m => action.by === 'message_id' ? (action.flag === m.message_id) : (action.flag === m.tag))
      if (messageToBeUpdated) {
        messageToBeUpdated.to = action.recipients_info
      }
      return newState

      // Append a message to structure
    case 'UPDATE_MESSAGES':
      newState = state.slice()
      conv_ind = newState.findIndex(conv => conv.id === action.conversation_id)
      if (conv_ind !== -1) {
        newState[conv_ind].messages = action.messages
        // This is a check to see if this conv's messages have been polled before
        newState[conv_ind].checkedMessages = true
      }
      return newState
      // Update messages of a structure

    case 'INSERT_MESSAGES':
      conv_ind = state.findIndex(conv => conv.id === action.conversation_id)
      if (conv_ind !== -1) {
        const messages			= state[conv_ind].messages
        const position			= action.position
        let startIndex			= action.startIndex
        let messagesToBeAdded	= []
        action.messages.forEach(m => {
          if (position === 'after') {
            if (m.created_at > messages[startIndex].created_at || (
              m.created_at === messages[startIndex].created_at && m.message_id >= messages[startIndex].message_id
            )) return
            messagesToBeAdded.push(m)
          } else {
            if (m.created_at < messages[startIndex].created_at || (
              m.created_at === messages[startIndex].created_at && m.message_id <= messages[startIndex].message_id
            )) return
            messagesToBeAdded.push(m)
          }
        })
        if (position === 'before') {
          messagesToBeAdded = messagesToBeAdded.reverse()
          startIndex++
        }
        messages.splice(startIndex, 0, ...messagesToBeAdded)
      }
      return state

    case 'UPDATE_MEDIA_INFO':
      conv_ind = state.findIndex(conv => conv.id === action.conversation_id)
      if (state[conv_ind]) {
        state[conv_ind].mediaInfo = action.media_info
      }
      return state

    case 'DELETE_MESSAGE':

      if (action.by === 'message_id') {
        console.log(`Message with inbox_id: ${action.flag} should be deleted from conversation with id: ${action.conversation_id}`)
      } else {
        console.log(`Message with tag: ${action.flag} should be deleted from conversation with id: ${action.conversation_id}`)
      }
      newState = state.slice()
      conv_ind = newState.findIndex(conv => conv.id === action.conversation_id)

      // 1. Remove the message from the conversation
      messages = newState[conv_ind].messages
      const messageIndex = messages.findIndex(m => action.by === 'message_id' ? (action.flag === m.message_id) : (action.flag === m.tag))
      const message = messages[messageIndex]
      messages.splice(messageIndex, 1)
      newState[conv_ind].messages = messages
      console.log('Message removed')

      // 2. Remove the message's media from mediaInfo from the conversation
      if (message && message.media.length) {
        const mediaInfo = newState[conv_ind].mediaInfo
        const updatedMediaArray = []
        let totalDeleted = 0
        mediaInfo.media.forEach(file => {
          if (file.message_id !== message.message_id) {
            updatedMediaArray.push(file)
          } else {
            totalDeleted++
          }
        })
        mediaInfo.media		= updatedMediaArray
        mediaInfo.total		-= totalDeleted
        mediaInfo.dbTotal	-= totalDeleted
        newState[conv_ind].mediaInfo = mediaInfo
        console.log('Media removed')
      }

      // 3. Update the last message info in the conversation
      const lastMessage = messages.length ? messages[messages.length - 1] : {}
      newState[conv_ind].last_message = lastMessage || {}
      newState[conv_ind].total -= 1
      console.log('Conversations list updated')
      return newState
    case 'ADD_CONTACTS_TO_CONVERSATIONS':
      newState		= state
      const contacts	= action.contacts
      newState.forEach(c => {
        const participantsArrays = [
          {
            name: 'participants',
            items: c.participants
          },
          {
            name: 'to',
            items: c.to
          },
          {
            name: 'not_my_nums',
            items: c.not_my_nums
          }
        ]

        participantsArrays.forEach(pa => {
          const participants = pa.items
          participants.forEach((p, i) => {
            const foundContact = contacts.find(contact => contact.numbers.find(n => n.number === p.number))
            if (!foundContact) return
            participants[i] = {
              ...participants[i],
              voip_contact_id: foundContact.id,
              name: foundContact.name.display
            }
          })
          c[pa.name] = participants
        })
      })
      return [...newState]
    case 'REMOVE_CONTACT_FROM_CONVERSATIONS':
      newState		= state
      const contactId	= action.contactId
      newState.forEach(c => {
        const participantsArrays = [
          {
            name: 'participants',
            items: c.participants
          },
          {
            name: 'to',
            items: c.to
          },
          {
            name: 'not_my_nums',
            items: c.not_my_nums
          }
        ]
        participantsArrays.forEach(pa => {
          const participants = pa.items
          participants.forEach((p, i) => {
            if (p.voip_contact_id === contactId) {
              participants[i] = {
                ...participants[i],
                voip_contact_id: null,
                name: ''
              }
            }
          })
          c[pa.name] = participants
        })
      })
      return [...newState]
    default:
      return state
  }
};

function hasNewMedia (conversation, newMessage) {
  // Returns true if there is media in the newMessage and if that
  // media hasn't been put in the mediaInfo before
  const mediaInfo = conversation.mediaInfo
  if (!mediaInfo) {
    return console.error(`Media info not initialized for conversation id: ${conversation.id} yet`)
  }

  const totalMedia = newMessage.media.length
  const fileWithSameMessageId = mediaInfo.media.find(file => file.message_id === newMessage.message_id)
  return totalMedia && !fileWithSameMessageId
}

function storeNewMedia (conversation, messageResponse) {
  const mediaInfo = conversation.mediaInfo
  if (!mediaInfo) {
    return console.error(`Media info not initialized for conversation id: ${conversation.id} yet`)
  }

  messageResponse.media.forEach(m => {
    const newMedia = {
      created_at:	messageResponse.created_at,
      filename:	m.filename,
      size:	m.size,
      type:	m.type,
      url:	m.url,
      from:	messageResponse.from_number,
      has_thumbnail:	m.has_thumbnail,
      message_id:	messageResponse.message_id
    }

    if (m.type.substring(0, 5) === 'image' && !m.width) {
      const image = new Image()
      image.onload = function () {
        newMedia.width = this.width
        newMedia.height = this.height
      }
      image.src = newMedia.url
    }

    mediaInfo.offset++
    mediaInfo.dbTotal++
    mediaInfo.total++
    mediaInfo.media.push(newMedia)
  })
};

export function conversationTotals (state = {}, action) {
  switch (action.type) {
    case 'UPDATE_TOTAL':
      state[action.conversation_id] = action.total
      return state
    default:
      return state
  }
}
