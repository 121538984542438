import React from 'react'
import { connect } from 'react-redux'
import API from './utils/api_v5'
import PDCButton from 'pdc-button'
import LoaderFull from 'loader-full'
import { MeetingsCopyDetailsIcon, PopupIcon } from 'pdc-svg-icons'
import { updateUser } from './actions/users'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { withStyles } from '@material-ui/styles'

import { theme } from 'get-theme'
import MeetingsImage from './utils/images/meetings-image.jpg'

const styles = theme => ({
	infoItemsWrapper: {
		textAlign:		'center',
		fontSize:		20,
		lineHeight:		1.3,
		letterSpacing:	-0.05,
		'& .value': {
			fontWeight:	'bold'
		},
		'&.small': {
			fontSize: 18
		}
	},
	infoItem: {
		'&:not(:first-child)': {
			marginTop: 20
		}
	},
	bullet: {
		display:	'inline-block',
		margin:		'0 2px',
		transform:	'scale(0.8)',
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
	linkStyle: {
		color: theme.palette.primary.main
	},
	wrapper:{
		position:	'relative',
		overflow:	'scroll',
		height:		'100%',
		'&::-webkit-scrollbar': {
			display: 'none'
		},
		'-ms-overflow-style': 'none'
	},
	mainContent: {
		paddingTop: 	40,
		display:		'flex',
		flexDirection:	'column',
		alignItems:		'center',
		'&.small': {
			padding:	'35px 15px 0'
		}
	},
	meetButton: {
		position:		'relative',
		fontSize:		45,
		fontWeight:		'normal',
		fontFamily:		'NTR, Montserrat, Helvetica, arial, sans-serif !important',
		lineHeight:		0.75,
		letterSpacing:	-1.5,
		padding:		'20px 0',
		width:			350,
		borderRadius:	60,
		marginBottom:	30,
		'&.small': {
			fontSize:		40,
			width:			'80%',
			padding:		'22px 0',
			marginBottom:	20
		}
	},
	arrowIcon: {
		width:		'0.9em',
		height:		'0.9em',
		fontSize:	'unset',
		position:	'absolute',
		right:		30
	},
	copyDetailsButton: {
		width:	180,
		height:	50,
		margin:	'30px 0',
		cursor:	'pointer',
		'&.small': {
			margin: '20px 0'
		}
	},
	goToDashboardButton: {
		padding:		'17px 30px',
		fontSize:		26,
		lineHeight:		1.12,
		letterSpacing:	-0.37,
		borderRadius:	60,
		borderWidth:	3,
		boxShadow:		'none',
		fontWeight:		'normal',
		'& svg': {
			width:		26,
			height:		30,
			marginLeft:	20
		},
		'&.small': {
			fontSize:	20,
			padding:	'14px 20px'
		}
	},
	copiedMessage: {
		width:			89,
		height:			49,
		borderRadius:	5,
		boxShadow:		'2px 2px 5px 0 rgba(0, 0, 0, 0.2)',
		background:		'#88969e',
		position:		'absolute',
		left:			50,
		bottom:			40,
		color:			'white',
		display:		'flex',
		alignItems:		'center',
		justifyContent:	'center',
		fontSize:		14,
		fontWeight:		600,
		lineHeight:		1.36,
		userSelect:		'none'
	},
	welcomeScreenWrapper: {
		display:	'flex',
		height:		'100%'
	},
	imageSection: {
		background:		'#9ea8b1',
		display:		'flex',
		flexDirection:	'column',
		alignItems:		'center',
		justifyContent:	'space-around',
		width:			321
	},
	meetingsComputerImageWrapper: {
		width: '100%',
		'& img': {
			width: '100%'
		}
	},
	meetingsLogoImageWrapper: {
		display:		'flex',
		flexDirection:	'column',
		alignItems:		'center',
		justifyContent:	'center',
		'& span': {
			color:		'#F2F5F6',
			fontWeight:	'bold',
			fontSize:	25,
			marginTop:	15
		}
	},
	infoSection: {
		flex:			1,
		display:		'flex',
		flexDirection:	'column',
		justifyContent:	'center',
		alignItems:		'center',
		'& div': {
			maxWidth:	483,
			padding:	'0 75px'
		},
		'& .main-text': {
			fontSize:	14,
			lineHeight:	1.43,
			'&:last-child': {
				marginTop: 15
			}
		},
		'& .more-info-link': {
			display:		'block',
			marginTop:		15,
			fontSize:		14,
			lineHeight:		1.43,
			letterSpacing:	-0.05,
			color:			theme.palette.primary.main,
			fontWeight:		'bold'
		},
		'& .ask-text': {
			display:	'block',
			marginTop:	15,
			'& a': {
				color:		theme.palette.primary.main,
				fontWeight:	'bold'
			}
		}
	}
})

const mapStateToProps = state => ({
	user: state.user
})

const mapDispatchToProps = dispatch => ({
	updateUser: user => dispatch(updateUser(user))
})

class App extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			user:				null,
			copyDetailsHover:	false,
			inviteCopied:		false
		}
		console.log(props)
	}

	componentDidMount = async () => {
		console.log('mounted')
		let response = await API.get_user(this.props.extension.extension_id)
		this.props.updateUser(response)
		this.props.onLoaded()
	}

	 onCopyDetailsClick = async () => {
		var text = 'You have been invited to a video meeting.\n\n'
		text += `You can join the meeting by visiting ${this.props.user.meeting_url}.`
		text += `${this.props.user.primary_dialin_number ?
				`\n\nOr you may dial in to ${this.props.user.primary_dialin_number.international_format} and use access code ${this.props.user.access_code}.` : ''}`
		this.setState({inviteCopied: true})

		try {
			await navigator.clipboard.writeText(text)
			console.log('Async: Copying to clipboard was successful!')
			window.setTimeout(() => this.setState({inviteCopied: false}) ,2000)
		} catch(err) {
			console.error('Async: Could not copy text: ', err)
		}
	}

	getBaseUrl = fullUrl => {
		let pathArray	= fullUrl.split( '/' )
		let protocol	= pathArray[0]
		let host		= pathArray[2]
		let url			= protocol + '//' + host
		return url
	}

	toggleCopyDetailsHover = copyDetailsHover => this.setState({copyDetailsHover})
	toggleGoToDashboardHover = goToDashboardHover => this.setState({goToDashboardHover})

	renderWelcomeScreen = () => {
		const { classes } = this.props
		let content = null
		if (window.V5PHONECOM && window.V5PHONECOM.user_id){
			content = <div>
				<p>Video meetings allows you to conduct video meetings online where you can see and hear each other, share your computer screen, and much more.</p>
				<p>Video comes included with your Phone.com plan, but you need to ask your Phone.com administrator to assign you a meeting host using our admin tool.</p>
			</div>
		}else{
			content = <div>
				<span className='main-text'>
					Introducing our new video conferencing and screen sharing solution. Now you can collaborate from anywhere using your personal computer. Plans start at just $4.99 per month per host. HD video conferencing with Phone.com is the next best thing to being in person. You can:
					<ul>
						<li>Conduct video meetings</li>
						<li>Meet for as long as you want</li>
						<li>Share your computer screen and documents</li>
						<li>Use your phone or computer audio</li>
						<li>Invite participants with a personal meeting room link</li>
						<li>Start or join a meeting with no downloads and nothing to install</li>
						<li>Choose one of our HIPAA compliant plans</li>
					</ul>
				</span>
				<a className='more-info-link' href='https://www.phone.com/features/video-conferencing-service/'>Find pricing details and more information here</a>
				<span className='ask-text'>Ask your <a href='https://www.phone.com/'>Phone.com</a> administrator to add Video Meetings to your account.</span>
			</div>
		}
		return (
			<div className={classes.welcomeScreenWrapper}>
				<div className={classes.imageSection}>
					<div style={{height: 81}}></div>
					<div className={classes.meetingsComputerImageWrapper}>
						<img src={MeetingsImage}/>
					</div>
					<div className={classes.meetingsLogoImageWrapper}>
						<img src={theme.icons.ghostIcon} />
						<span>VIDEO MEETINGS</span>
					</div>
				</div>
				<div className={classes.infoSection}>
					{content}
				</div>
			</div>
		)
	}

	meetNowURL = () => {
		if(window.V5PHONECOM.user_id){
			let pathArray	= this.props.user.meeting_url.split( '/' )
			let base_url	= pathArray[0] + '//' + pathArray[2]
			let iotum_auth	= '/auth/openid_connect?landing_url='
			let landing_url = '%2F' + pathArray[3] + '%2F' + pathArray[4] + '%2F' + pathArray[5]
			let url			= base_url + iotum_auth + landing_url
			return url
		}
		return this.props.user.meeting_url
	}

	render() {
		const { classes } = this.props
		console.log('bj user', this.props.user)
		let renederInformationItems = items => {
			return items.map((item, index) => (
				<div className={classes.infoItem} key={index}>
					<span>{item.title}</span><br/>
					<span className='value'>{item.value}</span>
				</div>
			))
		}

		let isSmall = this.props.screenViewType.isMobileView

		return (
			<div className={classes.wrapper}>
				{(this.props.user && !this.props.user.errorMessage) ?
					<div className={`${classes.mainContent} ${isSmall ? 'small' : ''}`}>
						<PDCButton
							variant		= 'new-primary'
							className	= {`${classes.meetButton} ${isSmall ? 'small' : ''}`}
							onClick		= {() => window.open(this.meetNowURL(), '_blank')}
						>
							Meet now <ArrowForwardIcon className={classes.arrowIcon}/>
						</PDCButton>
						<div className={`${classes.infoItemsWrapper} ${isSmall ? 'small' : ''}`} variant='outlined'>
							{renederInformationItems([
								{title: 'Meeting URL',		value: <a className={classes.linkStyle} href={this.props.user.meeting_url} target='_blank'>{this.props.user.meeting_url}</a>},
								{title: 'Dial in Number',	value: this.props.user.primary_dialin_number ? this.props.user.primary_dialin_number.international_format : 'no dial in numbers found'},
								{title: 'Access Code',		value: this.props.user.access_code},
								{title: 'Moderator Pin',	value: this.props.user.moderator_pin},
								{title: 'Connected Email',	value: this.props.user.email}
							])}
						</div>

						<MeetingsCopyDetailsIcon
							className		= {`${classes.copyDetailsButton} ${isSmall ? 'small' : ''}`}
							hover			= {this.state.copyDetailsHover ? 1 : 0}
							onClick			= {this.onCopyDetailsClick}
							onMouseEnter	= {() => this.toggleCopyDetailsHover(true)}
							onMouseLeave	= {() => this.toggleCopyDetailsHover(false)}
						/>

						<PDCButton
							className		= {`${classes.goToDashboardButton} ${isSmall ? 'small' : ''}`}
							onClick			= {() => window.open(this.getBaseUrl(this.props.user.meeting_url), '_blank')}
							onMouseEnter	= {() => this.toggleGoToDashboardHover(true)}
							onMouseLeave	= {() => this.toggleGoToDashboardHover(false)}
						>
							Go to your Meetings Dashboard <PopupIcon hover={this.state.goToDashboardHover ? 1 : 0}/>
						</PDCButton>
					</div>
				: this.props.user ? this.renderWelcomeScreen()
				: <LoaderFull styles={{loaderFull: {left: this.props.smallView ? '50%' : 'calc(50% + 120px)'}}} size='big'/>
				}
				{this.state.inviteCopied ? <div className={classes.copiedMessage}>Copied!</div> : null}
			</div>
		)
	}

}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))