import CallSession from "./CallSession"
import {
	UserAgent,
	Inviter,
	SessionState,
	RegistererState,
	Session,
	Registerer,
	RegistererRegisterOptions,
	SessionByeOptions,
} from "sip.js"
import { CallState } from "../enums/CallState"
import { CallActions } from "../enums/CallActions"
import { CallerInfo } from "../interfaces/CallerInfo"
import { CallEventsDelegate } from "../interfaces/CallEventsDelegate"
import { CallStats } from "../interfaces/CallStats"
//@ts-ignore
import holdSound from "../sounds/lawsuit-free-hold.mp3"

declare let window: any;
const CALLSESSIONTYPE = "sip"
export class SipCallSession extends CallSession {
	isNewCall: boolean = false
	joinedSessions: string[] = []

	myCallInfo: CallerInfo | null
	callType: string
	callId: string

	callStartTime?: number
	callEndTime?: number
	session?: Inviter
	callState: CallState | null = CallState.INACTIVE
	callAnswered: boolean = false
	participants: CallerInfo[] = []
	callInviteEvent: any
	callEventsDelegate: CallEventsDelegate

	recentConnectionStats: Array<any> = []

	mergedCallIDs: { [key: string]: string } = {}
	isMerged: boolean = false
	isMutedLocal: boolean = false
	isMutedRemote: boolean = false

	isOnHold: boolean = false
	holdTrack?: MediaStreamTrack = undefined //each call requires its own mediaTrack for hold to work correctly. to keep track of whos hold music should be playin we use the mediaStreamTrack id's and the enabled field.
	holdTrackSrc?: AudioBufferSourceNode = undefined

	statsIntervalId?: any = undefined
	constructor(
		participants: CallerInfo[],
		myNumber: CallerInfo,
		callId: string,
		callState: CallState,
		delegate: CallEventsDelegate
	) {
		super()
		this.participants = participants
		this.myCallInfo = myNumber
		this.callType = CALLSESSIONTYPE
		this.callId = callId
		this.callEventsDelegate = delegate
		// this.setupHoldMusic()
	}

	public hangup = async () => {
		let byeOpts: SessionByeOptions = {
			requestDelegate: {
				// onProgress: () => {
				//     //soon as its sent dont worry if it comes back, hangup thecall locally.
				//     this.callEventsDelegate.onCallHangup(this.callId)
				// },
			},
		}
		//TODO: if canceling before session established on outgoing, cant stop ringing so user's callee still picks up a call that was canceled
		if (this.session && SessionState && this.session!.state === SessionState.Established) {
			await this.session!.bye()
		} else if (this.session) {
			await this.session.cancel()
		} else {
			this.callEventsDelegate.onCallHangup(this.callId)
		}
	}
	public prepareInvite = async (userAgent: UserAgent): Promise<any> => {
		let headers: string[] = []
		if (this.callInviteEvent) {
			headers = [`X-Slot: ${this.callInviteEvent.call.uuid}`, `X-Server: ${this.callInviteEvent.call.host}`]
		}

		this.sendInvite(userAgent, headers)
	}
	private sendInvite = (userAgent: any, extraHeaders: any = []) => {
		// Create a user agent client to establish a session
		console.log(extraHeaders)
		const target = UserAgent.makeURI(`sip:${this.callId}@phone.com`)
		if (!target) return

		const inviter = new Inviter(userAgent, target, {
			sessionDescriptionHandlerOptions: {
				constraints: { audio: true, video: false },
			},
			delegate: {
				onRefer: (referal) => {
					console.log("referal detected", referal)
				},
			},
			extraHeaders: extraHeaders,
		})
		const outgoingSession = inviter

		// Setup outgoing session delegate
		// outgoingSession.delegate = {
		// 	// Handle incoming REFER request.
		// 	onRefer: (referal) => {
		// 		console.log("referal detected", referal)
		// 	},
		// }
		// Handle outgoing session state changes
		console.log("inviter", inviter)
		this.addInvSessionStateChangeListeners(inviter)
		try {
			inviter.invite({
				requestOptions: { extraHeaders: extraHeaders },
				requestDelegate: {
					onAccept: (res) => {
						console.log("does inviter have sdh", inviter)
					},
					onReject: (res) => {
						console.log(res)
						console.log('rejected')
					},
					onProgress: (res) => {
						console.log(res)
						console.log('prog')
					},
				}
			})
		} catch (err) {
			//if you hit this error, and inv.shouldRequestMedia is true, show error message, try to wipe session.
			console.log(err)
			console.log(typeof err)
		}
	}
	//to hold means to mute incoming audio, and replace the microphone with an audio loop that contains music or silence.

	//for now also means to load the audio track since we cannot seem to keep Bufer obj's source around to start and stop as we please. after first start()->stop() node must be disposed of.
	public hold = async (holdMusicLink?: string): Promise<void> => {

		let xhr = new XMLHttpRequest()
		xhr.open("GET", holdMusicLink ? holdMusicLink : holdSound, true)
		xhr.responseType = "blob"

		xhr.onload = (e) => {
			console.log(xhr.response)
			let reader = new FileReader()
			reader.onload = (readEvent) => {
				const context = new AudioContext()
				context.decodeAudioData(readEvent.target!.result as any, (buffer: AudioBuffer) =>
					this.playHoldMusic(context, buffer)
				)
			}
			let file = xhr.response
			reader.readAsArrayBuffer(file)
		}
		xhr.send()
	}
	//to unhold means to unmute incoming audio, and replace the hold music track with the micrphone.
	public unhold = async (): Promise<void> => {

		if (!this.isOnHold) {
			return
		}

		this.muteRemote(false)
		this.isOnHold = false
		let stream = window.micStream
		let sdh: any = this.session!.sessionDescriptionHandler!
		let pc: RTCPeerConnection = sdh.peerConnection
		let senders = pc.getSenders()

		for (let sender of senders) {
			console.log(sender.track!.label)
			console.log(sender.track!.id)
			this.holdTrack!.stop()
			const micTrack = stream.getAudioTracks()[0] as MediaStreamTrack
			await sender.replaceTrack(micTrack)
			// this.holdTrackSrc!.stop(0)
		}

		this.callEventsDelegate.onManagerStateUpdate(this.callId)
	}

	private playHoldMusic = async (context: AudioContext, buffer: AudioBuffer) => {
		let source = context.createBufferSource()
		source.loop = true
		source.buffer = buffer

		const remote = context.createMediaStreamDestination()
		const gain = context.createGain()
		gain.gain.value = 0.5
		source.connect(remote)
		source.connect(gain)

		this.holdTrack = remote.stream.getAudioTracks()[0]
		//TODO: getTrackById() - is this helpful? for multi call scenarios.
		this.holdTrackSrc = source

		//start actual hold logic
		this.muteRemote(true)
		let sdh: any = this.session!.sessionDescriptionHandler!
		let pc: RTCPeerConnection = sdh.peerConnection
		let senders = pc.getSenders()

		for (let sender of senders) {
			console.log(sender.track!.label)
			await sender.replaceTrack(this.holdTrack!)
			//audioBufferNode can only be started once. so need to create at time of hold() for now
			try {
				// await this.setupHoldMusic()
				this.holdTrackSrc!.start(0)
			} catch (err) {
				console.log("err cannot start() more than once on same buffer: ", err)
			}
		}

		this.isOnHold = true
		this.callEventsDelegate.onManagerStateUpdate(this.callId)
		//end hold logic.
	}

	public muteLocal = (isMuted: boolean): void => {
		if (this.session) {
			let session = this.session as any
			console.log(session)
			let pc = session.sessionDescriptionHandler.peerConnection
			let senders = pc.getSenders()
			senders.forEach((sender: RTCRtpSender) => {
				let track = sender.track!
				track.enabled = !isMuted
				this.isMutedLocal = !track.enabled
				console.log(this.isMutedLocal)
			})
			this.callEventsDelegate.onManagerStateUpdate(null)
		}
	}

	//cannot be toggle for scenario where one call is muted and third call isnt. youd never be able to mute all calls.
	public muteRemote = (isMuted: boolean): void => {
		if (this.session) {
			let session = this.session as any
			console.log(session)
			let pc = session.sessionDescriptionHandler.peerConnection
			pc.getReceivers().forEach((receiver: RTCRtpReceiver) => {
				let track = receiver.track
				track.enabled = !isMuted
				this.isMutedRemote = !track.enabled
			})
			this.callEventsDelegate.onManagerStateUpdate(null)
		}
	}

	public createCall = (): void => {
		throw new Error("Method not implemented.")
	}
	public showCallStats = (): void => {
		const interval = 3000
		if (window.cordova) {
			return;
		}
		if (!this.session || !this.session.sessionDescriptionHandler) {
			console.error('trying to publish stats on a session that does not exist')
			return
		}

		let myPeerConnection = (this.session as any).sessionDescriptionHandler.peerConnection as RTCPeerConnection

		let intervalId = setInterval(async () => {
			let stats: RTCStatsReport = await myPeerConnection.getStats(null);
			console.log('type:', typeof stats, 'obj:', stats)
			this.generateConnectionStats(stats)
			let statsObj: CallStats = this.getCallStats(this.recentConnectionStats) || 0
			console.log(statsObj)
			this.callEventsDelegate.onCallStatUpdate(this.callId, statsObj)

		}, interval);

		this.statsIntervalId = intervalId
	}

	private generateConnectionStats = (stats: RTCStatsReport) => {
		let connStats = {
			audio: {
				latency: 0,
				packetsLost: 0,
				sendCodec: 'N/A',
				recvCodec: 'N/A',
				jitter: 0,
			},
		}

		stats.forEach(report => {

			//chrome latency
			if (report.type === 'candidate-pair' && report['currentRoundTripTime']) {
				connStats.audio.latency = report['currentRoundTripTime'] / 2
			}

			//TODO: find a way to calculate ff latency - candidate-pair type does not report currentRTT

			//packet loss and jitter
			if (report.type === 'inbound-rtp') {
				connStats.audio.packetsLost = report.packetsLost / report.packetsReceived
				connStats.audio.jitter = report.jitter
				let key = report.codecId
				if (report.codecId) {
					let codecReport = stats.get(key) //as RTCCodecStats
					let mimeType = codecReport.mimeType
					if (mimeType) connStats.audio.recvCodec = mimeType
				}
			}

			if (report.type === 'outbound-rtp') {
				let key = report.codecId
				if (report.codecId) {
					let codecReport = stats.get(key) //as RTCCodecStats
					let mimeType = codecReport.mimeType
					if (mimeType) connStats.audio.sendCodec = mimeType
				}
			}

			if (this.recentConnectionStats.length === 5) {
				this.recentConnectionStats.shift()
			}
			if (this.recentConnectionStats.length < 5) {
				this.recentConnectionStats.push(connStats)
			}


		})
	}

	private addInvSessionStateChangeListeners = (invSession: Inviter): void => {
		invSession.stateChange.addListener((newState) => {
			switch (newState) {
				case SessionState.Initial:
					console.log("init")
					break
				case SessionState.Establishing:
					console.log("establishing")
					console.log("invSession", invSession)
					this.session = invSession
					this.callEventsDelegate.onCallCreated(this.callId)
					this.callEventsDelegate.onCallConnecting(this.callId)
					break
				case SessionState.Established:
					console.log(this.session)
					if (!this.session) this.session = invSession
					this.callEventsDelegate.onCallAnswered(this.callId)
					break
				case SessionState.Terminating:
				// fall through
				case SessionState.Terminated:
					console.log("sessionstate terminated")
					console.log(this.session)
					this.callEventsDelegate.onCallHangup(this.callId)
					console.log(this.session)
					this.session = undefined
					console.log("statement before unregister")
					break
				default:
					console.log("error session state", newState, typeof newState)
					throw new Error("Unknown session state.")
			}
		})
	}

	private getCallStats = (arr: any): CallStats => {
		//TODO: packets lost %, jitter,

		//shared between both browsers
		let packetsLost = this.getPacketsLost(arr)
		let jitter = this.getJitter(arr)

		//if this is ff, skip calculations, and estimate based on packetLoss and jitter
		if (!window.chrome) {
			let packetLossPoints = 0
			let jitterPoints = 0

			if (packetsLost < 1) {
				packetLossPoints = 2.5
			} else if (packetsLost < 10) {
				packetLossPoints = 1.5
			} else if (packetsLost < 5) {
				packetLossPoints = 0.5
			}

			if (jitter < 10) {
				jitterPoints = 2.5
			} else if (jitter < 20) {
				jitterPoints = 1.5
			} else if (jitter < 40) {
				jitterPoints = 0.5
			}
			//ignore getting mos score with algo, not enough data without latency
			const mosScore = jitterPoints + packetLossPoints

			const callStats: CallStats = { jitter, packetsLost, mosScore }
			return callStats
		}

		let avgLatency = this.getAverageLatency(arr)
		let effectiveLatency = avgLatency + jitter * 2 + 10
		let rVal = this.getRVal(effectiveLatency, packetsLost)
		let mosScore = this.getMosScore(rVal)
		let sendCodec = this.recentConnectionStats[this.recentConnectionStats.length - 1].audio.sendCodec
		let recvCodec = this.recentConnectionStats[this.recentConnectionStats.length - 1].audio.recvCodec
		// cant use rounding, 1 or 5 would never be hit.
		const callStats: CallStats = { jitter, packetsLost, mosScore, sendCodec, recvCodec }
		return callStats
	}

	private getAverageLatency = (statsArr: any) => {
		let latencyArr = statsArr.map((i: any) => i.audio.latency)
		let total = latencyArr.reduce((sum: any, i: any) => sum + i, 0)
		return total / latencyArr.length
	}

	private getJitter = (statsArr: any) => {
		//if there is jitter on the stats obj, report that instead.
		if (statsArr.length < 1) {
			return null
		}
		let jitter = statsArr[statsArr.length - 1].audio.jitter
		if (jitter) return jitter


		//if jitter is not reported, but we still have latency, we can calculate it .
		let latencyArr = statsArr.map((i: any) => i.audio.latency)
		//need at least 2 to get the diff.
		if (latencyArr.length < 2) {
			return 0
		}

		let diffs = []
		for (let i = latencyArr.length - 1; i > 1; i--) {
			let diff = Math.abs(latencyArr[i] - latencyArr[i - 1])
			diffs.push(diff)
		}
		let total = diffs.reduce((sum, i) => sum + i, 0)
		return total / diffs.length
	}

	private getRVal = (effectiveLatency: any, packetLossPercent: any) => {
		let rval = 5
		if (effectiveLatency < 160) {
			rval = 93.2 - effectiveLatency / 40
		} else {
			rval = 93.2 - (effectiveLatency - 120) / 10
		}
		return rval - packetLossPercent * 2.5
	}

	private getMosScore = (rval: any) => {
		const score = 1 + 0.035 * rval + 0.000007 * rval * (rval - 60) * (100 - rval)
		// cant use Math.round(), 1 or 5 would never be hit.
		return Math.ceil(score)
	}

	private getPacketsLost = (statsArr: any) => {
		if (statsArr.length < 1) {
			return 0
		}
		return statsArr[statsArr.length - 1].audio.packetsLost
	}

	public supports = (): Map<string, boolean> => {
		let supportedActions: any = {}

		//if you managed to create a session, allow hangup
		supportedActions[`${CallActions.HANGUP}`] = true
		//if call is incoming you can answer
		if (this.callState === CallState.INCOMING) supportedActions[`${CallActions.ANSWER}`] = true

		//TODO: waiting for hold changes to go to master
		// if (this.isOnHold)
		supportedActions[`${CallActions.HOLD}`] = this.callState === CallState.ACTIVE

		if (!this.isMerged) supportedActions[`${CallActions.MERGE}`] = true

		//as long as this call has been answered you may switch to it.
		if (this.callState === CallState.ACTIVE) supportedActions[`${CallActions.SWITCH}`] = true

		if (true) supportedActions[`${CallActions.TRANSFER}`] = true

		if (this.callState === CallState.ACTIVE) supportedActions[`${CallActions.MUTE}`] = true

		return supportedActions
	}
}
