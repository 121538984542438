import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setSmallView, switchView } from '../actions/view'
import { switchCall, removeContactFromCalls, addContactsToCalls } from '../actions/calls'
import { withStyles } from '@material-ui/core'
import Dialer from 'dialer'

const mapStateToProps = state => ({
	smallView: state.smallView,
})
const mapDispatchToProps = dispatch => ({
	switchView: view => dispatch(switchView(view)),
	addContactsToCalls: contacts => dispatch(addContactsToCalls(contacts)),
	removeContactFromCalls: contactId => dispatch(removeContactFromCalls(contactId))
})

const styles = theme => ({
	callContent: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative'
	},
	mainContentWrapper: {
		height: '100%',
		overflowY: 'auto'
	},
	mainContent: {
		padding: '50px 0',
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		height: 'fit-content',
		position: 'relative',
		width: '100%',
		maxWidth: 475,
		margin: '0 auto',
		'&.small-view': {
			padding: '0 20px'
		}
	},
	itemIcon: {
		position: 'relative',
		width: 100,
		height: 100,
		minHeight: 100,
		backgroundColor: '#eee',
		borderRadius: '50%',
		boxSizing: 'border-box',
		marginLeft: 'auto',
		marginBottom: 25
	},
	iconImage: {
		position: 'absolute',
		height: '100%',
		left: '50%',
		top: '50%',
		borderRadius: '50px',
		transform: 'translate(-50%, -50%)'
	},
	infoWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		'& .info-item': {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			'& .title': {
				fontSize: 28,
				fontWeight: 500
			}
		}
	},
	callInfoBar: {
		display: 'flex',
		alignItems: 'center',
		padding: '8px 20px',
		boxShadow: '1px 0 0 1px #e0e0e0',
		lineHeight: 1.36
	},
	dateTimeInfo: {
		flex: 3,
		display: 'flex',
		justifyContent: 'space-between',
		borderRight: '1px solid #e0e0e0',
		paddingRight: 10,
		'& > span:last-child': {
			textTransform: 'uppercase',
			fontWeight: 'bold',
			color: theme.palette.primary.main
		}
	},
	contactInfo: {
		flex: 1,
		minWidth: 115,
		display: 'flex',
		justifyContent: 'flex-end',
		paddingLeft: 10,
	},
	audioPlayerWrapper: {
		position: 'relative',
		marginTop: 40
	},
	recIcons: {
		display: 'flex',
		justifyContent: 'center',
		'& .rec-icon': {
			width: 65,
			height: 40,
			color: '#88969E',
			'&:not(last-child):first-child': {
				marginRight: 10
			}
		}
	},
	recordingInfo: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 50,
		fontStyle: 'italic',
		color: 'gray'
	},
	recordingsSeparator: {
		borderTop: '1px dashed lightgray',
		marginTop: 40
	},
	loadingBar: {
		marginBottom: 28
	}
})

class CallsDialer extends Component {

	state = {
		iconColor: null,
		recordingReadyToPlay: false,
		voicemailReadyToPlay: false,

	}

	componentDidMount() {
		this.props.setIsDialerOpen(true)
		// this.setCallerInfo()
	}

	componentDidUpdate(oldProps, oldState) {
		if(oldProps.activeCallId && !this.props.activeCallId) {
			//if there is no longer an active call, user hung up
			this.props.switchView('make_call')
		}
	}

	componentWillUnmount() {
		this.props.setIsDialerOpen(false)
	}

	hangup = () => {
		this.props.hangupById(this.props.activeCallId, true)
		//TODO: instead of switching views, first check if theres other sessions open. If there is keep the Dialer open, and somehow bring the bottom session list into focus, notification or something. - or maybe just show callbar...
		// this.props.switchView('make_call')
	}

	setLocalMuted = (isMuted) => {
		this.props.muteLocal(isMuted)
	}

	setRemoteMuted = (isMuted) => {
		this.props.muteRemote(isMuted)
	}

	setOnHold = () => {
		if (this.props.currentCall) {
			if (!this.props.currentCall.isOnHold) {
				this.props.hold()
			} else {
				this.props.unhold()
			}
		}
	}

	transfer = () => {
		this.props.sendDTMF('*')
		this.props.sendDTMF('2')
	}

	addCall = () => {
		//adds new call to dialer, puts current call session on hold.
	}

	render() {

		const { classes } = this.props
		const { phoneNumber, displayName, status } = this.state
		return (
			<div className={classes.callContent}>
				{/* <CallHeader
					call					= {currentCall}
					smallView				= {this.props.smallView}
					toggleParticipantsHover	= {this.toggleParticipantsHover}
					extraContacts			= {this.props.extraContacts}
				/> */}

				<div className={classes.mainContentWrapper}>
					<Dialer
						screenViewType={this.props.screenViewType}
						endCallClick={this.hangup}
						status={this.props.callState}
						displayName={this.props.currentCall ? this.props.currentCall.participants[0].callerId : 'Unknown'}
						phoneNumber={this.props.currentCall ? this.props.currentCall.participants[0].phoneNumber : '-----------'}
						onMuteClick={this.setLocalMuted}
						onHoldClick={this.setOnHold}
						onTransferClick={this.transfer}
						onAudioClick={this.setRemoteMuted}
						// TODO: remove and use activeCallId to get most up to date start time?
						callStartTime={this.props.callStartTime}
						onAddClick={this.addCall}
						supportsById={this.props.supportsById}
						
						calls={this.props.calls}
						callsCnt={this.props.callsCnt}
						activeCallId={this.props.activeCallId}
						switchCall={this.props.switchCall}
						mergeCall={this.props.mergeCall}
						answerById={this.props.answerById}
						sendDTMF={this.props.sendDTMF}
						currentCall={this.props.currentCall}
						callStats={this.props.callStats}
						isMutedLocal={this.props.isMutedLocal}
						isMutedRemote={this.props.isMutedRemote}

						switchView={this.props.switchView}
					/>
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CallsDialer))
