import React, { Component } from "react"
import { connect } from "react-redux"
import { formatPhoneNumber } from "phone-numbers"
import HeaderBar from "header-bar"
import { switchCall } from "../actions/calls"
import { switchView } from "../actions/view"
import { DefaultArrowTooltip } from "tooltips"

import { withStyles } from "@material-ui/core"

const styles = (theme) => ({
	headerItem: theme.vmFaxHeaderItem,
	participant: {
		cursor: "pointer",
	},
})

const mapStateToProps = (state) => ({
	// currentFax: state.currentFax
})
const mapDispatchToProps = (dispatch) => ({
	switchView: (view) => dispatch(switchView(view)),
	switchCall: (call) => dispatch(switchCall(call)),
})

class CallHeader extends Component {
	switchToSelector = () => {
		this.props.switchView("select")
		this.props.switchCall()
	}

	render() {
		const { classes } = this.props
		let testIdProps = this.props["data-test-id"] ? { "data-test-id": this.props["data-test-id"] } : {}
		let call = this.props.call
		let theOther = call.type === "incoming" ? "from" : "to"
		let formattedNumber = formatPhoneNumber(call[theOther].number)
		let name = null
		let contactId = call[theOther].contact_id
		if (contactId) {
			let extraContact = this.props.extraContacts.find((c) => c.id === contactId)
			if (extraContact) name = extraContact.name.display
		}

		return (
			<>
				<HeaderBar
					returnFnc={this.switchToSelector}
					showReturnButton={this.props.smallView}
					smallView={this.props.smallView}
				>
					<div
						{...testIdProps}
						className={classes.participant}
						onMouseEnter={() => this.props.toggleParticipantsHover(true)}
						onMouseLeave={() => this.props.toggleParticipantsHover(false)}
						data-test-id={'calls-call-header-participant-hover-control'}
					>
						{name ? <span className={classes.headerItem}>{name}</span> : null}
						<span className={classes.headerItem}>
							{formattedNumber}
						</span>
					</div>
				</HeaderBar>
			</>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CallHeader))
