import React, { Component } from 'react'
import { formatPhoneNumber } from 'phone-numbers'

// import { newChatIcon, newChatHoverIcon, newChatLiteIcon } from 'pdc-icons'
import { checkmarkSelected, checkmarkUnselected } from 'pdc-icons'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PersonIcon from '@material-ui/icons/Person'
import CallIcon from '@material-ui/icons/Call'

import { FSPrivate } from 'privacy'
import { DefaultArrowTooltip } from 'tooltips'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	title: {
		fontSize:		'16px',
		fontWeight:		'bold',
		lineHeight:		1.31,
		letterSpacing:	'-0.1px',
		marginBottom:	'15px'
	},
	participantsModal:{
		position:		'absolute',
		top:			'40px',
		left:			0, // '50%',
		right:			0,
		// transform:		'translateX(-50%)',
		marginLeft:		'5px',
		marginRight:	'auto',
		padding:		'15px 20px',
		width:			'50%',
		maxWidth:		'250px',
		background:		'#fff',
		border:			'2px solid #000',
		borderRadius:	'6px',
		zIndex:			1,
		boxShadow:		'6px 6px 0 0 rgba(52, 64, 71, 0.5)'
	},
	participantsWrapper: {},
	participantItem: {
		fontSize:		'14px',
		fontWeight:		500,
		lineHeight:		2.14,
		letterSpacing:	'-0.1px',
		color:			'#5a666d',
		display:		'flex',
		alignItems:		'center',
		'&:not(.me)': {
			justifyContent: 'space-between'
		},
		'&.me': {
			fontStyle:	'italic',
			fontWeight:	600,
			lineHeight:	1.6,
			cursor:		'default',
			'&:not(.selected)': {
				cursor:		'pointer',
				fontWeight:	500,
				color:		'#88969e'
			}
		},
		'& img': {
			userSelect:	'none',
			'&.checkmark': {
				width:			'12px',
				height:			'9px',
				marginRight:	'5px'
			},
			'&.chat-icon': {
				width:	'23px',
				height:	'18px'
			}
		},
		'& .chat-icon-wrapper': {
			cursor: 'pointer',
			'&:not(:hover)': {
				'&:not(.hovering) .chat-icon-lite, &.hovering .chat-icon-enabled': {
					display: 'none'
				}
			},
			'&:hover': {
				'& .chat-icon-enabled, & .chat-icon-lite': {
					display: 'none'
				}
			}
		},
		'&:hover .chat-icon-wrapper': {
			'&:not(:hover)': {
				'& .chat-icon-enabled, & .chat-icon-lite': {
					display: 'none'
				}
			}
		},
		'&:not(:hover) .chat-icon-wrapper': {
			'&:not(:hover)': {
				'& .chat-icon-hover': {
					display: 'none'
				}
			}
		}
	},
	actionsWrapper: {
		display: 'flex'
	},
	actionIcon: {
		opacity:	0.7,
		cursor:		'pointer',
		margin:		'0 5px',
		'&:hover:not(.not-allowed)': {
			opacity: 1
		},
		'&.not-allowed': {
			opacity:	0.3,
			cursor:		'not-allowed'
		}
	}
})

class ParticipantsModal extends Component {

	state = {
		popupHovered: false
	}

	makeCall = participant => {
		if (this.props.extension.is_virtual || this.props.isCallButtonDisabled) return
		this.props.makeCall(participant.number)
	}

	renderParticipant = (key, displayName, mine, participant) => {
		const { classes }	= this.props
		let phoneNumber			= participant.number
		let selected			= mine ? this.props.selectedNumber === phoneNumber : false
		let isSavedContact		= Boolean(participant.contactId)
		let EditContactIcon		= isSavedContact ? PersonIcon : PersonAddIcon
		let formatedNumber		= formatPhoneNumber(phoneNumber)
		let prefillData			= this._getContactPrefillData(formatedNumber, displayName, participant)
		let isVirtualExtension	= this.props.extension.is_virtual
		return (
			<div
				key			= {key}
				className	= {`${classes.participantItem} ${mine ? 'me' : ''} ${selected ? 'selected' : ''}`}
				onClick		= {() => mine && this.props.changeNumber(phoneNumber)}
			>
				{mine ? (
					selected ? <img src={checkmarkSelected} className='checkmark' alt='Checkmark' />
					: <img src={checkmarkUnselected} className='checkmark' alt='Checkmark' />
				) : null}
				<DefaultArrowTooltip
					title			= {formatedNumber}
					placement		= 'left'
					interactive		= {true}
					leaveDelay		= {1}
					enterTouchDelay	= {0}
				>
					<span>{displayName}</span>
				</DefaultArrowTooltip>
				{!mine && !process.env.REACT_APP_EDIT_CONTACT_FORM_HIDDEN? (
					// <div
						// commenting out until we fix start conversation bug
						// className		= {`chat-icon-wrapper ${this.state.hoverOverChatIcon ? 'hovering' : ''}`}
						// onClick			= {() => this.props.startChat(phoneNumber)}
						// onMouseEnter	= {() => this.setState({hoverOverChatIcon: true})}
						// onMouseLeave	= {() => this.setState({hoverOverChatIcon: false})}
					// ></div>
					<div className={classes.actionsWrapper}>
						{!process.env.REACT_APP_IS_CALLING_DISABLED && !window.safari || (window.V5PHONECOM && window.V5PHONECOM.features.has('calling_enabled')) ?
							<DefaultArrowTooltip
								title		= {isVirtualExtension ? 'Virtual extension can\'t make calls' : 'Call number'}
								placement	= 'left'
							>
								<div
									className={`${classes.actionIcon} ${(isVirtualExtension || this.props.isCallButtonDisabled) ? 'not-allowed' : ''}`}
									data-test-id='participant-call-button'
								>
									<CallIcon onClick={() => this.makeCall(participant)}/>
								</div>
							</DefaultArrowTooltip>
						: null}
						<DefaultArrowTooltip
							title		= {`${isSavedContact ? 'Edit' : 'Add '} contact`}
							placement	= 'left'
						>
							<div className={classes.actionIcon}>
								<EditContactIcon onClick={() => this.props.editContact(participant.contactId, participant.number, prefillData)}/>
							</div>
						</DefaultArrowTooltip>
					</div>
				) : null}
			</div>
		)
	}

	_getContactPrefillData = (formatedNumber, name, participant) => {
		let prefillData = null
		if (name !== formatedNumber) prefillData = {name}
		if (participant.address) {
			if (!prefillData) prefillData = {}
			prefillData.address = participant.address
		}
		return prefillData
	}

	togglePopupHover = popupHovered => this.setState({popupHovered})

	render() {
		let testIdProps	= this.props['data-test-id'] ? {'data-test-id': this.props['data-test-id']} : {}
		const { classes } = this.props

		return (
			(this.props.participantsHovered || this.state.popupHovered) &&
			<div
				{...testIdProps}
				className		= {classes.participantsModal}
				onMouseEnter	= {() => this.togglePopupHover(true)}
				onMouseLeave	= {() => this.togglePopupHover(false)}
			>
				<div className={classes.title}><span>Participants</span></div>
				<div className={classes.participantsWrapper}>
					<FSPrivate>
						{this.props.otherNumbers.map((p, i) => {
							let displayName = p.name || formatPhoneNumber(p.number)
							return this.renderParticipant(`1${i}`, displayName, false, p)
						})}
						<Divider style={{margin: '15px 0'}}/>
						{this.props.myNumbers.map((p, i) => {
							let displayName = p.numberNickname || formatPhoneNumber(p.number)
							return this.renderParticipant(`2${i}`, displayName, true, p)
						})}
					</FSPrivate>
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(ParticipantsModal)