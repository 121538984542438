import React, { Component } from "react"
import CallSessionItem from "./CallSessionItem"
import { withStyles } from "@material-ui/core"
import CallSessionSelectorActiveCall from "./CallSessionSelectorActiveCall"
import { currentCall } from "calls/src/reducers/calls"
const styles = (theme) => ({
	listRowMobile: {
		height: 65,
		// width: '96%',
		// marginBottom: 10,
		// marginTop: 10
	},
	listRowDesktop: {
		height: 80,
		borderBottomRightRadius: 7,
		borderBottomLeftRadius: 7
	}
})

//TODO: rename to call log selector, or rename CallsSelector to CallSessionSelector. or both.
class CallSessionSelector extends Component {
	state = {
		// activeCallId: PDCCall.call.activeCallId || null
	}
	componentDidMount(oldProps, oldState) {
		// if(oldProps.activeCallId !== this.props.activeCallId) {
		//     this.setState({ activeCallId: this.props.activeCallId })
		// }
	}

	getCallSessionItem(s, index) {
		const { switchCall, mergeCall, activeCallId, answerById, currentCall, hangupById, isExpanded } = this.props
		const { callId } = s
		return (
			<CallSessionItem
				key={callId}
				session={s}
				disabled={callId === activeCallId}
				onSwitchCallClick={() => switchCall(callId)}
				onMergeCallClick={() => mergeCall(callId)}
				onAnswerCallClick={answerById}
				hangupById={hangupById}
				isMerged={s.isMerged}
				currentCall={currentCall}
				isMobile={this.props.isMobile}
				listIndex={index}
				isExpanded={isExpanded}
			/>
		)
	}
	render() {
		const { classes, sessions } = this.props
		return (
			<div data-test-id={"call-session-list"}>
				{currentCall ? (
					<CallSessionSelectorActiveCall
						currentCall={this.props.currentCall}
						isExpanded={this.props.isExpanded}
						hangupById={this.props.hangupById}
						hold={this.props.hold}
						unhold={this.props.unhold}
						isMobile={this.props.isMobile}
						toggleShowCallSessionSelector={this.props.toggleShowCallSessionSelector}
						supportsById={this.props.supportsById}
					/>
				) : null}
				{sessions.map((s, index) => this.getCallSessionItem(s, index))}
			</div>
		)
	}
}

export default withStyles(styles)(CallSessionSelector)
