import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import CallSessionSelector from 'call-session-selector'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { DefaultArrowTooltip } from 'tooltips'
const styles = theme => ({
    notificationContainer: {
        width: '100%',
        height: 'fit-content',
        minHeight: 60,
        padding: 10,
        backgroundColor: 'gray',
        zIndex: 101,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        color: 'white',
        '&.secondary': {
            padding: 10
        },
        marginTop: 'auto'
    },
    notificationBarContainerExpanded: {
        width: '100%',
        minHeight: 'fit-content',
        maxHeight: '100%',
        padding: 10,
        backgroundColor: 'gray',
        zIndex: 101,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0
    },
    notificationBarContent: {
        width: '100%',
        height: 'fit-content',
        minHeight: 60,
        padding: 10,
        backgroundImage: 'linear-gradient(-315deg, #4f5e69, #212527)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        color: 'white',
        '&.secondary': {
            padding: 10
        },
        '& .link': {
            textDecoration: 'underline',
            color: 'white',
            cursor: 'pointer'
        }
    },
})

class MultiCallBottomBar extends Component {
    state = {
        isExpanded: false
    }
    renderSelector = (sessions, activeCallId) => {
        if (this.state.isExpanded) {
            return <CallSessionSelector sessions={sessions} activeCallId={activeCallId} switchCall={this.props.switchCall} mergeCall={this.props.mergeCall} answerById={this.props.answerById} calls={this.props.calls}/>
        }
    }
    toggleIsExpanded = () => {
		this.setState({ isExpanded: !this.state.isExpanded })
	}
    render() {
        const { classes, activeCallId, sessions } = this.props
        let keys = Object.keys(this.props.calls)
        const sortedSessions = keys.map(k => this.props.calls[k]).sort((a, b) => a.callStartTime - b.callStartTime)
        return (
            <div className={!this.state.isExpanded ? classes.notificationContainer : classes.notificationBarContainerExpanded}>
                {this.renderSelector(sortedSessions, activeCallId)}
                <div className={`${classes.notificationBarContent}`} id='notification-bar'>
                    {`${(sortedSessions.length - 1)} pending session(s)`}
                    <ArrowDropUpIcon className={classes.goToCallIcon} onClick={this.toggleIsExpanded} data-test-id={'dialer-multi-expand'}/>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(MultiCallBottomBar)