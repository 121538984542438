import PhoneComUser from 'phone-com-user'
import axios from 'axios'

export default function reportError (msg, url, lineNumber, columnNumber, error) {
  if (typeof (error) === 'string') { return false }

  if (window.ga) {
    window.ga('send', 'exception', {
      exDescription: error
    })
  }

  if (window.FS) {
    window.FS.event('exception', {
      message: msg,
      url: url,
      lineNumber: lineNumber,
      columnNumber: columnNumber,
      error: error
    })
  }

  if (window.Rollbar) {
    console.log(window.Rollbar.critical(msg, error, { code_version: process.env.REACT_BUILD_VERSION || '' }))
  }

  const Browser = (function () {
    const ua = navigator.userAgent; let tem
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*([\d.]+)/i) || []
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+(\.\d+)?)/g.exec(ua) || []
      return 'IE ' + (tem[1] || '')
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
    if ((tem = ua.match(/version\/([.\d]+)/i)) != null) M[2] = tem[1]
    return M.join(' ')
  })()

  const href = window.location.href
  const messageparam = {
    team_code_name: 'app',
    error_message: error ? error.message : '',
    error_obj_message: msg,
    url: href,
    line_number: lineNumber,
    column_number: columnNumber || 0,
    browser: Browser,
    platform: window.navigator.platform,
    stack_trace: error ? error.stack : '',
    fullstory_url: (typeof window.FS !== 'undefined' && typeof window.FS.getCurrentSessionURL === 'function') ? window.FS.getCurrentSessionURL() : ''
  }

  const path = '/abracadabra/report-javascript-error'
  const fullUrl = PhoneComUser.getv5ToolsRoot() + path
  const payload = JSON.stringify(messageparam)

  if (error) {
    if (!href.includes('localhost') && error) {
      axios.post(fullUrl, payload)
    } else {
      console.log('Reporting error: ' + error)
    }
  };
  return true
};
