import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './contact-styles'
import Api from 'api'
import LoaderFull from 'loader-full'
import HeaderBar from 'header-bar'
import ContactEditor from 'contact-editor'
import ConfirmModal from 'confirm-modal'
import Button from 'button'
import { NoContactsPersonIcon } from 'pdc-svg-icons'
import { switchContact } from '../../actions/users'
import { switchView } from '../../actions/people'
import { EditIcon, TrashIcon } from 'pdc-svg-icons'
import { DefaultArrowTooltip } from 'tooltips'
import { PdcCallConsumer } from '../../../../../pages/communicator-app/src/PdcCallProvider'
import { withStyles } from '@material-ui/core'
import gtmDataPush from 'gtm-events'

const GTM_APP_NAME = 'people;contact-content'
const GTM_MAP = {DISCARD_CHANGES: 1, REJECT_DISCARD: 0, DELETE_CONTACT: 1, CANCEL_DELETE: 0}

const mapStateToProps = state => ({
	smallView:			state.smallView,
	currentContactId:	state.currentContactId
})
const mapDispatchToProps = dispatch => ({
	switchContact:	contactId	=> dispatch(switchContact(contactId)),
	switchView:		view		=> dispatch(switchView(view))
})

class ContactContent extends Component {

	state = {
		discardChangesType:			null,
		reinitializeContactEditor:	false,
		deleteContactId:			null,
		isEditing:					false,
		editMode:					false,
		updating:					false
	}

	componentDidUpdate(prevProps) {
		if (prevProps.currentContactId !== this.props.currentContactId) this.setState({editMode: false})
	}

	onDeleteContactClick = contact => this.setState({deleteContactId: contact.id})

	onGoToSelectClick = () => {
		if (this.state.isEditing) return this.setState({discardChangesType: 'goto-select'})
		this.props.switchView('select')
		this.props.switchContact(null)
	}

	renderHeader = () => {
		const { classes, contactsUtil, currentContactId } = this.props
		if (!currentContactId) return <HeaderBar/>
		let contactItems		= contactsUtil.contacts.items
		let extraContactItems	= contactsUtil.extraContacts
		let isCreatingNew		= currentContactId === 'create-new-contact'
		let selectedContact		= (currentContactId && !isCreatingNew) ? (
			contactItems.find(c => c.id === currentContactId) ||
			extraContactItems.find(c => c.id === currentContactId)
		) : null
		let contactName			= selectedContact ? selectedContact.name.display : ''
		return (
			<HeaderBar
				returnFnc	= {this.onGoToSelectClick}
				smallView	= {this.props.smallView}
			>
				<div className='name-section'>{contactName}</div>
				{selectedContact ?
					<div className={classes.actionsSection}>
						<DefaultArrowTooltip
							title		= 'Edit contact'
							placement	= 'bottom'
						>
							<div
								className	= {classes.actionIconWrapper}
								onClick		= {() => {
									gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'edit-contact-click'})
									this.setState({editMode: true})
								}}
							><EditIcon className='edit-icon'/></div>
						</DefaultArrowTooltip>
						<DefaultArrowTooltip
							title		= 'Delete contact'
							placement	= 'bottom'
						>
							<div
								className	= {classes.actionIconWrapper}
								onClick		= {() => {
									gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'delete-contact-click'})
									this.onDeleteContactClick(selectedContact)
								}}
							><TrashIcon className='delete-icon'/></div>
						</DefaultArrowTooltip>
					</div>
				: null}
			</HeaderBar>
		)
	}

	cancelEdit = hasChange => {
		if (hasChange) return this.setState({discardChangesType: 'cancel'})
		this.setState({editMode: false})
		if (this.props.currentContactId === 'create-new-contact') {
			this.props.switchContact(null)
			this.props.switchView('select')
		}
	}

	saveContact = contact => {
		let { contactsUtil } = this.props
		contactsUtil.updateContact(contact)
		if (contact.group && contact.group.name)
			contactsUtil.addGroup({...contact.group})
		this.setState({editContact: null})
	}

	renderNoContactsView = () => {
		const { classes } = this.props
		return (
			<div className={classes.noContactsView}>
				<NoContactsPersonIcon className='nc-person-icon'/>
				<div className='nc-right-side'>
					<span className='nc-text'>You haven’t added any contacts yet</span>
					<Button
						variant	= 'outlined'
						icon	= 'add'
						onClick	= {this.props.onAddContactClick}
					>Add a contact</Button>
				</div>
			</div>
		)
	}

	renderNoContactSelectedView = () => <div className={this.props.classes.noSelectionView}>No contact selected.</div>

	onSave = contactId => {
		this.props.switchContact(contactId)
		this.setState({editMode: false})
	}

	setEditing = isEditing => {
		this.setState({isEditing})
		this.props.setEditing(isEditing)
	}

	renderContactDetails = () => {
		const { classes, contactsUtil, smallView } = this.props
		let currentContactId	= this.props.currentContactId
		if (!currentContactId) {
			if (contactsUtil.contactsLoaded && contactsUtil.contacts.items.length === 0) return this.renderNoContactsView()
			return this.renderNoContactSelectedView()
		}
		let isCreatingNew		= currentContactId === 'create-new-contact'
		let contactItems		= contactsUtil.contacts.items
		let extraContactItems	= contactsUtil.extraContacts
		let selectedContact		= (currentContactId && !isCreatingNew) ? (
			contactItems.find(c => c.id === currentContactId) ||
			extraContactItems.find(c => c.id === currentContactId)
		) : null
		if (!selectedContact && !isCreatingNew) return this.renderNoContactSelectedView()
		let isEditing			= this.state.editMode || isCreatingNew
		let editModeClass		= isEditing ? 'edit-mode' : ''
		let smallViewClass		= smallView ? 'small-view' : ''
		return (
			<div className={`${classes.contactDetailsSection} ${editModeClass} ${smallViewClass}`}>
				<div className='contact-editor-wrapper'>
					<PdcCallConsumer>
						{(context) => (
							<ContactEditor
								contactGroupTypes		= {contactsUtil.groupTypes}
								contact					= {selectedContact}
								reinitialize			= {this.state.reinitializeContactEditor}
								editMode				= {isEditing}
								saveContact				= {this.saveContact}
								onCancel				= {this.cancelEdit}
								onSave					= {this.onSave}
								setEditing				= {this.setEditing}
								addGroup				= {contactsUtil.addGroup}
								updateGroupId			= {contactsUtil.updateGroupId}
								makeCall				= {this.props.makeCall}
								startConversation		= {this.props.startConversation}
								isVirtualExtension		= {this.props.isVirtualExtension}
								smallView				= {smallView}
								canPlaceOutgoingCall	= {context.canPlaceOutgoingCall}
								origin					= 'people;contact-content'
							/>
						)}
					</PdcCallConsumer>
				</div>
			</div>
		)
	}

	discardChanges = () => {
		let discardChangesType = this.state.discardChangesType
		this.setState({
			discardChangesType:			null,
			editMode:					false,
			reinitializeContactEditor:	true
		}, () => this.setState({reinitializeContactEditor: false}))
		if (this.props.currentContactId === 'create-new-contact' || discardChangesType === 'goto-select') {
			this.props.switchContact(null)
			this.props.switchView('select')
		}
		this.props.setEditing(false)
		if (discardChangesType === 'goto-select') this.props.switchView('select')
		gtmDataPush({
			PDC_Action:	GTM_APP_NAME,
			PDC_Label:	`discard-changes;${discardChangesType}`,
			PDC_Value:	GTM_MAP.DISCARD_CHANGES
		})
	}

	renderDiscardChangesModal = () => {
		let onReject = () => {
			gtmDataPush({
				PDC_Action:	GTM_APP_NAME,
				PDC_Label:	`discard-changes;${this.state.discardChangesType}`,
				PDC_Value:	GTM_MAP.REJECT_DISCARD
			})
			this.setState({discardChangesType: null})
		}
		return (
			<ConfirmModal
				title			= 'You have unsaved changes'
				isShown			= {Boolean(this.state.discardChangesType)}
				content			= 'Do you wish to continue? All changes will be lost. '
				noButtonText	= 'Cancel'
				yesButtonText	= 'Continue'
				onReject		= {onReject}
				onConfirm		= {this.discardChanges}
				size			= 'size550'
			/>
		)
	}

	onDelete = async () => {
		gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'delete-contact', PDC_Value: GTM_MAP.DELETE_CONTACT})
		let contactId = this.state.deleteContactId
		this.setState({deleteContactId: null, updating: true})
		await Api.deleteContact(contactId)
		if (this.props.currentContactId === contactId) this.props.switchContact(null)
		this.setState({updating: false})
		this.cancelEdit()
		this.props.contactsUtil.deleteContact(contactId)
	}

	renderConfirmDeleteModal = () => {
		return (
			<ConfirmModal
				title			= 'Delete contact? This action cannot be undone.'
				isShown			= {Boolean(this.state.deleteContactId)}
				content			= {null}
				noButtonText	= 'Cancel'
				yesButtonText	= 'Delete Contact'
				yesButtonColor	= 'attention'
				onReject		= {() => {
					gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'delete-contact', PDC_Value: GTM_MAP.CANCEL_DELETE})
					this.setState({deleteContactId: null})
				}}
				onConfirm		= {this.onDelete}
				size			= 'size550'
			/>
		)
	}

	renderLoader = () => {
		const { classes } = this.props
		if (!this.state.updating) return null
		return <div className={classes.loadingDiv}><LoaderFull/></div>
	}

	render() {
		const { classes } = this.props
		return (
			<div className={classes.wrapper}>
				{this.renderLoader()}
				{this.renderHeader()}
				{this.renderContactDetails()}
				{this.renderDiscardChangesModal()}
				{this.renderConfirmDeleteModal()}
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ContactContent))