let wordBreak ='break-word'
if (document.documentMode || /Edge/.test(navigator.userAgent)) {
	wordBreak = 'break-all' // hack fix for edge and internet explorer, we may want move the if statement to a shared function, called like browserDetector or something
}

const styles = theme => ({
	identityWrapper: {
		display:		'flex',
		flexDirection:	'column',
		width:			'100%',
		'& .identity-section': {
			display:			'flex',
			alignItems:			'center',
			justifyContent:		'space-between',
					minHeight:		60,

			padding:			'2px 25px 2px 15px',
			backgroundColor:	theme.palette.tertiary[0],
			cursor:				'pointer',
			'& .identity-text': {
				display:		'flex',
				flexDirection:	'column',
				color:			'white',
				'& .main-identity-text': {
					fontSize:	12,
					fontWeight:	600,
					fontFamily:	'Montserrat-semi-bold',
					lineHeight:	'17px',
					wordBreak:	wordBreak
				},
				'& .secondary-identity-text': {
					fontSize:		10,
					fontWeight:		500,
					fontFamily:		'Montserrat-medium',
					lineHeight:		'14px',
					letterSpacing:	0.1,
					marginTop:		5
				}
			},
			'& .identity-avatar': {
				width:	45,
				height:	45
			}
		},
		'& .extension-selector-section': {
			position:			'relative',
			cursor:				'pointer',
			'&.expanded .extension-selector-header': {
				background:	theme.palette.tertiary['+200']
			},
			'&:not(.expanded) .extension-selector-wrapper': {
				display: 'none'
			},
			'& .extension-selector-header': {
				display:		'flex',
				alignItems:		'center',
				justifyContent:	'space-between',
				padding:		'20px 19px 16px 15px',
				fontSize:		16,
				fontWeight:		400,
				lineHeight:		'14px',
				background:		theme.palette.tertiary['+100'],
				color:			'white',
				'& > span': {
					textTransform:	'uppercase',
					fontFamily:		'NTR'
				},
				'& > svg': {
					width:	13,
					height:	7
				}
			},
			'& .extension-selector-wrapper': {
				position:	'absolute',
				width:		'100%',
				zIndex:		1,
				padding:	'0 12px 12px',
				background:	theme.palette.tertiary['+200'],
				'& .extension-selector-items-wrapper': {
					display:		'flex',
					flexDirection:	'column',
					maxHeight:		320,
					overflowY:		'auto',
					padding:		'11px 0',
					'&::-webkit-scrollbar': {
						'-webkit-appearance':	'none !important',
						width:					6,
						height:					6
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor:	'rgba(255, 255, 255, 0.35)',
						borderRadius:		3
					},
					'& .extension-selector-item': {
						fontSize:		12,
						fontWeight:		500,
						lineHeight:		'38px',
						letterSpacing:	0.05,
						padding:		'0 3px',
						color:			theme.palette.tertiary[-300],
						// textOverflow:	'ellipsis',
						// whiteSpace:		'nowrap',
						// overflow:		'hidden', // TODO: This causes the items to have minimal height and hot be shown. Should be fixed.
						'&.selected': {
							fontWeight:	700,
							color:		'white'
						}
					}
				}
			}
		}
	},
	identityPopup: {
		position:		'absolute',
		display:		'flex',
    	flexDirection:	'column',
		width:			280,
		background:		'white',
		color:			'black',
		overflowY: 		'auto',
		top:			20,
		zIndex:			10001,
		transform:		'translateX(100%)',
		boxShadow:		'0px 10px 15px rgba(0, 0, 0, 0.2), 0px 20px 35px rgba(0, 0, 0, 0.15), 0px 10px 45px rgba(0, 0, 0, 0.1)',
		borderRadius:	30,
		'& .identity-popup-user-info': {
			display:		'flex',
			flexDirection:	'column',
			alignItems:		'center',
			padding:		'40px 0 15px',
			'& > div': {
				marginBottom: 10
			},
			'& .main-text': {
				fontSize:	12,
				fontWeight:	600,
				lineHeight:	'19px'
			},
			'& .secondary-text': {
				fontSize:		12,
				fontWeight:		500,
				lineHeight:		'19px',
				letterSpacing:	0.05,
				color:			theme.palette.secondary['+100']
			}
		},
		'& .identity-popup-options': {
			borderTop: `1px solid ${theme.palette.secondary[-300]}`,
			'& .identity-popup-option': {
				borderBottom:	`1px solid ${theme.palette.secondary[-300]}`,
				padding:		'10px 0',
				fontSize:		12,
				fontWeight:		600,
				lineHeight:		'19px',
				color:			theme.palette.primary[0],
				textAlign:		'center',
				cursor:			'pointer',
				'&:hover': {
					background:	theme.palette.primary[-500]
				}
			}
		},
		'& .identity-popup-actions': {
			flex:			1,
			display:		'flex',
			alignItems:		'center',
			justifyContent:	'center',
			marginTop:		5,
			marginBottom:	5,
			padding:		'25px 0'
		},
		'& .x-icon-wrapper': {
			position:		'absolute',
			top:			22,
			right:			22,
			padding:		12.45,
			lineHeight:		1,
			borderRadius:	'50%',
			cursor:			'pointer',
			'&:hover': {
				background: theme.palette.secondary[-400]
			},
			'& .x-icon': {
				width:	18.41,
				height:	18.41,
				color:	theme.palette.secondary[0]
			}
		}
	},
	identityPopupMobilePosition:{
		right:			'75%',
	},
	identityPopupDesktopPosition:{
		right:			-20,
	}
})

export default styles