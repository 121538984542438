import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { getPhoneCom } from 'phonecom'

const api_base = process.env.REACT_APP_USER_API_URL

class API {
	static getMusicOnHoldLink = voip_recording_id => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/greetings/get-music-on-hold-link`
			return ajax.post(requestUrl, {voip_recording_id}).then(response => response.data)
		})
	}

	static createFileGreeting = (name, file) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/create-greeting`
			return ajax.post(requestUrl, {name, file}).then(response => response.data)
		})
	}

	static createTTSGreeting = (tts_text, tts_voice, name) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/create-greeting`
			return ajax.post(requestUrl, {origin: 'tts', tts_text, tts_voice, name}).then(response => response.data)
		})
	}

	static getVoicemailConfig = () => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/voicemail/get-ext-vm-config`
			return ajax.post(requestUrl, {}).then(response => response.data)
		})
	}

	static configureVoicemail = voip_recording_id => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/voicemail/configure-voicemail`
			return ajax.post(requestUrl, {voip_recording_id}).then(response => response.data)
		})
	}

	static getNotificationSettings = async () => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'tools')}/notifications/get-notification-settings`
		let response = (await ajax.post(requestUrl, {})).data
		return response
	}

	static setNotificationSettings = async data => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'tools')}/notifications/set-notification-settings`
		let response = (await ajax.post(requestUrl, data)).data
		return response
	}

	static getExtension = async () => {
		let voip_id		= PhoneComUser.getAPIAccountId()
		let extensionId	= parseInt(PhoneComUser.getExtensionId())
		let base_url	= 'https://api.phone.com/v4'
		let requestUrl	= `${base_url}/accounts/${voip_id}/extensions/${extensionId}`
		let response	= await ajax.get(requestUrl, 'Bearer')
		return response.data
	}

	static addExtensionForward = async (phoneNumber, screening) => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/configure-device-calling`
		let data = {
			device_type:	'web',
			call_mode:		'voip',
			caller_id_mode:	'calling_number',
			phone_number:	{'phone_number': phoneNumber, screening}
		}
		let response = await ajax.post(requestUrl, data)
		return response
	}

	static removeNumberForwarding = async phoneNumber => {
		let requestUrl	= `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/remove-number-forwarding`
		let data		= { phone_number: phoneNumber }
		let response	= await ajax.post(requestUrl, data)
		return response
	}

	static getUser = async userId => {
		let account_id	= PhoneComUser.getAPIAccountId()
		let requestUrl	= `${api_base}/voip/${account_id}/users?page_size=1&offset=0&order_by=status.asc&include=extension,plan,devices,numbers&user_ids=${userId}`
		let response	= await ajax.get(requestUrl)
		if (response.message === 'Network Error') return null
		return response.data.data[0]
	}

	static updateUser = async user => {
		let account_id	= PhoneComUser.getAPIAccountId()
		let requestUrl	= `${api_base}/voip/${account_id}/users`
		let response	= await ajax.put(requestUrl, [user])
		if (response.toString().substr(0, 5) === 'Error') return { error: true }
		return response.data
	}

	static uploadAvatar = async (user, base64image) => {
		let account_id = PhoneComUser.getAPIAccountId()
		let requestUrl	= `${api_base}/voip/${account_id}/users/${user.id}/avatar`
		let response	= await ajax.put(requestUrl, {image: base64image})
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			console.log('Got non-2xx response from server:', response.response.data)
			return response.response.data
		}
		try {
			return response.data ? response.data : { error: true }
		} catch (err) {
			console.error('Error calling upload-avatar endpoint', err)
			return { error: true }
		}
	}

	static updateContact = async (user_id, type, value) => {
		let account_id	= PhoneComUser.getAPIAccountId()
		let payload = {}
		let contact = {
			contact_type: type,
			contact: value,
		}
		payload['contact'] = contact
		
		let requestUrl	= `${api_base}/voip/${account_id}/user/${user_id}/update_contact`
		let response	= await ajax.post(requestUrl, {contact})
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			console.log('Got non-2xx response from server:', response.response.data)
			return response.response.data
		}
		try {
			return response.data ? response.data : {error: true}
		} catch (err) {
			console.error('Error calling update contact endpoint', err)
			return {error: true}
		}
	}
	static confirmContact = async (user_id, type, value, code) => {
		let account_id	= PhoneComUser.getAPIAccountId()
		let payload = {}
		let contact = {
			contact_type: type,
			contact: value,
			verification_code: code
		}
		payload['contact'] = contact
		
		let requestUrl	= `${api_base}/voip/${account_id}/user/${user_id}/confirm_contact`
		let response	= await ajax.post(requestUrl, {contact})
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			console.log('Got non-2xx response from server:', response.response.data)
			return response.response.data
		}
		try {
			return response.data ? response.data : {error: true}
		} catch (err) {
			console.error('Error calling confirm contact endpoint', err)
			return {error: true}
		}
	}
}

export default API