import React, { Component } from "react"
import { connect } from "react-redux"
import api from "../util/api_v5.js"
import PhoneComUser from "phone-com-user"
import CallContent from "./CallContent"
import CallsSelector from "./CallsSelector"
import StartNewButton from "start-new-button"
import MakeCall from "./MakeCall"
import ResizeAware from "react-resize-aware"
import { setSmallView, switchView } from "../actions/view"
import { switchExtension } from "../actions/pdcuser"
import { switchCall, updateCalls, updateCall,addRecordings, addContactsToCalls, removeContactFromCalls } from "../actions/calls"
import LoaderFull from "loader-full"
import { withStyles } from "@material-ui/core"
import CallsDialer from "./CallsDialer"
import PdcOpenConnection from "pdc-open-connection"
import { PdcCallConsumer } from "../../../../pages/communicator-app/src/PdcCallProvider"
import gtmDataPush from 'gtm-events'

const GTM_APP_NAME = 'calls'
const GTM_MAP = {ALREADY_ACTIVE: 0, NOT_ACTIVE: 1}

let threshold = 768

const mapStateToProps = (state) => ({
	calls: state.calls,
	smallView: state.smallView,
	currentView: state.currentView,
	currentExtensionRedux: state.currentExtension,
})
const mapDispatchToProps = (dispatch) => ({
	setSmallView: (boolVal) => dispatch(setSmallView(boolVal)),
	switchView: (view) => dispatch(switchView(view)),
	switchCall: (call) => dispatch(switchCall(call)),
	updateCalls: (call) => dispatch(updateCalls(call)),
	updateCall: (call) => dispatch(updateCall(call)),
	addRecordings: (recordings) => dispatch(addRecordings(recordings)),
	switchExtension: (extension) => dispatch(switchExtension(extension)),
	addContactsToCalls: (contacts) => dispatch(addContactsToCalls(contacts)),
	removeContactFromCalls: (contactId) => dispatch(removeContactFromCalls(contactId)),

})

const styles = (theme) => ({
	appWrapper: {
		display: "flex",
		height: "100%",
		position: "relative",
	},
	callsPanel: {
		display: "flex",
		flexDirection: "column",
		minWidth: theme.selector.width,
		boxShadow: "0 0 0 1px #e0e0e0",
		position: "relative",
		"&.small-view": {
			width: "100%",
		},
		"&:not(.small-view)": {
			maxWidth: theme.selector.width,
		},
	},
	callsSelectorTitle: {
		fontSize: 20,
		fontWeight: 600,
		lineHeight: 1.25,
		letterSpacing: -0.2,
		color: "black",
		padding: "17px 20px",
		boxShadow: theme.palette.primary.flatBottomShadow,
	},
})

class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			loadedOnce: false,
		}
		if (props.extension && props.extension.extension_id !== PhoneComUser.getExtensionId()) {
			PhoneComUser.changeExtension(props.extension.extension_id)
		}
	}

	componentDidMount = async () => {
		this._ismounted = true
		await this.initialLoad()
		console.log(this.props)

		PdcOpenConnection.on("call_sent", this.createCallWithOutgoingCall)
		PdcOpenConnection.on("call_received", this.createCallLogWithIncomingCall)

		// if calls loaded into dialer but theres no active call - edge case
		if (!this.props.currentCall && this.props.currentView === "dialer") this.props.openCallLogList()
	}

	componentWillUnmount() {
		this._ismounted = false
		PdcOpenConnection.removeCallback("call_sent", this.createCallWithOutgoingCall)
		PdcOpenConnection.removeCallback("call_received", this.createCallLogWithIncomingCall)
	}

	componentDidUpdate = async (prevProps) => {
		setTimeout(() => {
			if (
				this.props.calls.items &&
				prevProps.calls.items &&
				this.props.calls.items.length !== prevProps.calls.items.length
			) {
				let element = document.getElementsByClassName("infinite-scroller")[0]
				if (element) {
					// element.scrollTop = 0
				}
			}
		}, 1)
		// if(this.props.calls.items && prevProps.calls.items && this.props.calls.items.length !== prevProps.calls.items.length) {
		// 	let element = document.getElementsByClassName('infinite-scroller')[0]
		// 	element.scrollTop = 0;
		// }

		if (this.props.extension && this.props.extension.extension_id !== PhoneComUser.getExtensionId()) {
			PhoneComUser.changeExtension(this.props.extension.extension_id)
			// this.props.resetSubscription(true)
			this.initialLoad(true)
		}

		if (prevProps.isOffline && !this.props.isOffline) this.adjustView()

		let appData = this.props.appData
		if (appData && (!this.appDataRandomString || this.appDataRandomString !== appData.randomString)) {
			this.appDataRandomString = appData.randomString
			if (appData.view) {
				this.props.switchView(appData.view)
				this.props.switchCall()
			}
		}

		this.updateContactsInfoInCalls()
	}

	updateContactsInfoInCalls = () => {
		if (!this.props.contactsUtil.extraContactsLoaded) return
		let numNewContacts = 0
		let callItems = this.props.calls.items
		if (!callItems) return
		let contactIdsToBeRemoved = []
		callItems.forEach((call) => {
			let theOther = call.type === "incoming" ? "from" : "to"
			let extraContact = this.props.contactsUtil.extraContacts.find((contact) =>
				contact.numbers.find((n) => n.number === call[theOther].number)
			)
			if (extraContact && !call[theOther].contact_id) {
				numNewContacts++
			} else if (!extraContact && call[theOther].contact_id) {
				contactIdsToBeRemoved.push(call[theOther].contact_id)
			}
		})
		if (numNewContacts > 0) {
			this.props.addContactsToCalls(this.props.contactsUtil.extraContacts)
		}
		if (contactIdsToBeRemoved.length) {
			contactIdsToBeRemoved.forEach((contactId) => this.props.removeContactFromCalls(contactId))
		}
	}

	updateCallLogWithEvent = async (e) => {
		//TODO: once call.log passes extension info, we can fetch call log when event comes in, instead of onClick. 08/03

		//upon getting call log event, fetch the call log. bringing in call log into event would involve doing that for every single call
		const voipId = PhoneComUser.getAPIAccountId()
		const call = await api.getCall(this.props.currentCall.id, voipId)
		if (call) {
			let calls = this.props.calls.items

			//filter out the old version of this call, add new one
			calls = calls.filter((c) => c.id !== call.id)
			calls.unshift(call)
			calls.sort((a, b) => b.start_time - a.start_time)

			let callsOBj = {
				filters: this.props.calls.filters,
				items: [...calls],
				limit: this.props.calls.limit,
				offset: this.props.calls.offset,
				sort: this.props.calls.sort,
				total: this.props.calls.total,
			}
			this.props.updateCall(call)
			//TODO: maybe force fetch the voicemail and recording
		}
	}

	createCallLogWithIncomingCall = (e) => {
		console.log(this.time)
		if (this.props.calls.items.find((c) => c.id === e.call_id)) return
		if (e.call.status) {
			console.log("incoming call should have no status, ignoring event", e)
			return
		}

		console.log("call received")
		let callLog = {
			id: e.call.uuid,
			extension: e.voip_api_token.voip_phone_id,
			type: "incoming",
			start_time: Date.now() / 1000,
			from: { number: e.call.from },
			to: { number: e.call.called_number },
			duration: 0,
			recording: {},
			voicemail: {},
			isTempCall: true,
		}

		let callsOBj = {
			filters: this.props.calls.filters,
			items: [callLog, ...this.props.calls.items],
			limit: this.props.limit + 1,
			offset: this.props.offset,
			sort: this.props.sort,
			total: this.props.total + 1,
		}
		console.log("call answered event detected with id", e.call.uuid)
		this.props.updateCall(callLog)
	}

	createCallWithOutgoingCall = (event) => {
		const { payload } = event
		if(!payload) {
			console.error('no event payload')
			return
		}

		if (payload.direction !== "out") {
			console.log("there should not be incoming call events coming from call.new")
			return
		}

		if (this.props.calls.items.find((c) => c.id === payload.call_id)) return

		//get the current callerId from call in question
		const { currentCall } = this.props
		if (!currentCall) return

		const callerId = currentCall.myCallInfo.phoneNumber

		console.log("call.new outgoing call event", payload)
		let callLog = {
			id: payload.call_id,
			extension: payload.from_extn_id,
			type: "outgoing",
			start_time: Date.now() / 1000,
			from: { number: callerId || "" },
			to: { number: payload.to_did },
			duration: 0,
			recording: {},
			voicemail: {},
			isTempCall: true,
		}

		let callsOBj = {
			filters: this.props.calls.filters,
			items: [callLog, ...this.props.calls.items],
			limit: this.props.limit + 1,
			offset: this.props.offset,
			sort: this.props.sort,
			total: this.props.total + 1,
		}
		console.log("call_sent event detected with id", payload.call_id)
		this.props.updateCall(callLog)
	}

	adjustView = () => {
		this.initialLoad(true)
	}

	initialLoad = async (force) => {
		let extensionSwitched = Boolean(
			!this.props.currentExtensionRedux ||
				this.props.extension.extension_id !== this.props.currentExtensionRedux.extension_id
		)
		if (!extensionSwitched && this.props.calls.items && !force) return this.setState({ loadedOnce: true })
		if (extensionSwitched) this.props.switchExtension(this.props.extension)
		this.setState({ loading: true })
		this.props.contactsUtil.reload()
		await this.getCalls()
		await this.loadExtraContacts()
		this.setState({ loading: false, loadedOnce: true })
	}

	loadExtraContacts = async () => {
		let calls = this.props.calls
		let callsItems = calls.items
		if (!callsItems) return
		let phoneNumbers = []
		// Collect all of the phone numbers which are not connected to a contact
		callsItems.forEach((c) => {
			let direction = c.type === "outgoing" ? "to" : "from"
			if (c[direction].contact_id) return
			phoneNumbers.push(c[direction].number)
		})
		if (phoneNumbers.length === 0) return
		// Remove duplicates
		phoneNumbers = Array.from(new Set(phoneNumbers))
		let filters = { keyword: phoneNumbers }
		let extraContacts = await this.props.contactsUtil.loadExtraContacts(filters)
		this.props.addContactsToCalls(extraContacts)
	}

	getCalls = async () => {
		if (!this._ismounted) return // console.log('Calls App.js got unmounted')
		// limit so based on height so it always gets filled
		let extensionId = PhoneComUser.getExtensionId()
		let limit = Math.max(parseInt(window.innerHeight / 50), 15)
		let responseCalls = await api.loadCalls({}, limit)
		if (responseCalls === "network-error") return
		responseCalls.items.forEach((c) => {
			c.recording.loading = true
			c.voicemail.loading = true
		})
		this.getRecordings(responseCalls.items)
		if (extensionId !== PhoneComUser.getExtensionId()) {
			// This may happen if you change the extension while this extension calls are being loaded
			return // console.log('The extension got changed so stop.')
		}
		this.props.updateCalls(responseCalls)
		// responseCalls.items.forEach((c) => {
		// 	this.props.updateCall(c);
		// });
		let switchedByUrl = this.openUrlCall()
		this.props.onLoaded()
		if (switchedByUrl) return
		if (!this.props.calls.items || !this.props.calls.items.length) {
			this.props.switchCall()
		} else if (!this.props.smallView) {
			this.props.switchCall(this.props.calls.items[0])
		}
	}

	// If the url path links to a call then open it
	openUrlCall = () => {
		let pathname = window.location.pathname
		let pathnameSplit = pathname.split("/").filter((e) => e)
		let callId = undefined
		if (pathnameSplit.length > 2 && pathnameSplit[1] === "calls") {
			callId = pathnameSplit[2].substring(1)
		}
		let callItems = this.props.calls.items
		if (!callItems || !callId) return

		let pathCall = callItems.find((c) => c.id === callId)
		if (pathCall) {
			this.props.switchCall(pathCall)
			this.props.switchView("content")
			return true
		}
		return false
	}

	getRecordings = async (callItems) => {
		let recordingIds = callItems.map((c) => (c.recording ? c.recording.id : null)).filter((c) => c)
		let voicemailIds = callItems.map((c) => (c.voicemail ? c.voicemail.id : null)).filter((c) => c)
		let recordings = await api.getCallRecordings(recordingIds, voicemailIds)
		if(recordings) await this.props.addRecordings(recordings)
	}

	retryRecordings = async (callItem) => {
		console.log('retry recordings', callItem)
		await this.getRecordings([callItem])
	}

	handleResize = (size) => {
		let needToExpand =
			this.props.smallView &&
			((this.props.standalone && size.width >= threshold) || !this.props.screenViewType.isMobileView)
		let needToShrink =
			!this.props.smallView &&
			((this.props.standalone && size.width < threshold) || this.props.screenViewType.isMobileView)
		if (needToShrink) {
			this.props.setSmallView(true)
		} else if (needToExpand) {
			this.props.setSmallView(false)
		}
	}

	onMakeCallClick = () => {
		gtmDataPush({
			PDC_Action:	GTM_APP_NAME,
			PDC_Label:	'make-a-call-button-click',
			PDC_Value:	this.props.currentView !== 'make_call' ? GTM_MAP.NOT_ACTIVE : GTM_MAP.ALREADY_ACTIVE
		})
		this.props.switchCall()
		this.props.openMakeACall()
	}

	renderCallsSelector = () => {
		const { classes } = this.props
		let hiddenClass = ''
		if (this.props.smallView && this.props.currentView !== 'select') hiddenClass = 'hidden'
		return (
			<div className={`${classes.callsPanel} ${hiddenClass} ${this.props.smallView ? 'small-view' : ''}`}>
				{!process.env.REACT_APP_IS_CALLING_DISABLED && !window.safari ||
				(window.V5PHONECOM && window.V5PHONECOM.features.has('calling_enabled')) ? (
					<PdcCallConsumer>
						{(context) => (
							<StartNewButton
							app='calls'
							title='Make a call'
							onClick={this.onMakeCallClick}
							disabled={!context.canPlaceOutgoingCall}
						/>
						)}
					</PdcCallConsumer>
				) : !this.props.smallView ? (
					<div className={classes.callsSelectorTitle}>Calls</div>
				) : null}
				<PdcCallConsumer>
					{(context) => (
						<CallsSelector
							newCall={this.state.newCall}
							setNoNewCall={this.setNoNewCall}
							smallView={this.props.smallView}
							screenViewType={this.props.screenViewType}
							deleteCall={this.deleteCall}
							changeReadStatus={this.changeReadStatus}
							getRecordings={this.getRecordings}
							loadExtraContacts={this.loadExtraContacts}
							extraContacts={this.props.contactsUtil.extraContacts}
							placeCall={this.props.makeCall}
							canPlaceOutgoingCall={context.canPlaceOutgoingCall}
              				openDialer={this.props.openDialer}
							extension={this.props.extension}
							callSetupInProgress={context.callSetupInProgress}
							myCallerInfo={this.props.myCallerInfo}
							setMyCallerInfo={this.props.setMyCallerInfo}
						/>
					)}
				</PdcCallConsumer>
			</div>
		)
	}

	render() {
		const { classes } = this.props
		return (
			<div className="App">
				<ResizeAware
					style={{ height: this.props.standalone ? "calc(100% - 60px)" : "100%" }}
					onResize={this.handleResize}
				>
					{this.state.loading ? (
						<div className={classes.loadingDiv}>
							<LoaderFull
								styles={{ loaderFull: { left: this.props.smallView ? "50%" : "calc(50% + 120px)" } }}
								size="big"
							/>
						</div>
					) : null}
					{this.state.loadedOnce && (
						<div className={classes.appWrapper}>
							{this.renderCallsSelector()}
							{this.props.currentView === "make_call" ? (
								<PdcCallConsumer>
									{(context) => (
										<MakeCall
											extension={this.props.extension}
											contactsUtil={this.props.contactsUtil}
											setIsDialerOpen={this.props.setIsDialerOpen}
											connect={context.connect}
											makeCall={this.props.makeCall}
											openCallLogList={this.props.openCallLogList}
											myCallerInfo={this.props.myCallerInfo}
											setMyCallerInfo={this.props.setMyCallerInfo}
											disabled={!context.canPlaceOutgoingCall}
										/>
									)}
								</PdcCallConsumer>
							) : null}
							{this.props.currentView == "dialer" ? (
								<PdcCallConsumer>
									{(context) => (
										<CallsDialer
											screenViewType={this.props.screenViewType}
											setIsDialerOpen={this.props.setIsDialerOpen}
											status="ringing"
											callStartTime={
												context.currentCall ? context.currentCall.callStartTime : null
											}
											callState={context.currentCall ? context.currentCall.callState : null}
											calls={context.calls}
											switchCall={context.switchCall}
											mergeCall={context.mergeCall}
											callsCnt={context.callsCnt}
											activeCallId={context.activeCallId}
											hangupById={context.hangupById}
											answerById={context.answerById}
											hold={context.hold}
											unhold={context.unhold}
											muteLocal={context.muteLocal}
											muteRemote={context.muteRemote}
											sendDTMF={context.sendDTMF}
											currentCall={context.currentCall}
											callStats={context.callStats}
											isMutedLocal={context.isMutedLocal}
											isMutedRemote={context.isMutedRemote}
											supportsById={context.supportsById}
										/>
									)}
								</PdcCallConsumer>
							) : null}
							{(!this.props.smallView && ["content", "select"].includes(this.props.currentView)) ||
							this.props.currentView === "content" ? (
								<PdcCallConsumer>
									{(context) => (
										<CallContent
											extension={this.props.extension}
											deleteCall={this.deleteCall}
											changeReadStatus={this.changeReadStatus}
											redirect={this.props.redirect}
											extraContacts={this.props.contactsUtil.extraContacts}
											contactGroupTypes={this.props.contactsUtil.groupTypes}
											updateContact={this.props.contactsUtil.updateContact}
											deleteContact={this.props.contactsUtil.deleteContact}
											makeCall={this.props.makeCall}
											callContext={this.props.callContext}
											currentCallSession={context.currentCall}
											retryRecordings={this.retryRecordings}
										/>
									)}
								</PdcCallConsumer>
							) : null}
						</div>
					)}
				</ResizeAware>
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))
