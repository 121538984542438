import React, { Component } from "react"
import CallBarActiveCallBar from "./CallBarActiveCallBar"
import SignalInfo from "signal-info"
import MicIcon from "@material-ui/icons/Mic"
import MicOffIcon from "@material-ui/icons/MicOff"
import DialpadIcon from "@material-ui/icons/Dialpad"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap"
import { DefaultArrowTooltip } from "tooltips"
import { withStyles } from "@material-ui/core"
import gtmDataPush from 'gtm-events'

const GTM_APP_NAME = 'call-bar-mobile'
const GTM_MAP = {SAME_APP: 0, SWITCH_APP: 1, UNMUTE: 0, MUTE: 1, NO_CALL: 2}

const styles = (theme) => ({
	notificationContainer: {
		width: "100%",
		// height: "fit-content",
		minHeight: 36,
		// padding: 10,
		// backgroundImage: "linear-gradient(-315deg, #4f5e69, #212527)",
		zIndex: 3,
		display: "flex",
		// alignItems: "center",
		justifyContent: "space-between",
		textAlign: "center",
		color: "white",
		backgroundColor: theme.palette.secondary["-300"],
		position: "relative",
		"&.secondary": {
			padding: 10,
		},
		"& .link": {
			textDecoration: "underline",
			color: "white",
			cursor: "pointer",
		},
	},
	notificationBarContent: {
		width: "100%",
		height: "100%",
		minHeight: 36,
		// padding: 10,
		// backgroundImage: "linear-gradient(-315deg, #4f5e69, #212527)",
		zIndex: 3,
		display: "flex",
		// alignItems: "center",
		justifyContent: "space-between",
		textAlign: "center",
		color: "white",
		position: "relative",
		"&.secondary": {
			padding: 10,
		},
		"& .link": {
			textDecoration: "underline",
			color: "white",
			cursor: "pointer",
		},
	},
	callBarContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
	},
	goToCallIcon: {
		width: 24,
		height: 24,
		cursor: "pointer",
		// marginLeft: 10,
		color: theme.palette.secondary["+200"],
	},
	// mobile version of above
	callControlCallBarContainerWithOnClick: {
		width: "28px",
		height: "28px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"&:active": {
			opacity: 1,
			backgroundColor: theme.palette.secondary["-200"],
		},
		cursor: "pointer",
		marginLeft: 15,
		color: "black",
	},
	callControlCallBarContainer: {
		width: "28px",
		height: "28px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer",
		marginLeft: 15,
		color: "black",
	},
	dialPadIcon: { height: 30, width: 25, cursor: "pointer", color: "black" },
	callMenuContainerMobile: {
		maxWidth: 600,
		position: "absolute",
		zIndex: 7,
		bottom: 27,
		left: 0,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
		borderTopLeftRadius: 7,
		borderTopRightRadius: 7,
		overflow: "hidden",
		marginBottom: 10,
	},
	callMenuContent: {
		backgroundColor: "white",
		width: "100%",
		minHeight: "51px",
		boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 12px, rgba(0, 0, 0, 0.25) 0px 4px 20px",
		borderBottomRightRadius: "7px",
		borderBottomLeftRadius: "7px",
		overflow: "hidden",
	},
	inactiveMicMobile: { width: 24, height: 24, color: theme.palette.secondary["-100"] },
	activeMic: { width: 24, height: 24, color: theme.palette.attention["0"] },
	collapseBtn: {
		alignSelf: "flex-end",
		marginRight: "28px",
		marginBottom: "23px",
		width: "41px",
		height: "41px",
		borderRadius: "50%",
		boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
		borderStyle: "none",
	},
})

class CallBarContentMobile extends Component {
	renderCallBarSessionIndicators = () => {
		// const length = 4
		const { callsOnHold } = this.props
		// TODO: should sipCallManager just do the locking? the ui shouldnt have to confirm if its less than 4
		if (callsOnHold)
			return callsOnHold.map((c) => <AccountBoxIcon style={{ width: 22, height: 22, color: "red" }} />)
	}

	openDialer = () => {
		gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'open-dialer-click'})
		this.props.openDialer()
	}

	openMakeACall = () => {
		let pathnameSplit = window.location.pathname.split('/')
		let isCallsAppOpened = pathnameSplit.length > 2 && pathnameSplit[2] === 'calls'
		gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'call-button-click', PDC_Value: isCallsAppOpened ? GTM_MAP.SAME_APP : GTM_MAP.SWITCH_APP})
		this.props.openMakeACall()
	}

	onMuteToggle = () => {
		let GTM_VALUE = this.props.currentCall ? this.props.isMutedLocal ? GTM_MAP.UNMUTE : GTM_MAP.MUTE : GTM_MAP.NO_CALL
		gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'mute-click', PDC_Value: GTM_VALUE})
		this.props.onMuteToggle()
	}

	render() {
		const { classes, currentCall, isExpanded, backgroundCalls } = this.props
		return (
			<div className={classes.notificationContainer} data-test-id={"callbar"}>
				<div className={classes.callBarContainer}>
					<CallBarActiveCallBar
						currentCall={currentCall}
						backgroundCalls={backgroundCalls}
						visible={!isExpanded && (currentCall || backgroundCalls.length)}
						toggleShowCallSessionSelector={this.props.toggleShowCallSessionSelector}
					/>

					{/* overlay div */}
					{isExpanded ? (
						<div className={`${classes.callMenuContainerMobile}`}>
							{/* main content */}
							<button onClick={this.props.toggleShowCallSessionSelector} className={classes.collapseBtn}>
								<ExpandMoreIcon style={{ width: 23, height: 23 }} />
							</button>
							{/* <CallBarActiveCallBar
								currentCall={this.props.currentCall}
								backgroundCalls={backgroundCalls}
								visible={isExpanded && this.props.currentCall}
								toggleShowCallSessionSelector={this.props.toggleShowCallSessionSelector}
							/> */}
							<div className={classes.callMenuContent}>{this.props.renderCallSelector()}</div>
							{/* <CallBarActiveCallBar
								currentCall={this.props.currentCall}
								backgroundCalls={backgroundCalls}
								visible={isExpanded && this.props.currentCall}
								toggleShowCallSessionSelector={this.props.toggleShowCallSessionSelector}
							/> */}
						</div>
					) : null}

					{/* callbar content */}
					<div className={`${classes.notificationBarContent}`} id="notification-bar">
						{/* grp 1 */}
						<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							<div className={classes.callControlCallBarContainer}>
								<SignalInfo
									width={24}
									height={24}
									color={"white"}
									jitter={this.props.callStats ? this.props.callStats.jitter : null}
									packetsLost={this.props.callStats ? this.props.callStats.packetsLost : null}
									avgLatency={this.props.callStats ? this.props.callStats.avgLatency : null}
									mosScore={this.props.callStats ? this.props.callStats.mosScore : null}
									sendCodec={this.props.callStats ? this.props.callStats.sendCodec : null}
									recvCodec={this.props.callStats ? this.props.callStats.recvCodec : null}
									isActive={this.props.currentCall && this.props.currentCall.callState === "ACTIVE"}
									isMobile={true}
									onClick={() => gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'signal-info-click'})}
								/>
							</div>
							{/* using active call bar */}
							{/* <div
								className={classes.callControlCallBarContainer}
								data-test-id={"callbar-session-list-expand-mobile"}
								onClick={this.toggleShowCallSessionSelector}
							>
								<ListIcon style={{ width: 26, height: 26}} />
							</div> */}
							<div
								onClick={this.onMuteToggle}
								className={
									this.props.currentCall
										? classes.callControlCallBarContainerWithOnClick
										: classes.callControlCallBarContainer
								}
							>
								{this.props.currentCall ? (
									!this.props.isMutedLocal ? (
										<MicIcon className={classes.activeMic} />
									) : (
										<MicOffIcon className={classes.activeMic} />
									)
								) : (
									<MicIcon className={classes.inactiveMicMobile} />
								)}
							</div>
						</div>

						{/* grp 2 */}
						
						<div
							style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "-33%" }}
						>
							{(this.props.currentCall || this.props.backgroundCalls.length > 0 ? 
									<DefaultArrowTooltip title="Go to the call" placement="top">
										<ZoomOutMapIcon
											className={classes.dialPadIcon}
											onClick={this.openDialer}
											data-test-id={"go-to-dialer-btn"}
										/>
									</DefaultArrowTooltip>
								: <DialpadIcon
								id="dialpad-button"
								className={classes.dialPadIcon}
								onClick={this.openMakeACall}
								data-test-id={"call-bar-dialer-button"}
								/>)
							}	
						</div>

						{/* grp 3 */}
						<div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 10 }}>
							<div>{this.renderCallBarSessionIndicators()}</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default withStyles(styles)(CallBarContentMobile)
