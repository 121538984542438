/**
 * gtmDataPush pushes to event to GA and FullStory for analytics reports
 *
 * @param {json} data The event.
 *    @param {String} data.PDC_Action the events action ex: 'message-sent'
 *    @param {String} data.PDC_Label the events label ex: PDC_Label:	'type: ' + eventType
 * @public
 */
export default function gtmDataPush(data) {
	if (!window.dataLayer) return

	if (window.V5PHONECOM) {
		let voipId		= parseInt(window.V5PHONECOM.voip_id)
		let voipPhoneId	= parseInt(window.V5PHONECOM.voip_phone_id)
		let userId		= parseInt(window.V5PHONECOM.user_id)
		if (isNaN(userId)) userId = null
		data.event				= data.event || 'PDC_my_phone_event'
		data.PDC_voip_id		= voipId		// Set the voip id
		data.PDC_voip_phone_id	= voipPhoneId	// Set the extension id (voip_phone id)
		data.PDC_user_id		= userId		// Set the user id
		let PDC_Label			= data.PDC_Label ? `${data.PDC_Label}, ` : ''
		data.PDC_Label			= `${PDC_Label}features: (${Array.from(window.V5PHONECOM.features).join(';')})`
		data.PDC_Value			= (data.PDC_Value || data.PDC_Value === 0) ? data.PDC_Value : null
	}
	// return console.log('#### GTM DATA PUSH:', data)
	window.dataLayer.push(data)

	if (!window.FS) return
	let event = data.event || 'event'
	window.FS(event, data)
	// console.log('GTM event data:', data)
}