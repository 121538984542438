import React, { Component } from 'react'
import PropTypes from 'prop-types'
class Timer extends Component{
	constructor(props) {
		super(props)
		this.state = {
			callDuration: '',
			timerId: null
		}
	}

	componentDidMount() {
		this.startCallTimer()
	}

	componentWillUnmount(){
		this.endTimer()
	}

	startCallTimer = () => {
		if(!this.state.timerId){
			let timerId = setInterval(this.timerInterval, 1000)
			this.setState({ timerId })
		}
	}

	endTimer = () => {
		if(this.state.timerId){
			console.log('clearing timer')
			clearInterval(this.state.timerId)
			this.setState({timerId: null})
		}
	}

	formatMinutes = seconds => {
		let twoDigits = n => n > 9 ? n : `0${n}`
		let hours = seconds >= 3600 ? parseInt(seconds / 60 / 60) : 0
		seconds = seconds >= 3600 ? seconds - 3600 * hours : seconds
		let minutes = seconds >= 60 ? parseInt(seconds / 60) : 0
		seconds = seconds >= 60 ? seconds - 60 * minutes : seconds
		return hours ? `${twoDigits(hours)}:${twoDigits(minutes)}:${twoDigits(seconds)}` : `${twoDigits(minutes)}:${twoDigits(seconds)}`
	}

	timerInterval = () => {
		const { startTime, endTime } = this.props
		if(!startTime){
			this.setState({ callDuration:''})
		}else {
            if (endTime) {
                this.setState({callDuration: this.formatMinutes(Math.round(endTime - startTime))})
                this.endTimer()
            } else {
                this.setState({callDuration: this.formatMinutes(Math.round(parseInt(Date.now() / 1000) - startTime))})
            }
        }
	}

	render(){
		return <span style={{ "font-variant-numeric": "tabular-nums" }}>{this.state.callDuration}</span>
	}
}

Timer.propTypes = {
	/**
	 * the data time the timer should start from 
	 */
	startTime: PropTypes.instanceOf(Date),
	/**
	 * the data time the timer should read as to end. This will stop the timer, showing the current duration.
	 */
	endTime: PropTypes.instanceOf(Date),
}

Timer.defaultProps = {
	startTime:	Date.now()/1000,
	endTime:		null
}

export default Timer