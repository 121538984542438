import React, { Component } from 'react'
import api from '../../util/api_v5'
import LoaderFull from 'loader-full'
import Button from 'button'
import SelectionControl from 'selection-control'
import { DefaultArrowTooltip } from 'tooltips'
import gtmDataPush from 'gtm-events'
import styles from './styles'
import { withStyles } from '@material-ui/core'

const localStorageMap = {
	calls:		'dninccls',
	voicemails:	'dnmsclsvms',
	messages:	'dnmsgs',
	faxes:		'dnfxs'
}

const GTM_APP_NAME = 'personal-settings;notifications'
const GTM_MAP = {TOGGLE_ON: 1, TOGGLE_OFF: 0, ALREADY_SAVING: 0, SAVE: 1}

class Notifications extends Component {

	constructor(props) {
		super(props)
		this.state = {loaded: false, saving: false}
		this.loadNotificationSettings()
		this.setLocalStorage()
	}

	setLocalStorage = () => {
		localStorage.dnmsgs		= localStorage.dnmsgs		|| 'false'
		localStorage.dninccls	= localStorage.dninccls		|| 'false'
		localStorage.dnmsclsvms	= localStorage.dnmsclsvms	|| 'false'
	}

	loadNotificationSettings = async () => {
		let notificationsSettings = await api.getNotificationSettings()
		this.setState({loaded: true, notificationsSettings, notificationsSettingsTemp: JSON.parse(JSON.stringify(notificationsSettings))})
	}

	renderNotificationItem = (type, checked, mainText, secondaryText, onClick, disabledMessage) => {
		const { classes } = this.props
		return (
			<div className={classes.notificationItem}>
				<DefaultArrowTooltip
					title		= {disabledMessage}
					placement	= 'top'
				>
					<div>
						<SelectionControl
							variant		= {type}
							checked		= {checked}
							onClick		= {onClick}
							name		= {`checked-${mainText}`}
							value		= {`checked-${mainText}`}
							disabled	= {Boolean(disabledMessage)}
							// className	= {classes.actionButton}
						/>
					</div>
				</DefaultArrowTooltip>
				<div className={`${classes.notificationText} ${type === 'check' ? 'small' : ''}`}>
					<span className='main'>{mainText}</span>
					{secondaryText ? <span className='secondary'>{secondaryText}</span> : null}
				</div>
			</div>
		)
	}

	toggleDesktopNotifications = async type => {
		if (type === 'main') {
			if (Notification.permission === 'default') {
				let response = await Notification.requestPermission()
				if (response === 'granted') {
					Object.values(localStorageMap).forEach(localStorageKey => localStorage[localStorageKey] = 'true')
				}
				this.forceUpdate()
			}
			return
		}
		let currentState = localStorage[localStorageMap[type]]
		let newState = 'true' !== currentState
		gtmDataPush({
			PDC_Action:	GTM_APP_NAME,
			PDC_Label:	`toggle-desktop-notification;${type}`,
			PDC_Value:	newState ? GTM_MAP.TOGGLE_ON : GTM_MAP.TOGGLE_OFF
		})
		localStorage[localStorageMap[type]] = `${newState}`
		this.forceUpdate()
	}

	getDesktopNotificationsStatuses = () => {
		let main		= Notification.permission === 'granted'
		let calls		= main && localStorage[localStorageMap.calls] === 'true'
		let voicemails	= main && localStorage[localStorageMap.voicemails] === 'true'
		let messages	= main && localStorage[localStorageMap.messages] === 'true'
		let faxes		= main && localStorage[localStorageMap.faxes] === 'true'
		return {main, calls, voicemails, messages, faxes}
	}

	renderDesktopNotificationsSection = () => {
		const { classes } = this.props
		let statuses = this.getDesktopNotificationsStatuses()
		let mainDisabled = ''
		if (Notification.permission === 'granted') mainDisabled = 'To disable the notifications do it from the browser settings.'
		if (Notification.permission === 'denied') mainDisabled = 'To enable the notifications do it from the browser settings.'
		let othersDisabled = statuses.main === false ? 'Firstly enable the desktop notifications' : ''
		return (
			<div className={classes.section}>
				<div className={classes.sectionTitle}>DESKTOP NOTIFICATIONS</div>
				{this.renderNotificationItem('switch', statuses.main, 'Desktop notifications', 'Browser permission is needed for desktop notifications.', () => this.toggleDesktopNotifications('main'), mainDisabled)}
				{this.renderNotificationItem('switch', statuses.calls, 'Incoming calls', null, () => this.toggleDesktopNotifications('calls'), othersDisabled)}
				{this.renderNotificationItem('switch', statuses.voicemails, 'New voicemails', null, () => this.toggleDesktopNotifications('voicemails'), othersDisabled)}
				{this.renderNotificationItem('switch', statuses.messages, 'New text messages', null, () => this.toggleDesktopNotifications('messages'), othersDisabled)}
				{this.renderNotificationItem('switch', statuses.faxes, 'Incoming faxes', null, () => this.toggleDesktopNotifications('faxes'), othersDisabled)}
			</div>
		)
	}

	toggleEmailForMessagesFaxesAndVoicemails = () => {
		let notificationsSettingsTemp				= this.state.notificationsSettingsTemp
		let newState								= !notificationsSettingsTemp.messages.email
		notificationsSettingsTemp.messages.email	= newState
		notificationsSettingsTemp.faxes.email		= newState
		notificationsSettingsTemp.voicemails.email	= newState
		this.setState({notificationsSettingsTemp})
		gtmDataPush({
			PDC_Action:	GTM_APP_NAME,
			PDC_Label:	'toggle-email-notification;messages-faxes-voicemails',
			PDC_Value:	newState ? GTM_MAP.TOGGLE_ON : GTM_MAP.TOGGLE_OFF
		})
	}

	toggleVoicemailAttachmentType = () => {
		let notificationsSettingsTemp = this.state.notificationsSettingsTemp
		notificationsSettingsTemp.voicemails.attachment_type = notificationsSettingsTemp.voicemails.attachment_type === 'wav' ? 'none' : 'wav'
		this.setState({notificationsSettingsTemp})
		gtmDataPush({
			PDC_Action:	GTM_APP_NAME,
			PDC_Label:	'toggle-email-notification;voicemail-attachment',
			PDC_Value:	notificationsSettingsTemp.voicemails.attachment_type === 'wav' ? GTM_MAP.TOGGLE_ON : GTM_MAP.TOGGLE_OFF
		})
	}

	toggleAttachFax = () => {
		let notificationsSettingsTemp				= this.state.notificationsSettingsTemp
		notificationsSettingsTemp.faxes.attach_fax	= !notificationsSettingsTemp.faxes.attach_fax
		this.setState({notificationsSettingsTemp})
		gtmDataPush({
			PDC_Action:	GTM_APP_NAME,
			PDC_Label:	'toggle-email-notification;attach-fax',
			PDC_Value:	notificationsSettingsTemp.faxes.attach_fax ? GTM_MAP.TOGGLE_ON : GTM_MAP.TOGGLE_OFF
		})
	}

	toggleEmailForCalls = () => {
		let notificationsSettingsTemp				= this.state.notificationsSettingsTemp
		notificationsSettingsTemp.calls.send_email	= !notificationsSettingsTemp.calls.send_email
		this.setState({notificationsSettingsTemp})
		gtmDataPush({
			PDC_Action:	GTM_APP_NAME,
			PDC_Label:	'toggle-email-notification;calls',
			PDC_Value:	notificationsSettingsTemp.calls.send_email ? GTM_MAP.TOGGLE_ON : GTM_MAP.TOGGLE_OFF
		})
	}

	getValue = (type, app) => this.state.notificationsSettingsTemp[app][type]

	renderEmailNotificationsSection = () => {
		const { classes } = this.props
		return (
			<div className={classes.section}>
				<div className={classes.sectionTitle}>EMAIL NOTIFICATIONS</div>
				{this.renderNotificationItem('switch', this.getValue('send_email', 'calls'), 'Incoming calls', null, this.toggleEmailForCalls)}
				{this.renderNotificationItem('switch', this.getValue('email', 'messages'), 'Messages, Faxes and Voicemails', null, this.toggleEmailForMessagesFaxesAndVoicemails)}
				{this.renderNotificationItem('checkbox', this.getValue('attachment_type', 'voicemails') === 'wav', 'Include voicemail audio and transcription', null, this.toggleVoicemailAttachmentType)}
				{this.renderNotificationItem('checkbox', this.getValue('attach_fax', 'faxes'), 'Attach fax to email (PDF)', null, this.toggleAttachFax)}
			</div>
		)
	}

	areObjectsSame = (o1, o2) => {
		let hasChange = false
		Object.keys(o1).forEach(key => {
			if (hasChange) return
			if (o1[key] !== o2[key]) hasChange = true
		})
		return !hasChange
	}

	checkHasChange = () => {
		// NOTE: Currently messages, faxes and voicemails are same so we can check only for one of them
		let { notificationsSettings, notificationsSettingsTemp } = this.state
		let callsChange			= !this.areObjectsSame(notificationsSettings.calls, notificationsSettingsTemp.calls)
		let faxesChange			= !this.areObjectsSame(notificationsSettings.faxes, notificationsSettingsTemp.faxes)
		let voicemailsChange	= !this.areObjectsSame(notificationsSettings.voicemails, notificationsSettingsTemp.voicemails)
		return callsChange || faxesChange || voicemailsChange
	}

	onSaveClick = async () => {
		let { notificationsSettings, notificationsSettingsTemp } = this.state
		let callsString						= `${notificationsSettings.calls.send_email}->${notificationsSettingsTemp.calls.send_email}`
		let messagesFaxesVoicemailsString	= `${notificationsSettings.messages.email}->${notificationsSettingsTemp.messages.email}`
		let attachFaxString					= `${notificationsSettings.faxes.attach_fax}->${notificationsSettingsTemp.faxes.attach_fax}`
		let voicemailAttachmentTypeString	= `${notificationsSettings.voicemails.attachment_type}->${notificationsSettingsTemp.voicemails.attachment_type}`
		if (this.state.saving) {
			gtmDataPush({
				PDC_Action:	GTM_APP_NAME,
				PDC_Label:	`save;calls:${callsString};messages-faxes-voicemail:${messagesFaxesVoicemailsString};attachFax:${attachFaxString};voicemailsAttachment:${voicemailAttachmentTypeString}`,
				PDC_Value:	GTM_MAP.ALREADY_SAVING
			})
			return
		}
		if(notificationsSettingsTemp.calls){
			if(!notificationsSettingsTemp.calls.send_to){
				notificationsSettingsTemp.calls.send_to = 'custom'
			}
			notificationsSettingsTemp.calls.status = notificationsSettingsTemp.calls.send_email || notificationsSettingsTemp.calls.send_sms
		}
		let data = {
			messages_voicemails_faxes:	notificationsSettingsTemp.messages,
			voicemail_attachment_type:	notificationsSettingsTemp.voicemails.attachment_type,
			email_attach_fax:			notificationsSettingsTemp.faxes.attach_fax,
			calls:						notificationsSettingsTemp.calls,
			email:						window.V5PHONECOM.email
		}
		this.setState({saving: true})
		await api.setNotificationSettings(data)
		let updatedNotificationSettings = JSON.parse(JSON.stringify(notificationsSettingsTemp))
		this.setState({notificationsSettings: updatedNotificationSettings, saving: false})
		gtmDataPush({
			PDC_Action:	GTM_APP_NAME,
			PDC_Label:	`save;calls:${callsString};messages-faxes-voicemail:${messagesFaxesVoicemailsString};attachFax:${attachFaxString};voicemailsAttachment:${voicemailAttachmentTypeString}`,
			PDC_Value:	GTM_MAP.SAVE
		})
	}

	renderSaveButton = () => {
		const { classes } = this.props
		let hasChange = this.checkHasChange()
		if (this.hasChange !== hasChange) this.props.setBusy(hasChange)
		this.hasChange = hasChange
		return (
			<Button
				disabled	= {!hasChange || this.state.saving}
				onClick		= {this.onSaveClick}
				className	= {classes.saveButton}
			>Save</Button>
		)
	}

	render() {
		const { classes } = this.props
		return (
			<div className={`${classes.settingWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
				{!this.state.loaded ? <div className={classes.loadingDiv}><LoaderFull size='big'/></div> :
					<>
						{this.state.saving ? <div className={classes.loadingDiv}><LoaderFull size='big'/></div> : null}
						{this.renderDesktopNotificationsSection()}
						{this.renderEmailNotificationsSection()}
						{this.renderSaveButton()}
					</>
				}
			</div>
		)
	}
}

export default withStyles(styles)(Notifications)