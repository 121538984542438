import { SimpleUser } from "sip.js/lib/platform/web"
import { CallManager, CallFeatures } from "./CallManager"
import { CallState } from "../enums/CallState"
import { CallerInfo } from "../interfaces/CallerInfo"
import { SipCallSession } from "./SipCallSession"
import { CallType } from "../enums/CallType"
import { UserAgent, RegistererState, Registerer, RegistererOptions, RegistererRegisterOptions, Session, UserAgentOptions, Web } from "sip.js"
import CallSession from "./CallSession"

//@ts-ignore
import { formatPhoneNumber } from "phone-numbers"
//@ts-ignore
import PDCOpenConnection from "pdc-open-connection"
//@ts-ignore
import PhoneComUser from "phone-com-user"
//@ts-ignore
import api from "./util/api_v2"

//@ts-ignore
import Api from "api"

import { CallManagerEvents } from "../enums/CallManagerEvents"
import { register } from "../serviceWorker"
import { CallEventsDelegate } from "../interfaces/CallEventsDelegate"
import { SessionState } from "sip.js"

declare global {
	interface Window {
		V5PHONECOM: any;
		safari: boolean;
		Rollbar: any;
		AudioContext: any;
		micStream?: MediaStream | null;
	}
}

class SipCallManager extends CallManager {
	callMode: CallType = CallType.SIP
	calls: { [key: string]: SipCallSession } = {}
	activeCallId: string | null = null
	topicCallbacks: any[] = []
	// private userAgent: any = {};
	myCallerInfo: CallerInfo = {
		phoneNumber: '',
		callerId: ''
	}
	simpleUser?: SimpleUser
	callEventsDelegate: CallEventsDelegate = {
		onCallReceived: (e: any) => {
			console.log(this)
			const { notification } = e
			const callId = notification.call.linked_uuid
			const call = this.calls[callId]
			if (!call) return
			call.callState = CallState.INCOMING
			call.callAnswered = false
			call.callInviteEvent = notification
			call.callStartTime = Date.now() / 1000 //this is a temp value until answered to handle order of multiple incoming calls
			this.emit(CallManagerEvents.CALL_RECEIVED, e)
		},
		onCallConnecting: (callId: string) => {
			const call = this.calls[callId]
			if (!call) return
			call.callState = CallState.CONNECTING
			this.activeCallId = callId
			this.emit(CallManagerEvents.CONNECTING, callId)
		},
		onCallAnswered: (callId: string) => {
			const call = this.calls[callId]
			if (!call) return
			call.callState = CallState.ACTIVE
			call.callAnswered = true
			call.callStartTime = Date.now() / 1000
			//session is private, is it time to remove simpleUser?
			this.setupRemoteMedia(call.session)
			this.simpleUser!["session"] = call.session
			this.emit(CallManagerEvents.CALL_ANSWERED, null)
			this.calls[callId].showCallStats()
			this.rb_debug_log('answered_call', { callId: callId })
		},
		onCallHangup: (callId: string) => {
			const call = this.calls[callId]
			if (!call) return
			if (!call.session) {
				delete this.calls[callId]
				this.emit(CallManagerEvents.CALL_HANGUP, null)
				return
			}
			// this.cleanupMedia()
			if (this.getCallsArray().length === 1) {
				//this is last call
				// console.log(this.simpleUser!.localMediaStream)
				this.simpleUser!.unregister()
				// this.simpleUser!.disconnect()
				window.micStream = null
				this.cleanupMedia()


			}
			if (call.statsIntervalId) clearInterval(call.statsIntervalId)
			
			
			let sdh = call.session!.sessionDescriptionHandler as any
			let pc = sdh.peerConnection as RTCPeerConnection
			pc.getSenders().forEach((t: RTCRtpSender) => t.track?.stop())

			call.session = undefined
			call.callState = null
			call.callAnswered = false

			if (this.activeCallId === callId && this.calls[this.activeCallId].participants.length === 1) this.activeCallId = null

			//if its greater than one, and the hung up call was the active call - you need to reassign the active call using one of the participants. and flip isMerged status.
			else if (this.activeCallId === callId && this.calls[this.activeCallId].participants.length > 1) {
				this.activeCallId = call.mergedCallIDs[call.participants[1].phoneNumber]
				this.calls[this.activeCallId].isMerged = false
			}

			delete this.calls[callId]

			//if you hangup the merged call yourself this logic shouldnt be hit, it will fail.
			if (call.participants.length > 1 && this.calls[call.mergedCallIDs[call.participants[0].phoneNumber]]) {
				//youre hanging up this call, but you dont want the others to drop,
				//make the next call in the participants list the active caller.
				let originalCallId = call.callId
				let newCallId = call.mergedCallIDs[call.participants[0].phoneNumber]
			
				let newCall = this.calls[newCallId]
				this.activeCallId = newCallId

				//now that this is the active call, it cannot be merged state.
				this.calls[this.activeCallId].isMerged = false
			}

			// if this is a merged call that has been hungup
			// if (call.participants.length > 1) {
			// 	call.participants.forEach((p: CallerInfo, index: number) => {
			// 		if (index !== 0) {
			// 			this.calls[p.phoneNumber].isMerged = false
			// 			this.emit(CallManagerEvents.STATE_UPDATE, {})
			// 		}
			// 	})
			// }

			// if a caller hung up on their own, check all other calls for this session, remove any reference of it. o(n^2) bad??
			Object.values(this.calls).forEach(session => {
				for(let i = 1; i < session.participants.length; i++) {
					if (session.mergedCallIDs[session.participants[i].phoneNumber] === callId) {
						session.participants.splice(i, 1)
					}
				}
			})
			
			this.emit(CallManagerEvents.CALL_HANGUP, null)
		},
		onCallCreated: (callId: string) => {
			const call = this.calls[callId]
			if (!call || call.callState === CallState.CONNECTING) return
			call.callState = CallState.INACTIVE
			this.emit(CallManagerEvents.CALL_CREATED, null)
		},
		onCallDTMFReceived: async (tone: any, duration: any) => {
			this.emit("callDTMFReceived", [tone, duration])
		},
		onCallHold: async (held: any) => {
			this.emit("callHold", held)
		},
		onCallStatUpdate: (callId: string, stat: any) => {
			this.emit(CallManagerEvents.CALL_STAT_UPDATE, { callId, stat })
		},
		onManagerStateUpdate: (callId: string) => {
			this.emit(CallManagerEvents.STATE_UPDATE, {})
		},
	}

	callSetupInProgress: boolean = false
	holdMusicLink?: string

	deniedAudioPermissions: boolean = false
	noDeviceFound: boolean = false

	private async rb_debug_log(message: string, extraData: any = {}) {
		if (window.Rollbar && typeof window.Rollbar.debug === "function") {
			window.Rollbar.debug(message, extraData)
		}
	}

	constructor() {
		super()
	}

	public connect = async (): Promise<null> => {
		try {
			await this.setMyCallerInfo(PhoneComUser.getPhoneNumber()[0])
		} catch (error) {
			console.error(error)
		}

		if (this.simpleUser) {
			return new Promise((resolve, reject) => {
				resolve(null)
			})
		}
		Api.registerSipDevice()

		let current_ext = null;
		if (window!.V5PHONECOM!.user_id && window!.V5PHONECOM!.user_default_extension_id) {
			current_ext = parseInt(window!.V5PHONECOM!.user_default_extension_id)
		} else {
			current_ext = parseInt(PhoneComUser.getExtensionId())
		}
		console.log(current_ext)
		const extension = await api.getExtension(current_ext)
		if (
			!extension ||
			!extension.device_membership ||
			!extension.device_membership.device ||
			!extension.device_membership.device.sip_authentication
		) {
			console.log("no device")
			this.noDeviceFound = true
			return null
		}

		console.log('connect', extension)
		let auth = extension.device_membership.device.sip_authentication
		let username = auth.username + "x0"
		let host = "sip.phone.com"
		let port = "9998"
		let password = auth.password
		let ua = {
			traceSip: true,
			// uri: username + '@' + host,
			uri: username + "@" + "phone.com",
			wsServers: ["wss://" + host + ":" + port],
			authorizationUser: username,
			password: password,
			realm: "phone.com",
			userAgentString: "CommunicatorWeb/" + "v1",
			log: {
				level: "warn",
			},
		}
		let uri = "sip:" + ua.uri



		// // Create media stream factory
		const myMediaStreamFactory: Web.MediaStreamFactory = (
			constraints: MediaStreamConstraints,
			sessionDescriptionHandler: Web.SessionDescriptionHandler
		): Promise<MediaStream> => {


			// const mediaStream = new MediaStream(); // my custom media stream acquisition
			return navigator.mediaDevices.getUserMedia({ audio: true, })
				.then((stream: MediaStream) => {
					this.deniedAudioPermissions = false
					window.micStream = stream
					return Promise.resolve(stream);
				})
				.catch(err => {
					console.log("caught media error")
					this.deniedAudioPermissions = true
					//wipe calls obj, cant make calls. update ui
					this.calls = {}
					this.emit(CallManagerEvents.STATE_UPDATE, {})
					throw Error("test")
				})
		}

		// Create session description handler factory
		const mySessionDescriptionHandlerFactory: Web.SessionDescriptionHandlerFactory = 
			Web.defaultSessionDescriptionHandlerFactory(myMediaStreamFactory);

		// Create user agent
		// const myUserAgent = new UserAgent({
		// 	sessionDescriptionHandlerFactory: mySessionDescriptionHandlerFactory
		// });


		let userAgentOptions: UserAgentOptions = {
			uri: UserAgent.makeURI(uri),
			authorizationPassword: auth.password,
			authorizationUsername: auth.username + "x0",
			logBuiltinEnabled: false,
			userAgentString: "CommunicatorWeb/" + "v1",
			displayName: extension.name,
			transportOptions: {
				server: ua.wsServers[0],
			},
			autoStop: true,
			// delegate: {
			//     onInvite: this.onInvite
			// }
			sessionDescriptionHandlerFactory: mySessionDescriptionHandlerFactory,
			// sessionDescriptionHandlerFactoryOptions: {}
		}

		// delegates https://github.com/onsip/SIP.js/blob/master/docs/simple-user/sip.js.simpleuserdelegate.md
		var options = {
			// delegate: this.callEventsDelegate,
			media: {
				local: {},
				remote: {
					audio: document.getElementById("remoteAudio") as HTMLAudioElement,
				},
			},
			connectionRecoveryMinInterval: 10,
			aor: "sip:" + ua.uri,
			userAgentOptions: userAgentOptions,
		}

		PDCOpenConnection.on("call_received", this.onCallNotification)
		let simpleUser = new SimpleUser(ua.wsServers[0], options)
		this.simpleUser = simpleUser
		simpleUser
			.connect()
			.then(() => {
				return simpleUser
			})
			.catch((err: any) => console.log(err))

		try {
			let selectedMusic = await Api.getAccountHoldMusic()
			console.log('selectedMusic:', selectedMusic)

			if (!selectedMusic) return null

			// let voicemailConfig = await Api.getVoicemailConfig()
			// let voipRecording	= voicemailConfig.voip_recording
			// let voipRecordingId	= voipRecording.voip_recording_id
			let voipRecordingId = selectedMusic.id
			let res = await Api.getMusicOnHoldLink(voipRecordingId)

			let link = res.download_link

			//let app default to backup song
			if (!link) return null

			this.holdMusicLink = link

		} catch (error) {
			console.log(error)
		}


		return null
	}

	protected onCallNotification = async (notification: any): Promise<void> => {
		console.log('call notification', notification)

		if (
			notification.call.status === "canceled" ||
			notification.call.status === "missed" ||
			(notification.call.status === "answered" &&
				(this.calls[notification.call.linked_uuid] &&
				this.calls[notification.call.linked_uuid].callState === CallState.INCOMING)
				|| (this.calls[notification.call.from] &&
					this.calls[notification.call.from].callState === CallState.INCOMING))
		) {
			this.hangupById(notification.call.linked_uuid)
		} else if (!notification.call.status && this.getCallsArray().length < 4) {
			const theirCallInfo: CallerInfo = {
				phoneNumber: notification.call.from,
				callerId: notification.call.caller_contact_name,
			}
			console.log('call notification', theirCallInfo)

			let myCallInfo: CallerInfo
			if (notification.call.called_number) {
				myCallInfo = {
					callerId: notification.call.called_number_name,
					phoneNumber: formatPhoneNumber(notification.call.called_number)
				}
			} else {
				myCallInfo = await this.getMyNumberInfo()
			}

			let session = new SipCallSession(
				[theirCallInfo],
				myCallInfo,
				notification.call.linked_uuid,
				CallState.INCOMING,
				this.callEventsDelegate
			)

			if (!this.calls[session.callId]) this.calls[session.callId] = session

			let e = { notification }
			this.calls[session.callId].callEventsDelegate.onCallCreated(e)
			this.calls[session.callId].callEventsDelegate.onCallReceived(e)

			//after 15s clear the call if it has not been answered
			// setTimeout(() => {
			// 	if(this.calls[session.callId].callState === CallState.INCOMING) {
			// 		delete this.calls[session.callId]
			// 	}
			// }, 15000)
			console.log('call notification', this.calls[session.callId])
		}
	}

	public call = async (callee: string) => {
		if (
			this.calls[callee] ||
			this.getCallsArray().length > 4 ||
			this.getCallsArray().filter((c) => c.callState === CallState.INACTIVE).length > 0
		) {
			return
		}
		//lock call ui
		this.callSetupInProgress = true
		this.emit(CallManagerEvents.STATE_UPDATE, null)
		this.initPreCallCheck(callee)

		// let myNumberInfo = await this.getMyNumberInfo()
		//set teh callerId to passed in value, wait for response, then continue process only if there is a success.
		
		if (!this.myCallerInfo) return

		let theirNumberInfo: CallerInfo = {
			callerId: callee,
			phoneNumber: callee,
		}
		// let callee just be ID youre searching for from session hashmap.
		let sessionId: string = callee
		let session = new SipCallSession(
			[theirNumberInfo],
			this.myCallerInfo,
			sessionId,
			CallState.CONNECTING,
			this.callEventsDelegate
		)
		this.calls[sessionId] = session

		this.registerAndPrepareInvite(sessionId)
		this.callSetupInProgress = false
		this.emit(CallManagerEvents.STATE_UPDATE, {})
		this.rb_debug_log('made_call', {callId: callee, callerId: this.myCallerInfo})
	}

	public async switchCall(callId: string): Promise<void> {
		if (!callId || !this.calls[callId]) {
			throw Error("trying to switch to non-existent call")
		}

		//hold active call - function already accounts for multi call scenarios
		await this.hold()

		//wipe old incoming audio
		this.cleanupMedia()

		//switch to new incoming audio
		this.setupRemoteMedia(this.calls[callId].session)

		//switch the current call
		this.activeCallId = callId
		this.simpleUser!["session"] = this.calls[callId].session

		//unhold current call
		await this.unhold()

		//if the length is greater than one, you need to remerge the audio tracks bc of how hold tracks are handled
		let call = this.calls[this.activeCallId]
		if (call.participants.length > 1) {
			for (let p of call.participants) {
				if (call.mergedCallIDs[p.phoneNumber] === callId) continue
				await this.mergeCall(call.mergedCallIDs[p.phoneNumber])
			}
		}

		//render update
		this.emit(CallManagerEvents.SWITCH_CALL, { callId })
		this.rb_debug_log('switch_call', { callId: callId })
	}

	//cann only hold active call
	async hold(id: string | null = this.activeCallId): Promise<any> {
		if (!id || !this.calls[id] || this.calls[id].isOnHold) {
			return
		}

		await this.calls[id].hold(this.holdMusicLink)
		//if youre on a merged call there is additional calls to hold
		if (id && !this.calls[id].isOnHold && this.calls[id].participants.length > 1) {
			let call = this.calls[id]
			for (let participant of call.participants) {
				let call_id = call.mergedCallIDs[participant.phoneNumber]
				if (call_id) {
					await this.calls[call_id].hold(this.holdMusicLink)
				}
			}
		}
		this.rb_debug_log('hold_call', { callId: id })
	}

	//can unhold inactive call to prepare for a merge.
	async unhold(id: string | null = this.activeCallId): Promise<any> {
		if (!id || !this.calls[id] || !this.calls[id].isOnHold) {
			return
		}

		await this.calls[id].unhold()

		if (this.calls[id].participants.length > 1) {
			let call = this.calls[id]
			for (let participant of this.calls[id].participants) {
				let call_id = call.mergedCallIDs[participant.phoneNumber]
				if (call_id) {
					await this.calls[call_id].unhold()
				}
			}
		}
		this.rb_debug_log('unhold_call', { callId: id })
	}
	async sendDTMF(tone: string): Promise<void> {
		this.simpleUser!.sendDTMF(tone).catch(() => {/* Do Nothing */ })
	}
	public async mergeCall(callIdToMerge: string): Promise<void> {
		//unhold background call
		await this.unhold()
		await this.unhold(callIdToMerge)

		//take all received tracks from the sessions you want to merge
		let activeSession = this.calls[this.activeCallId!] as any
		let sessiontoMerge = this.calls[callIdToMerge] as any

		//make sure nothing is muted
		activeSession.muteLocal(false)
		activeSession.muteRemote(false)

		sessiontoMerge.muteLocal(false)
		sessiontoMerge.muteRemote(false)

		let sessions: SipCallSession[] = [activeSession, sessiontoMerge]

		let receivedTracks = this.getAllReceiverTracks(sessions)

		//use the Web Audio API to mix the received tracks
		let AudioCtontext = window.AudioContext || (window as any).webkitAudioContext
		const context = new AudioContext()
		const allReceivedMediaStreams = new MediaStream()

		// Update merged IDs list
		activeSession.mergedCallIDs[sessiontoMerge.participants[0].phoneNumber] = sessiontoMerge.callId
		for(let number in sessiontoMerge.mergedCallIDs){
			activeSession.mergedCallIDs[number] = sessiontoMerge.mergedCallIDs[number]
		}

		await this.mixAllAudioForMerge(sessions, context, receivedTracks, allReceivedMediaStreams)

		this.initStream(allReceivedMediaStreams, callIdToMerge)
		this.rb_debug_log('merge_call', { callIdToMerge: callIdToMerge })
	}

	//gather all receivers for merge - all incoming audio tracks
	private getAllReceiverTracks(callSessions: SipCallSession[]): MediaStreamTrack[] {
		let receivedTracks: MediaStreamTrack[] = []

		for (let session of callSessions) {
			if (session) {
				let sdh: any = session.session!.sessionDescriptionHandler!
				let pc: RTCPeerConnection = sdh.peerConnection
				pc.getReceivers().forEach((receiver: RTCRtpReceiver) => {
					receivedTracks.push(receiver.track)
				})
			}
		}
		return receivedTracks
	}

	private async mixAllAudioForMerge(
		sessions: SipCallSession[],
		context: AudioContext,
		receivedTracks: MediaStreamTrack[],
		allReceivedMediaStreams: MediaStream
	): Promise<void> {
		for (let session of sessions) {
			if (session) {
				//create an incoming audio output to <audio/> using all callers incoming audio
				const mixedOutput = context.createMediaStreamDestination()
				let sdh: any = session.session!.sessionDescriptionHandler!
				let pc: RTCPeerConnection = sdh.peerConnection
				let receivers = pc.getReceivers()
				let senders = pc.getSenders()

				//connect all incoming audio
				receivers.forEach((receiver: any) => {
					receivedTracks.forEach((track) => {
						allReceivedMediaStreams.addTrack(receiver.track)
						if (receiver.track.id !== track.id) {
							const sourceStream = context.createMediaStreamSource(new MediaStream([track]))
							sourceStream.connect(mixedOutput)
						}
					})
				})

				//mixing your voice with all the received audio
				senders.forEach((sender: any) => {
					const sourceStream = context.createMediaStreamSource(new MediaStream([sender.track]))
					sourceStream.connect(mixedOutput)
				})

				let tracks = mixedOutput.stream.getTracks()
				await senders[0].replaceTrack(tracks[0])
			}
		}
	}

	private async initStream(allReceivedMediaStreams: MediaStream, callIdToMerge: string): Promise<void> {
		//play all received stream to you
		let remoteAudioId = "remoteAudio"
		const remoteAudio = document.getElementById(remoteAudioId) as HTMLAudioElement
		remoteAudio.srcObject = allReceivedMediaStreams
		const promiseRemote = remoteAudio.play()
		if (promiseRemote !== undefined) {
			promiseRemote
				.then((_) => {
					console.log("playing all received streams to you")

					//if everything worked, attach joined callId to currentCall
					let current = this.calls[this.activeCallId!]
					let callToMerge = this.calls[callIdToMerge]
					console.log('9933 shouldnt show here', callToMerge.callId)
					callToMerge.isMerged = true
					let info = callToMerge.participants[0]

					//if its not already in the array.
					if (!current.participants.find((c) => c.phoneNumber === info.phoneNumber)) {
						current.participants.push(info)
					}
					this.emit(CallManagerEvents.MERGE_CALL, null)
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	public muteCurrentLocal = (isMuted: boolean): void => {
		if (this.activeCallId) {
			let call = this.calls[this.activeCallId]
			call.muteLocal(isMuted)
			for(let number in call.mergedCallIDs){
				let p_id = call.mergedCallIDs[number]
				this.calls[p_id].muteLocal(isMuted)
			}
		}
	}

	public muteCurrentRemote = (isMuted: boolean): void => {
		if (this.activeCallId) {
			let call = this.calls[this.activeCallId]
			call.muteRemote(isMuted)
			for(let number in call.mergedCallIDs){
				let p_id = call.mergedCallIDs[number]
				this.calls[p_id].muteRemote(isMuted)
			}
		}
	}

	public answerById(id: string): void {
		this.initPreCallCheck(id)
		this.callEventsDelegate.onCallConnecting(id)
		this.registerAndPrepareInvite(id)
		this.rb_debug_log('answered_incoming_call', { callId: id })
	}

	async hangupById(id: string, endAll: boolean = false): Promise<any> {
		const call = this.calls[id]
		if (!call) return

		call.hangup()

		//if call was merged, send bye to others
		if (endAll && call.participants.length > 1) {
			for(let number in call.mergedCallIDs){
				let p_id = call.mergedCallIDs[number]
				if(this.calls[p_id]){
					this.calls[p_id].hangup()
				}
			}
		} else {
			//if not ending all participants, make sure to put rest of calls on hold. - might not make sense for n calls merged the, only for 3 call scenario?
			for(let number in call.mergedCallIDs){
				let p_id = call.mergedCallIDs[number]
				await this.hold(p_id)
			}
		}
	}

		//if call was merged, send bye to others
	

	private initPreCallCheck(callId: string) {
		//if there is an active call, put that session on hold, and then switch
		if (this.activeCallId && this.calls[this.activeCallId].participants.length === 1) {
			this.hold()
			this.muteCurrentRemote(true)
			//wipe old incoming audio
			this.cleanupMedia()
			this.emit(CallManagerEvents.SWITCH_CALL, { callId: callId })
		}
		//work flow for multi call is different since we need two hold tracks.
		else if (this.activeCallId && this.calls[this.activeCallId].participants.length > 1) {
			//replace both sending tracks

			this.hold()
			this.muteCurrentRemote(true)
			//wipe old incoming audio
			this.cleanupMedia()
			this.emit(CallManagerEvents.SWITCH_CALL, { callId: callId })
		}
	}

	private registerAndPrepareInvite(sessionId: string) {
		this.activeCallId = sessionId
		let registererOpts: RegistererRegisterOptions = {
			requestDelegate: {
				onAccept: () => {
					console.log("register options worked")
					try {
						this.calls[sessionId].prepareInvite(this.simpleUser!["userAgent"])
							.catch(err => {
								console.log(err)
								console.log(typeof err)
							})
					} catch (error) {
						console.log(error)
					}
				},
			},
		}
		this.simpleUser!.register(undefined, registererOpts).catch((err) => console.log(err))
	}
	async getMyNumberInfo(): Promise<any> {
		let activeCallerId = null
		try {
			const response = await api.fetchActiveCallerId()
			if (response.caller_id) activeCallerId = response.caller_id
		} catch (error) {
			console.error(error)
			return {
				phoneNumber: "",
				callerId: "",
			}
		}

		let selected = activeCallerId
		const number = formatPhoneNumber(selected)
		let myNumberInfo: CallerInfo = {
			phoneNumber: number,
			callerId: number,
		}
		return myNumberInfo
	}

	async setMyCallerInfo(callerId: string) {
		let activeCallerId = null
		let response = null

		if(!callerId) return 

		if(window.V5PHONECOM && window.V5PHONECOM.user_id && window.V5PHONECOM.user_default_extension_id){
			response = await api.setActiveCallerId(callerId, window.V5PHONECOM.user_default_extension_id)
		}else{
			response = await api.setActiveCallerId(callerId)
		}

		console.log('setMyCallerInfo', callerId, response)
		if (response.message && response.message === "success"){
			activeCallerId = callerId
			let selected = activeCallerId
			const number = formatPhoneNumber(selected)
			this.myCallerInfo = {
				phoneNumber: number,
				callerId: number,
			}
		}
	}

	// setActiveNumber(phoneNumber: string) {
	// 	this.activePhoneNumber = phoneNumber
	// }

	cleanupMedia(): void {
		const mediaElement: HTMLAudioElement = document.getElementById("remoteAudio")! as HTMLAudioElement
		mediaElement.srcObject = null
		mediaElement.pause()
	}
	setupRemoteMedia(session: any): void {
		const mediaElement: HTMLAudioElement = document.getElementById("remoteAudio")! as HTMLAudioElement
		const remoteStream = new MediaStream()
		session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver: any) => {
			if (receiver.track) {
				remoteStream.addTrack(receiver.track)
			}
		})
		mediaElement.srcObject = remoteStream
		mediaElement.play()
	}

	test(): void {
		throw new Error("Method not implemented.")
	}
	muteById(id: string): void {
		throw new Error("Method not implemented.")
	}
	addSession(session: CallSession): void {
		throw new Error("Method not implemented.")
	}
	removeSession(session: CallSession): void {
		throw new Error("Method not implemented.")
	}
	setActiveCall(callId: string): void {
		throw new Error("Method not implemented.")
	}
	supportsById = (callId: string): Map<string, boolean> => {
		console.log(callId)
		return this.calls[callId].supports()
	}

	isCallingEnabled = (): boolean => {
		return !process.env.REACT_APP_IS_CALLING_DISABLED;
	}

	isOutboundCallingEnabled = (): boolean => {
		return this.isCallingEnabled() && !window!.safari;
	}

	supports = (is_extension_virtual: boolean): Map<CallFeatures, boolean> => {
		var ret = new Map<CallFeatures, boolean>();
		ret.set(CallFeatures.outboundCalling, is_extension_virtual ? false : this.isOutboundCallingEnabled());
		return ret;
	}
}

export default SipCallManager
