// This is a javascript library to create a listener-only open connection with PDC's IoT core based on account credentials.
// To be used as a websocket. From a use perspective, any IoT or AWS references should be abstracted away.
// Websocket connection is stored as a global to prevent multiple websockets from opening at the same time.

// Usage:
// Connect to the websocket with PDCOpenConnection.connect()
// Subscribe to topics with PDCOpenConnection.subscribe(topicName)
// For every topic, create a callback that handles messages under that topic
// PDCOpenConnection.on(topicName, callback), where m is the message in json
import PhoneComUser from 'phone-com-user'
import ajax from 'ajax'
import mqtt from 'mqtt'

class PDCOpenConnection {

	constructor() {
		this.client				= null
		this.connected			= false
		this.connecting			= false
		this.subscriptions		= []
		this.accountSubscriptions = []
		this.pendingSubs		= []
		this.pendingAccountSubs	= []
		this.topicCallbacks		= {}
		this.lostConnection		= false
		this.retryingConnection = false
		this.reconnectCallbacks	= []
		
		if (window.pdcOpenConnection) {
			this.client				= window.pdcOpenConnection.client
			this.connecting			= window.pdcOpenConnection.connecting
			this.topicCallbacks		= window.pdcOpenConnection.topicCallbacks
			this.subscriptions		= window.pdcOpenConnection.subscriptions
			this.pendingSubs		= window.pdcOpenConnection.pendingSubs
			this.accountSubscriptions	= window.pdcOpenConnection.subscriptions
			this.pendingAccountSubs	= window.pdcOpenConnection.pendingSubs
			this.connected			= window.pdcOpenConnection.connected
			this.lostConnection		= window.pdcOpenConnection.lostConnection
			this.reconnectCallbacks	= window.pdcOpenConnection.reconnectCallbacks || []
		} else {
			window.pdcOpenConnection = this
		}
	}

	subscribe = topic => {
		if (this.subscriptions.includes(topic))
			return

		if (!this.client) {
			this.pendingSubs.push(topic)
			return
		}

		if (!PhoneComUser.getExtensionId()) return

		let root = this.topicRoot()
		let fullTopic = `${root}/${topic}`

		let subscriptionErr
		this.client.subscribe(fullTopic, err => {
			if (!err) return
			this.retryingConnection = true
			subscriptionErr = err
			this.subscribe(topic)
		})

		if (subscriptionErr) return
			
		this.subscriptions.push(topic)
	}

	subscribeAccount = topic => {

		if (PhoneComUser.getRole() !== 'account')
			return

		if (this.accountSubscriptions.includes(topic))
			return

		if (!this.client) {
			this.pendingAccountSubs.push(topic)
			return
		}

		let root = this.topicAccountRoot()
		let fullTopic = `${root}/${topic}`

		let subscriptionErr
		this.client.subscribe(fullTopic, err => {
			if (!err) return
			this.retryingConnection = true
			subscriptionErr = err
			this.subscribeAccount(topic)
		})

		if (subscriptionErr) return
			
		this.accountSubscriptions.push(topic)
	}

	topicAccountRoot = () => {
		let account_id		= PhoneComUser.getAPIAccountId()
		let stage			= PhoneComUser.getStage()
		return `${stage}/account/${account_id}`
	}

	topicRoot = () => { 
		let account_id		= PhoneComUser.getAPIAccountId()
		let extension_id	= PhoneComUser.getExtensionId()
		let stage			= PhoneComUser.getStage()
		return `${stage}/account/${account_id}/extension/${extension_id}`
	}

	// This is a hard reset for switching users or resetting bad connections
	hardReset = () => {
		this.connected		= false
		this.connecting		= false
		if (this.client) {
			this.client.end()
			delete this.client
		}
		this.connect()
	}

	connect = () => {
		if (this.connecting || this.connected) return this.client
		this.connecting = true
		
		// make a request to signing endpoint with pdc credentials
		let websocketURL = `${PhoneComUser.getv5ToolsRoot()}/messaging/get-websocket-connection`

		ajax.postAccount(websocketURL, {})
		.then(response => {
			if (!response.data) return

			let url = response.data.url
			let client = mqtt.connect(url, {
				'clientId': 'mqttjs_' + Math.random().toString(16).substr(2, 8),
				reconnectPeriod: 0
			})

			client.on('connect', () => {
				this.connected	= true
				this.connecting	= false
				// Subscribe to all topics that were created before the connection was made
				this.pendingSubs.forEach(this.subscribe)
				this.pendingAccountSubs.forEach(this.subscribeAccount)

				if (this.lostConnection && this.reconnectCallbacks.length) {
					this.reconnectCallbacks.forEach(callback => callback(this.retryingConnection))
				}
				this.retryingConnection = false
				this.lostConnection = false

			})

			client.on('message', (fullTopic, message) => {
				let topic = fullTopic.split('/').pop()
				let callbacks = this.topicCallbacks[topic]
				if (callbacks) {callbacks.forEach(callback => callback(JSON.parse(message.toString('utf8'))))}
			})

			client.on('close', e => {
				this.pendingSubs	= (this.subscriptions && this.subscriptions.length > 0 )? this.subscriptions: this.pendingSubs // stops overriding pendingSubs with empty sub list
				this.pendingAccountSubs	= (this.accountSubscriptions && this.accountSubscriptions.length > 0 ) ? this.accountSubscriptions : this.pendingAccountSubs
				this.accountSubscriptions = []
				this.subscriptions	= []
				this.connected		= false
				this.lostConnection	= true
				this.hardReset()
			})

			client.on('error', (e) => {
				// error
			})

			client.on('reconnect', () => {
				// error
			})

			this.client = client
			window.pdcOpenConnection = this
			return client
		},
		error => {
			this.connecting = false;
		}).catch((err) => {
			setTimeout(this.connect(), 3000);
		})
	}
	
	close = () => this.client.end()

	// on Topic
	// Connect if not connected
	// subscribe if not subscribed
	on = (topic, callback) => {

		if (!this.connected) this.connect()

		this.subscribe(topic)

		if (!this.topicCallbacks[topic])
			this.topicCallbacks[topic] = [callback]
		else if (!this.topicCallbacks[topic].includes(callback)) {
			this.topicCallbacks[topic].push(callback)
		}
	}

	onAccount = (topic, callback) => {

		if (!this.connected) this.connect()

		this.subscribeAccount(topic)

		if (!this.topicCallbacks[topic])
			this.topicCallbacks[topic] = [callback]
		else if (!this.topicCallbacks[topic].includes(callback)) {
			this.topicCallbacks[topic].push(callback)
		}
	}

	// Probably shouldn't use this. removes based on function comparison
	// Would prefer if we managed whether to exec inside the callback
	removeCallback = (topic, callback) => {
		if (!this.topicCallbacks[topic]) return
		this.topicCallbacks[topic].filter(cb => cb !== callback)
	}

	onConnect = callback => this.client ? this.client.on('connect', callback) : null
	
	onReconnect = callback => this.reconnectCallbacks.push(callback)

	onClose = callback => this.client ? this.client.on('close', callback) : null
}

export default new PDCOpenConnection()