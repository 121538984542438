import React, { Component } from "react"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import { withStyles } from "@material-ui/core"
import { theme } from "get-theme"
import SignalInfo from "signal-info"
import { DefaultArrowTooltip } from "tooltips"
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap"
import MicIcon from "@material-ui/icons/Mic"
import CallIcon from "@material-ui/icons/Call"
import CallEndIcon from "@material-ui/icons/CallEnd"
import MicOffIcon from "@material-ui/icons/MicOff"
import PersonIcon from "@material-ui/icons/Person"
import CallBarActiveCallArea from "./CallBarActiveCallArea"
import Dialpad from "dialpad"
import DialpadIcon from "@material-ui/icons/Dialpad"
import gtmDataPush from 'gtm-events'

const GTM_APP_NAME = 'call-bar-desktop'
const GTM_MAP = {SAME_APP: 0, SWITCH_APP: 1, UNMUTE: 0, MUTE: 1, NO_CALL: 2}

const styles = (theme) => ({
	notificationContainerDesktop: {
		width: "100%",
		height: "fit-content",
		minHeight: 65,
		zIndex: 3,
		display: "flex",
		justifyContent: "space-between",
		textAlign: "center",
		color: "white",
		background: `linear-gradient(90deg, ${theme.palette.tertiary["+100"]} 0%, ${theme.palette.tertiary["-100"]} 99.6%)`,
		// TODO: not in palette
		// background: "linear-gradient(90deg, #278DA7 0%, #34A4C0 99.6%)",
		position: "relative",
		"&.secondary": {
			padding: 10,
		},
		"& .link": {
			textDecoration: "underline",
			color: "white",
			cursor: "pointer",
		},
	},
	notificationBarContent: {
		width: "100%",
		height: "100%",
		minHeight: 36,
		// padding: 10,
		// backgroundImage: "linear-gradient(-315deg, #4f5e69, #212527)",
		zIndex: 3,
		display: "flex",
		// alignItems: "center",
		justifyContent: "space-between",
		textAlign: "center",
		color: "white",
		position: "relative",
		"&.secondary": {
			padding: 10,
		},
		"& .link": {
			textDecoration: "underline",
			color: "white",
			cursor: "pointer",
		},
	},
	callBarContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
	},
	section: {
		flex: 1,
		fontSize: 18,
		"&.mobile": {
			fontSize: 14,
		},
	},
	leftSection: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontWeight: 500,
		fontSize: 22,
		"& > span:first-child": {
			marginRight: 10,
		},
		"&.mobile": {
			flexDirection: "column",
			fontSize: 14,
		},
		"&.tablet": {
			fontSize: 18,
		},
	},
	middleSection: {
		position: "absolute",
		left: "50%",
		transform: "translateX(-50%)",
	},
	dialpadWrapper: {
		height: 24,
		width: 24,
		float: "left",
		// marginRight: 10,
	},
	linkSeparator: {
		padding: "0 5px",
	},
	closeNotificationBar: {
		position: "absolute",
		right: 15,
		top: "50%",
		transform: "translateY(-50%)",
		fontSize: 26,
		cursor: "pointer",
	},
	goToCallIcon: {
		width: 24,
		height: 24,
		cursor: "pointer",
		// marginLeft: 10,
		color: theme.palette.secondary["+200"],
	},
	goToCallIconMobile: {
		width: 24,
		height: 24,
		cursor: "pointer",
		// marginLeft: 10,
		color: "white",
	},
	goToCallButton:{
		backgroundColor: `${theme.palette.primary['0']} !important`
	},
	endCallButton:{
		backgroundColor: `${theme.palette.attention['0']} !important`
	},
	selectorToggle: {
		alignSelf: "end",
		borderLeftColor: "black",
		borderLeftStyle: "solid",
		borderLeftWidth: "0.25px",
		height: "100%",
		width: "60px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: theme.palette.secondary["-200"],
	},
	callControlCallBarButton: {
		width: "40px",
		height: "40px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		// opacity: "0.65",
		borderRadius: "5px",
		marginLeft: "1em",
		backgroundColor: theme.palette.tertiary["-100"],
		"&:hover": {
			backgroundColor: theme.palette.tertiary["-100"],
			opacity: 0.6,
		},
		"&:active": {
			backgroundColor: theme.palette.tertiary["-100"],
			opacity: 0.3,
		},
		cursor: "pointer",
	},
	// mobile version of above
	callControlCallBarContainer: {
		width: "28px",
		height: "28px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer",
		marginLeft: 15,
		color: "black",
	},
	dialPadIcon: { height: 30, width: 25, cursor: "pointer", color: "white" },
	callMenuContainer: {
		maxWidth: 450,
		position: "absolute",
		left: "calc(50% - 225px)",
		zIndex: 7,
		bottom: 55,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
		borderTopLeftRadius: 7,
		borderTopRightRadius: 7,
		overflow: "hidden",
		marginBottom: 10,
	},
	callMenuContainerTail: {
		borderLeft: "20px solid transparent",
		borderRight: "20px solid transparent",
		borderTop: "20px solid white",
		boxSizing: "border-box",
		// border: "1em solid black",
		borderColor: "transparent transparent white white",
		transformOrigin: "0 0",
		transform: "rotate(-45deg)",
		//TODO: do these count towards palette? diff random black lvls
		boxShadow: "-28px 12px 24px rgba(0, 0, 0, 0.2)",
	},
	callMenuContent: {
		backgroundColor: "white",
		width: "100%",
		minHeight: "51px",
		boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 12px, rgba(0, 0, 0, 0.25) 0px 4px 20px",
		borderBottomRightRadius: "7px",
		borderBottomLeftRadius: "7px",
		overflow: "hidden",
	},
	callBarControlsContainer: {
		maxWidth: "300px",
		display: "flex",
		flexDirection: "row",
		//justifyContent: "space-around",
		width: "100%",
		alignItems: "center",
		height: "100%",
	},
	holdAreaEmpty: {
		backgroundColor: theme.palette.tertiary["0"],
		// backgroundColor: "#26859D",
		maxWidth: 275,
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
	},
	holdArea: {
		backgroundColor: theme.palette.attention["-100"],
		//TODO: not in palette
		// backgroundColor: "#FF7A6B",
	},
	desktopOnHoldCallContainer: {
		width: 40,
		height: 46,
		"&:hover": {
			backgroundColor: theme.palette.attention["+100"],
		},
		"&:active": {
			opacity: 1,
		},
		cursor: "pointer",
	},
	activeMic: { width: 24, height: 24, color: theme.palette.attention["0"] },
	inactiveMic: { width: 24, height: 24, color: "white" },
})

class CallBarContentDesktop extends Component {
	state = {
		isDialpadShown: false,
	}

	onEndCallClick = e => {
		// ignore toggling call menu when clicking end call
		e.stopPropagation()
		const { currentCall } = this.props
		if (this.props.isExpanded) this.props.toggleShowCallSessionSelector()
		this.props.hangupById(currentCall.callId, true)
		gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'end-button-click'})
	}

	renderHoldCalls = () => {
		if (this.props.callsOnHold.length > 0) {
			return (
				<>
					<span style={{ marginLeft: 20, marginRight: 20 }}>{"On Hold"}</span>
					{this.props.callsOnHold.map((c) => (
						<DefaultArrowTooltip title={c.participants[0] ? c.participants[0].callerId : "not available"}>
							<div className={this.props.classes.desktopOnHoldCallContainer}>
								<PersonIcon
									style={{ width: 30, height: 30, color: "white", marginLeft: 10, marginRight: 10 }}
								/>
							</div>
						</DefaultArrowTooltip>
					))}
				</>
			)
		}
		return this.props.currentCall ? "No callers on hold" : "No active calls"
	}

	openMakeACall = () => {
		let pathnameSplit = window.location.pathname.split('/')
		let isCallsAppOpened = pathnameSplit.length > 2 && pathnameSplit[2] === 'calls'
		gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'call-button-click', PDC_Value: isCallsAppOpened ? GTM_MAP.SAME_APP : GTM_MAP.SWITCH_APP})
		this.props.openMakeACall()
	}

	openDialer = () => {
		gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'open-dialer-click'})
		this.props.openDialer()
	}

	onMuteToggle = () => {
		let GTM_VALUE = this.props.currentCall ? this.props.isMutedLocal ? GTM_MAP.UNMUTE : GTM_MAP.MUTE : GTM_MAP.NO_CALL
		gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'mute-click', PDC_Value: GTM_VALUE})
		this.props.onMuteToggle()
	}

	render() {
		const { classes } = this.props
		const supportedActions = this.props.currentCall ? this.props.supportsById(this.props.currentCall.callId) : {}
		return (
			<div className={classes.notificationContainerDesktop} data-test-id={"callbar"}>
				<div className={classes.callBarContainer}>
					{/* overlay div */}
					{this.props.isExpanded ? (
						<div className={classes.callMenuContainer}>
							{/* main content */}
							<div className={classes.callMenuContent}>{this.props.renderCallSelector()}</div>

							{/* bubble tail */}
							<div className={classes.callMenuContainerTail}></div>
						</div>
					) : null}
					{/* callbar content */}
					<div className={`${classes.notificationBarContent}`} id="notification-bar">
						{/* call controls */}
						<div className={classes.callBarControlsContainer}>
							{/* TODO: move to a call control button class */}
							{/* <div className={classes.callControlCallBarButton}>
								<SignalInfo
									width={24}
									height={24}
									color={'white'}
									jitter={this.props.callStats ? this.props.callStats.jitter : null}
									packetsLost={this.props.callStats ? this.props.callStats.packetsLost : null}
									avgLatency={this.props.callStats ? this.props.callStats.avgLatency : null}
									signalStr={this.props.callStats ? this.props.callStats.signalStr : null}
									sendCodec={this.props.callStats ? this.props.callStats.sendCodec : null}
									recvCodec={this.props.callStats ? this.props.callStats.recvCodec : null}
									isActive={this.props.currentCall && this.props.currentCall.callState === "ACTIVE"}
								/>
							</div> */}

							{/* <div>{this.renderCallBarSessionIndicators()}</div> */}

							{this.props.currentCall || this.props.backgroundCalls.length > 0 ? (
								<div
									className	= {`${classes.callControlCallBarButton} ${classes.endCallButton}`}
									onClick		= {this.onEndCallClick}
								>
									<CallEndIcon
										className={classes.goToCallIconMobile}
										data-test-id={"go-to-dialer-btn"}
									/>
								</div>
							) : (
								<div
									className={`${classes.callControlCallBarButton} ${classes.goToCallButton} `}
									onClick={this.openMakeACall}
								>
									<CallIcon
										className={classes.goToCallIconMobile}
										data-test-id={"go-to-dialer-btn"}
									/>
								</div>
							)}

							{this.props.currentCall || this.props.backgroundCalls.length > 0 ? (
								<div
									className	= {classes.callControlCallBarButton}
									onClick		= {() => gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'signal-info-click'})}
								>
									<SignalInfo
										width={24}
										height={24}
										color={"white"}
										jitter={this.props.callStats ? this.props.callStats.jitter : null}
										packetsLost={this.props.callStats ? this.props.callStats.packetsLost : null}
										avgLatency={this.props.callStats ? this.props.callStats.avgLatency : null}
										mosScore={this.props.callStats ? this.props.callStats.mosScore : null}
										sendCodec={this.props.callStats ? this.props.callStats.sendCodec : null}
										recvCodec={this.props.callStats ? this.props.callStats.recvCodec : null}
										isActive={
											this.props.currentCall && this.props.currentCall.callState === "ACTIVE"
										}
									/>
								</div>
							) : null}

							{this.props.currentCall || this.props.backgroundCalls.length > 0 ? (
								<DefaultArrowTooltip title="Go to the call" placement="top">
									<div className={classes.callControlCallBarButton}>
										<ZoomOutMapIcon
											className={classes.goToCallIconMobile}
											onClick={this.openDialer}
											data-test-id={"go-to-dialer-btn"}
										/>
									</div>
								</DefaultArrowTooltip>
							) : null}

							{this.props.currentCall || this.props.backgroundCalls.length > 0
								? this.props.renderDialpad()
								: null}

							{/* TODO: insert mic control */}
							{supportedActions["MUTE"] && (this.props.currentCall || this.props.backgroundCalls.length > 0) ? (
								<div className={classes.callControlCallBarButton} onClick={this.onMuteToggle}>
									{this.props.currentCall ? (
										!this.props.isMutedLocal ? (
											<MicIcon
												style={{ width: 30, height: 30, color: theme.palette.attention["0"] }}
												data-test-id='callbar-mute-icon-on'
											/>
										) : (
											<MicOffIcon
												style={{
													width: 30,
													height: 30,
													color: theme.palette.attention["0"],
													opacity: 0.5,
												}}
												data-test-id='callbar-mute-icon-off'
											/>
										)
									) : (
										<MicIcon style={{ width: 30, height: 30, color: "white" }} />
									)}
								</div>
							) : null}
						</div>

						{/* active call center toggle area */}
						{this.props.currentCall || this.props.backgroundCalls.length > 0 ? (
							<CallBarActiveCallArea
								toggleShowCallSessionSelector={this.props.toggleShowCallSessionSelector}
								onEndCallClick={e => this.onEndCallClick(e, 2)}
								currentCall={this.props.currentCall}
								isExpanded={this.props.isExpanded}
							/>
						) : null}

						{/* calls on hold area */}
						<div
							className={`${classes.holdAreaEmpty} ${
								this.props.backgroundCalls.length > 0 ? classes.holdArea : ""
							}`}
						>
							{this.renderHoldCalls()}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default withStyles(styles)(CallBarContentDesktop)
