import React, { Component } from 'react'
import styles from './styles'
import SelectionControl from 'selection-control'
import TextField from 'pdc-text-field'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { withStyles } from '@material-ui/core'

class Menu extends Component {

	constructor(props) {
		super(props)
		this.wrapperRef			= React.createRef()
		this.inputRef			= React.createRef()
		this.buttonContentRef	= React.createRef()
		this.itemsWrapperRef	= React.createRef()
		this.state = {
			flipped:					false,
			expanded:			false,
			menuOffsetTop:		0,
			inputValue:			this.getDefaultText(),
			selectedValues:		this.getDefaultVal(),
			shownElementValues:	[]
		}
	}

	getDefaultText = () => {
		let { variant, items } = this.props
		let defaultVal = this.props.default
		// NOTE: The inputValue (for which we call this function) is used only in case variant is 'input'
		if ((!defaultVal && defaultVal !== 0) || variant !== 'input') return ''
		let item = items.find(i => i.value === defaultVal)
		return this.itemToString('', item)
	}

	getDefaultVal = () => {
		let defaultVal = this.props.default
		if (!defaultVal && defaultVal !== 0) defaultVal = []
		return Array.isArray(defaultVal) ? defaultVal : [defaultVal]
	}

	componentDidMount() {
		this.setButtonContentWidth()
	}

	componentDidUpdate(prevProps, prevState) {
		this.setMenuOffsetTop(prevState)

		// Change the selection if the menu is controlable
		this.changeSelection(prevProps)

		// Set button content width
		let prevSelectedValues	= prevState.selectedValues
		let selectedValues		= this.state.selectedValues
		let prevItems			= prevProps.items
		let currentItems		= this.props.items
		if (!this.areArraysSame(prevSelectedValues, selectedValues) || !this.areArraysSame(prevItems, currentItems)) return this.setButtonContentWidth()

		// On open scroll to the selected item (if any)
		if (!prevState.expanded && this.state.expanded && this.state.selectedValues.length) {
			this.scrollToFirstSelection()
		}
	}

	scrollToFirstSelection = () => {
		let itemsWrapperElement = this.itemsWrapperRef ? this.itemsWrapperRef.current : null
		if (!itemsWrapperElement) return
		let firstSelectedValue = this.state.selectedValues[0]
		let firstSelectedElement = Array.from(itemsWrapperElement.children).find(e => e.getAttribute('value') === `${firstSelectedValue}`)
		if (!firstSelectedElement) return
		let allignOffset = (itemsWrapperElement.offsetHeight - firstSelectedElement.offsetHeight) / 2
		if (allignOffset < 0) allignOffset = 0
		itemsWrapperElement.scrollTop = firstSelectedElement.offsetTop - allignOffset
	}

	changeSelection = prevProps => {
		if (!this.props.controlable) return
		let variant		= this.props.variant
		let prevDefault	= prevProps.default
		let thisDefault	= this.props.default
		if (['single', 'input'].includes(variant) && prevDefault === thisDefault) return
		if (variant === 'multiple' && this.areArraysSame(prevDefault, thisDefault)) return
		this.setState({
			inputValue:		this.getDefaultText(),
			selectedValues:	this.getDefaultVal()
		})
	}

	setMenuOffsetTop = prevState => {
		if (prevState.expanded || !this.state.expanded) return
		this.setState({flipped: false})
		let buttonElement = this.wrapperRef.current.children[0]
		let menuOffsetTop = buttonElement.offsetHeight
		let viewHeight = window.innerHeight;
		let hasChildren = this.wrapperRef.current.children.length

		// Reverse direction if the menu would go offscreen
		if (hasChildren) {
			let lastChild = this.wrapperRef.current.children[this.wrapperRef.current.children.length - 1]
			if (this.wrapperRef.current.offsetTop + lastChild.offsetHeight > viewHeight) {
				menuOffsetTop = this.itemsWrapperRef.current.offsetHeight * -1
				this.setState({flipped: true})
			}
		}
		this.setState({menuOffsetTop})
	}

	setButtonContentWidth = () => {
		let selectionElement = this.buttonContentRef.current
		if (!selectionElement) return
		let selectionElements = Array.from(selectionElement.children)
		let calculationDivElement = selectionElements.find(e => {
			let classAttr = e.getAttribute('class')
			if (!classAttr) return false
			return classAttr.includes('calculation-div')
		})
		let calculationElements = Array.from(calculationDivElement.children)
		let wrapperWidth = this.getWrapperWidth()
		let selectionWidth = wrapperWidth - 50
		let mappedElements = calculationElements.map(element => {
			let classAttr = element.getAttribute('class')
			if (!classAttr) return false
			return {
				width:	this.getElementWidth(element),
				value:	classAttr.split('menu-value-')[1].split(' ')[0]
			}
		}).filter(e => e)
		let shownElementValues = []
		let widthSum = 0
		let stop = false
		mappedElements.forEach((me, i) => {
			if (stop) return
			let margin = i < mappedElements.length - 1 ? 5 : 0
			let addWidth = me.width + margin
			// i !== 0 means that the first element will always be shown. If wider than the max-width then it will have ellipsis
			if (i !== 0 && widthSum + addWidth > selectionWidth) return stop = true
			widthSum += addWidth
			shownElementValues.push(`${me.value}`)
		})
		this.setState({shownElementValues})
	}

	areArraysSame = (a1, a2) => {
	   return a1 === a2
	}

	getWrapperWidth = () => {
		let wrapperElement = this.wrapperRef.current
		return this.getElementWidth(wrapperElement)
	}

	getElementWidth = element => {
		let styles = window.getComputedStyle(element)
		return parseFloat(styles.width.split('px')[0])
	}

	toggle = e => {
		let expanded = this.state.expanded
		if (expanded && ['INPUT', 'svg', 'path'].includes(e.target.nodeName) && this.props.variant === 'input') return
		this.setState({expanded: !expanded, flipped: false}, () => {
			if (this.props.variant === 'input') {
				if (expanded) this.inputRef.current.blur()
				else this.inputRef.current.focus()
			}
		})
	}

	onClickAway = () => this.setState({expanded: false, flipped: false})

	itemToString = (defaultValue, item) => {
		let inputValue = item.content
		if (typeof(item.content) !== 'string') {
			if (item.string) inputValue = item.string
			else inputValue = defaultValue
		}
		return inputValue
	}

	onSelect = (event, index) => {
		let { items, variant } = this.props

		let item			= items[index]
		let selectedValues	= this.state.selectedValues

		if (item.notSelectable) {
			if (item.onClick) item.onClick()
			return
		}

		let itemValue = item.value
		if (variant === 'multiple') {
			if (selectedValues.includes(itemValue)) selectedValues = selectedValues.filter(v => v !== itemValue)
			else selectedValues.push(itemValue)
		} else {
			selectedValues = [itemValue]
		}

		if (!this.props.controlable) {
			let inputValue = this.itemToString(event.target.innerText, item)
			this.setState({selectedValues: [...selectedValues], inputValue})
		}
		if (variant !== 'multiple') this.setState({expanded: false})

		if (this.props.onChange) {
			let selectedItems = items.filter(item => selectedValues.includes(item.value))
			if (variant === 'multiple') this.props.onChange(selectedItems, index)
			else this.props.onChange(item, index)
		}
	}

	onInputChange = inputValue => {
		this.setState({inputValue})
		if (!inputValue) this.setState({selectedValues: []})
	}

	renderButtonContent = () => {
		const { classes, items, label, inputId } = this.props
		let selectedValues	= this.state.selectedValues
		let selectedItems	= items.filter(i => selectedValues.includes(i.value))
		let hasLabelClass	= label ? 'has-label' : ''
		let idProps			= inputId ? {id: inputId} : {}

		if (!selectedValues.length) return null
		let renderValue = this.props.renderValue
		let shownElementValues = this.state.shownElementValues
		let notShownItems = selectedValues.length - shownElementValues.length
		let onlyShownClass = selectedItems.length === 1 || selectedItems[1] && !shownElementValues.includes(`${selectedItems[1].value}`) ? 'only-shown' : ''

		return (
			<div ref={this.buttonContentRef} className={`content ${hasLabelClass}`} {...idProps}>
				{renderValue ? renderValue(selectedItems) :
					selectedItems.map((selectedItem, i) => {
						return shownElementValues.includes(`${selectedItem.value}`) ? (
							<span className={onlyShownClass} key={i}>
								{selectedItem.content}
								{selectedItems.length - 1 > i ? ', ' : ''}
							</span>
						) : null
					})
				}
				{(!renderValue && notShownItems) ? <span>+{notShownItems}</span> : null}
				<div className={`${classes.calculationDiv} calculation-div`}>
					{selectedItems.map((selectedItem, i) => {
						return (
							<span className={`menu-value-${selectedItem.value}`} key={i}>
								{selectedItem.content}
								{selectedItems.length - 1 > i ? ', ' : ''}
							</span>
						)
					})}
				</div>
			</div>
		)
	}

	getMenuButtonContent = () => {
		let variant = this.props.variant
		let content = variant === 'input' ? this.state.inputValue : this.renderButtonContent()
		return content
	}

	renderButton = () => {
		const { label, error, variant, inputId } = this.props
		let expanded	= this.state.expanded
		let content		= this.getMenuButtonContent()
		let idProps		= inputId ? {id: inputId} : {}
		return (
			<TextField
				inputRef		= {this.inputRef}
				fullWidth		= {true}
				flipped			= {this.state.flipped}
				label			= {label}
				active			= {expanded}
				error			= {error}
				content			= {content}
				boxShadow		= {true}
				editable		= {variant === 'input'}
				showExpandIcon	= {variant !== 'input'}
				onClick			= {this.toggle}
				onInputChange	= {this.onInputChange}
				{...idProps}
			/>
		)
	}

	renderCheckbox = (selected, item, index) => {
		let { classes, variant, selectionStyle } = this.props
		if (!selectionStyle) selectionStyle = 'checkbox'
		if (variant !== 'multiple' || selectionStyle !== 'checkbox') return null
		return (
			<SelectionControl
				variant			= 'checkbox'
				checked			= {selected}
				name			= {`mic-${index}}`}
				value			= {`mic-${item.value}`}
				disableHover	= {true}
				onClick			= {() => {}}
				className		= {{wrapper: classes.checkbox}}
			/>
		)
	}

	render() {
		let { classes, items, variant, selectionStyle, className, fullWidth } = this.props
		if (!selectionStyle) selectionStyle = 'checkbox'
		let wrapperClassNames			= classes.menuWrapper
		let menuItemsWrapperClassNames	= this.state.flipped ? 'menu-items-wrapper-flipped' : 'menu-items-wrapper';
		let multyCheckClass				= (variant === 'multiple' && selectionStyle === 'checkbox') ? 'multi-check' : ''
		let menuItemClassNames			= `menu-item ${multyCheckClass}`
		if (fullWidth) wrapperClassNames += ' full-width'
		if (className) {
			if (className.wrapper)		wrapperClassNames			+= ` ${className.wrapper}`
			if (className.itemsWrapper)	menuItemsWrapperClassNames	+= ` ${className.itemsWrapper}`
			if (className.item)			menuItemClassNames			+= ` ${className.item}`
		}

		return (
			<ClickAwayListener onClickAway={this.onClickAway}>
				<div data-select='select' ref={this.wrapperRef} className={wrapperClassNames}>
					{this.renderButton()}
					{this.state.expanded ?
						<div ref={this.itemsWrapperRef} className={menuItemsWrapperClassNames} style={{top: this.state.menuOffsetTop}}>
							{items.map((item, i) => {
								if(!item || !item.content) return
								let inputValueLowerCase	= this.state.inputValue.toLowerCase()
								let itemTextLowerCase	= this.itemToString('', item).toLowerCase()
								if (variant === 'input' && !itemTextLowerCase.includes(inputValueLowerCase)) return null
								let selectedValues	= this.state.selectedValues
								let isSelected		= selectedValues.includes(item.value)
								let selectedClass	= isSelected ? 'selected' : ''
								return (
									<div
										data-menu-item	= {item.content}
										value			= {item.value}
										key				= {i}
										className		= {`${menuItemClassNames} ${selectedClass}`}
										onClick			= {e => this.onSelect(e, i)}
									>{this.renderCheckbox(isSelected, item, i)}{item.content}</div>
								)
							})}
						</div>
					: null}
				</div>
			</ClickAwayListener>
		)
	}
}

export default withStyles(styles)(Menu)