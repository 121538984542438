import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'

const api_base = process.env.REACT_APP_USER_API_URL
const number_manager_base = process.env.REACT_APP_NUMBER_MANAGER_API_URL

class API {
	static loadUsers = async (page_size, offset) => {
		let account_id = PhoneComUser.getAPIAccountId()
		let requestUrl	= `${api_base}/voip/${account_id}/users?page_size=${page_size}&offset=${offset}&order_by=status.asc&include=extension,plan,add_ons,devices,numbers`
		let response	= await ajax.get(requestUrl)
		if (response.response && response.response.status >= 400) {
			return response.response.data
		}

		try {
			return response.data ? response.data : {error: true}
		} catch (err) {
			console.error('Error calling invite users endpoint', err)
			return {error: true}
		}
	}

	static inviteUsers = async (users) => {
		let account_id	= PhoneComUser.getAPIAccountId()
		let requestUrl	= `${api_base}/voip/${account_id}/users`

		// Since Ajax.js::Ajax.onError() RETURNs a caught axios error, rather than
		// re-THROWing it, this will never throw -- but response may be an error
		// object. Instead of using try / catch  (which won't catch the _returned_
		// error) we'll need to inspect the object to see if it's an error or not
		let response	= await ajax.post(requestUrl, users)
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload

			return response.response.data
		}

		try {
			return response.data ? response.data : {error: true}
		} catch (err) {
			console.error('Error calling invite users endpoint', err)
			return {error: true}
		}
	}

	static resendInvite = async (user_id) => {
		let account_id	= PhoneComUser.getAPIAccountId()
		let requestUrl	= `${api_base}/voip/${account_id}/users/${user_id}/invitation`

		// Since Ajax.js::Ajax.onError() RETURNs a caught axios error, rather than
		// re-THROWing it, this will never throw -- but response may be an error
		// object. Instead of using try / catch  (which won't catch the _returned_
		// error) we'll need to inspect the object to see if it's an error or not
		let response	= await ajax.post(requestUrl, {})
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			
			return response.response.data
		}
		try {
			return response.data ? response.data : {error: true}
		} catch (err) {
			console.error('Error calling resend invite endpoint', err)
			return {error: true}
		}
	}

	
	
	static updateUser = async (user) => {
		let account_id = PhoneComUser.getAPIAccountId()
		let requestUrl	= `${api_base}/voip/${account_id}/users`
		let response	= await ajax.put(requestUrl, [user])
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			
			return response.response.data
		}
		try {
			return response.data ? response.data : {error: true}
		} catch (err) {
			console.error('Error calling reset password endpoint', err)
			return {error: true}
		}
		
	}

	static resetUserPassword = async (id) => {
		let account_id = PhoneComUser.getAPIAccountId()
		let requestUrl	= `${api_base}/voip/${account_id}/users/${id}/resetpassword`
		let response	= await ajax.post(requestUrl, {})
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			
			return response.response.data
		}

		try {
			return response.data ? response.data : {error: true}
		} catch (err) {
			return {error: true}
		}
	}
	
	static deleteUser = async (id) => {
		let account_id = PhoneComUser.getAPIAccountId()
		let requestUrl	= `${api_base}/voip/${account_id}/users/${id}`
		let response	= await ajax.delete(requestUrl, {})
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			
			return response.response.data
		}

		try {
			return response.data ? response.data : {error: true}
		} catch (err) {
			console.error('Error calling delete users endpoint', err)
			return {error: true}
		}
	}

	static getUsersStats = async () => {
		let account_id = PhoneComUser.getAPIAccountId()
		// let requestUrl	= `${PhoneComUser.getv5ApiRoot()}`
		let requestUrl	= `${api_base}/voip/${account_id}/users/statistics`
		let response	= await ajax.get(requestUrl)
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			
			return response.response.data
		}

		try {
			return response.data ? response.data : {error: true}
		} catch (err) {
			console.error('Error calling statistics endpoint', err)
			return {error: true}
		}
	}

	static listUnassignedNumbers = async () => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/phone-numbers/list-phone-numbers`
		let response = await ajax.post(requestUrl, {filters: {'route-type': 'no-route'}})
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			
			return response.response.data
		}

		try {
			return response.data ? response.data : {error: true}
		} catch (err) {
			console.error('Error calling list-phone-numbers endpoint', err)
			return {error: true}
		}
	}

	static findAvailableNumbers = async (area_code=null, limit=9) => {
		// let requestUrl		= `${PhoneComUser.getv5ApiRoot()}/numbers/get-available-numbers`
		let requestUrl	= `${number_manager_base}/get-available-numbers`
		let payload = {limit, area_code}
		let response = await ajax.postAccount(requestUrl, payload)
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			
			return response.response.data
		}

		try {
			return response.data ? response.data : {error: true}
		} catch (err) {
			console.error('Error calling get-available-number endpoint', err)
			return {error: true}
		}
	}
	static addNumberToAccountAndAssignToUser = async (numberId, userId) => {
		// let requestUrl		= `${PhoneComUser.getv5ApiRoot()}/numbers/add-available-number`
		let requestUrl	= `${number_manager_base}/add-available-number`
		let payload = {search_result_id: numberId, voip_user_id: userId}
		let response = await ajax.postAccount(requestUrl, payload)
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			
			return response.response.data
		}

		try {
			return response.data ? response.data : {error: true}
		} catch (err) {
			console.error('Error calling add-available-number endpoint', err)
			return {error: true}
		}
	}	

	static assignNumberToExtension = async (number, voip_phone_id) => {
		let requestUrl		= `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/configure-calling`
		let payload = {
			voip_did_id:			number.voip_did_id,
			voip_phone_ids:			[voip_phone_id],
			sms_forward_user_id:	voip_phone_id,
			mode:					'ring-users',
		}
		let response = await ajax.post(requestUrl, payload)
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			
			return response.response.data
		}

		try {
			return response.data ? response.data : {error: true}
		} catch (err) {
			console.error('Error calling configure-calling endpoint', err)
			return {error: true}
		}
	}	

	static unassignNumber = async (voip_did_id) => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/configure-calling`
		let response = await ajax.post(requestUrl, {mode: 'unassign', voip_did_id: voip_did_id})
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			
			return response.response.data
		}
		try {
			return response.data ? response.data : {error: true}
		} catch (err) {
			console.error('Error calling configure-calling endpoint', err)
			return {error: true}
		}
	}

	static updatePhoneNumberName = async (id, name) => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/phone-numbers/update-phone-number`
		return await ajax.post(requestUrl, {id, name})
	}

	static addMeet = async (email, extension_id, plan_code, name) => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/unified/video/add-user`
		let account_id = PhoneComUser.getAPIAccountId()
		let payload = {email, account_id, extension_id, plan_code, name}
		
		let response = await ajax.post(requestUrl, payload)
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			
			return response.response.data
		}
		try {
			return response.data ? response.data : {error: true}
		} catch (err) {
			console.error('Error calling add-user endpoint', err)
			return {error: true}
		}
	}


		
	static uploadAvatar = async (user, base64image) => {
		let account_id = PhoneComUser.getAPIAccountId()
		let requestUrl	= `${api_base}/voip/${account_id}/users/${user.id}/avatar`
		let response	= await ajax.put(requestUrl, {image: base64image})
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			
			return response.response.data
		}
		try {
			return response.data ? response.data : {error: true}
		} catch (err) {
			console.error('Error calling upload-avatar endpoint', err)
			return {error: true}
		}
	}

	static getExpressServiceCode = async () => {
		let base_url = PhoneComUser.getv4ApiRoot()
		let account_id = PhoneComUser.getAPIAccountId()
		let extension_id = PhoneComUser.getExtensionId()

        let url = `${base_url}/accounts/${account_id}/extensions/${extension_id}/express-service-codes?modes=full`
		let response = await ajax.get(url)

		if (response && response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			return response.data
		}
		try {
		    return response.data.items.pop()['express_service_code']
		} catch (err) {
			return ''
		}
	}

	static setCallForwarding = async (phoneNumber, extensionId) => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/configure-device-calling`
		let data = {
			device_type:	'web',
			call_mode:		'voip',
			caller_id_mode:	'calling_number',
			phone_number:	{phone_number: phoneNumber, screening: true}, // Screening should be set to true by default
			extension_id:	extensionId
		}
		let response = await ajax.post(requestUrl, data)
		return response
	}

	static removeNumberForwarding = async (phoneNumber, extensionId) => {
		let requestUrl	= `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/remove-number-forwarding`
		let data		= { phone_number: phoneNumber, extension_id: extensionId }
		let response	= await ajax.post(requestUrl, data)
		return response
	}

	static getExtension = async extension_id => {
		let voip_id		= PhoneComUser.getAPIAccountId()
		let extensionId	= extension_id || parseInt(PhoneComUser.getExtensionId())
		let base_url	= 'https://api.phone.com/v4'
		let requestUrl	= `${base_url}/accounts/${voip_id}/extensions/${extensionId}`
		let response	= await ajax.get(requestUrl, 'Bearer')
		return response.data
	}
}


export default API