import React, { Component } from "react"
import Messages from "messages"
import People from "people"
import Meetings from "meetings"
import Voicemail from "voicemail"
import Settings from "settings"
import Call from "calls"
import Faxes from "faxes"
import PersonalSettings from "personal-settings"
import Navigation from "./src/nav/Navigation"
import AppLoader from "./src/AppLoader.js"
import api from "./src/util/api"
import Api from "api"
import {
	loadContacts,
	loadMore,
	addGroup,
	updateGroupId,
	loadExtraContacts,
	updateExtraContacts,
	updateContact,
	deleteContact,
} from "./src/util/contacts"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { ThemeProvider } from "@material-ui/styles"
import FirstTimeUserInfoPopupContent from "./src/temp/FirstTimeUserInfoPopupContent.js"
import OfflineBar from "./src/OfflineBar.js"
import CSRBar from "./src/CSRBar.js"
import NotificationBar from "./src/NotificationBar.js"
import CallBar from "./src/CallBar.js"

import { initializePhoneCom, getAppConfig } from "phonecom"
import InfoPopup from "info-popup"
import PDCOpenConnection from "pdc-open-connection"
import {
	pushMessageNotification,
	pushVoicemailNotification,
	pushFaxNotification,
	pushCallNotification,
} from "notification-pusher"
import { sendElectronNotification, electronNotificationListener, addElectronEventListener } from "pdc-electron-utils"
import PhoneComUser from "phone-com-user"
import { theme } from "get-theme"
import { withStyles } from "@material-ui/core"
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import API from "calls/src/util/api_v5"
import CallProvider, { PdcCallConsumer } from "../../pages/communicator-app/src/PdcCallProvider"
import { CallState } from "../../pages/communicator-app/src/enums/CallState"
import Prompt from "pdc-prompt"
import { isUpdateAvailable } from "service-worker-utils"
import IncomingCallModal from "./src/IncomingCallModal"
const themeFontFamily = theme.fontFamily || "Montserrat, Helvetica, arial, sans-serif"

const styles = (theme) => ({
	mainDiv: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		"&.inactive": {
			opacity: 0.9,
		},
		"& *": {
			fontFamily: themeFontFamily,
		},
	},
	csrBar: {
		height: "32px",
	},
})

const INACTIVITY_PERIOD = 30 * 1000 // half minute

class Communicator extends Component {
	constructor(props) {
		super(props)
		this.infoPopupContent = <FirstTimeUserInfoPopupContent />
		this.state = {
			focused: document.hasFocus(),
			userActive: false,
			selectedExtension: null,
			contactsInfo: {
				contacts: null,
				extraContacts: [],
				groupTypes: [],
				contactsLoaded: false,
				extraContactsLoaded: false,
			},
			loadingContacts: false,
			loading: true,
			appLoading: true,
			screenViewType: {
				isMobileView: false,
				isTabletView: false,
			},
			unreadMessages: null,
			showNotificationBar: false,
			currentAppName: "calls",
			appData: null,
			isOffline: false,
			isDialerOpen: false,
			hideCallBar: true,
			waitingSW: null,
			triedCallWithoutMicPermissions: false,
			appHasChange: false,
			placingCallError: false
		}

		this.notificationSubscriptions = {
			messages: [this.setUnreadCounts.bind(this, null)],
			voicemail: [this.setUnreadCounts.bind(this, null)],
			faxes: [this.setUnreadCounts.bind(this, null)],
			read_status: [this.setUnreadCounts.bind(this, null)], // This is about messages
		}
		PDCOpenConnection.onReconnect((retryingConnection) => {
			if (!retryingConnection) {
				// window.location.reload();
			}
		})
	}

	setUnreadCounts = (extensionId) => {
		let ext = extensionId || this.state.selectedExtension.extension_id
		api.getUnreadCounts(ext).then((res) => {
			if (!res || !res.items) return
			let unread = res.items[0]
			this.setState({
				unreadMessages: unread.messages,
				unreadVoicemails: unread.voicemails,
				unreadFaxes: unread.faxes,
			})
			this.sendElectronUnreadCounts(unread)
		})
	}

	changeMessageReadStatus = (type, howMany) => {
		if (howMany === "all") {
			this.setState({ unreadMessages: 0 })
		} else if (type === "read") {
			this.setState({ unreadMessages: this.state.unreadMessages - howMany })
		} else if (type === "unread") {
			this.setState({ unreadMessages: this.state.unreadMessages + howMany })
		} else {
			console.error("Invalid type for changing message read status")
		}
	}

	changeVoicemailReadStatus = (type, howMany) => {
		if (howMany === "all") {
			this.setState({ unreadVoicemails: 0 })
		} else if (type === "read") {
			this.setState({ unreadVoicemails: this.state.unreadVoicemails - howMany })
		} else if (type === "unread") {
			this.setState({ unreadVoicemails: this.state.unreadVoicemails + howMany })
		} else {
			console.error("Invalid type for changing voicemail read status")
		}
	}

	changeFaxReadStatus = (type, howMany) => {
		if (howMany === "all") {
			this.setState({ unreadFaxes: 0 })
		} else if (type === "read") {
			this.setState({ unreadFaxes: this.state.unreadFaxes - howMany })
		} else if (type === "unread") {
			this.setState({ unreadFaxes: this.state.unreadFaxes + howMany })
		} else {
			console.error("Invalid type for changing fax read status")
		}
	}

	setScreenView() {
		let window_size = window.innerWidth
		console.log(this.props.theme.screenViewSizes.mobileViewSize)
		if (
			this.props.theme.screenViewSizes.mobileViewSize < window_size &&
			window_size < this.props.theme.screenViewSizes.tabletViewSize
		) {
			this.updateScreenViewState({
				screenViewType: {
					isTabletView: true,
					isMobileView: false,
				},
			})
		} else if (window_size < this.props.theme.screenViewSizes.mobileViewSize) {
			console.log("mobile")

			this.updateScreenViewState({
				screenViewType: {
					isTabletView: false,
					isMobileView: true,
				},
			})
		} else {
			this.updateScreenViewState({
				screenViewType: {
					isTabletView: false,
					isMobileView: false,
				},
			})
		}
	}

	updateScreenViewState(screenViewState) {
		let currentScreenViewState = this.state.screenViewType

		if (
			screenViewState.screenViewType.isMobileView !== currentScreenViewState.isMobileView ||
			screenViewState.screenViewType.isTabletView !== currentScreenViewState.isTabletView
		) {
			console.log("updating")
			this.setState(screenViewState)
		}
	}

	componentWillMount() {
		this.setScreenView()
		window.addEventListener("resize", () => this.setScreenView())

		addElectronEventListener("deeplink-event", ({ action, payload }) => {
			switch (action) {
				case "makeCall":
					return this.makeCall(payload.toNumber)
				case "sendMessage":
					return this.sendMessage(payload.toNumber, payload.text)
				case "startMessage":
				// return this.startMessage(data.toNumber)
			}
		})

		window.ononline = this.onOnline
		window.onoffline = this.onOffline
		if (window.navigator && !window.navigator.onLine) {
			this.onOffline()
		}
	}

	sendMessage = (toNumber = null, text = null) => {
		console.log(`Should send message to ${toNumber}:`, text)
	}

	startMessage = () => {
		console.log(`Start message `)
	}

	onOnline = (e) => {
		let isOffline = this.state.isOffline
		if (!isOffline) return
		return window.location.reload()
		setTimeout(() => {
			loadContacts(this)
			this.setUnreadCounts()
			this.setState({ isOffline: false })
		}, 2000)
	}
	onOffline = (e) => this.setState({ isOffline: Date.now() })

	componentDidMount = () => {
		this.focusHandlers()
		this.init()
	}

	focusHandlers = () => {
		let that = this
		window.onfocus = function () {
			that.setState({ focused: true })
		}
		window.onblur = function () {
			that.setState({ focused: false })
		}
		window.onmousemove = function () {
			if (!that.state.focused) return
			clearTimeout(that.notMovingTimeout)
			if (that.state.userActive !== true) {
				that.setState({ userActive: true })
			}
			that.notMovingTimeout = setTimeout(function () {
				that.setState({ userActive: false })
			}, INACTIVITY_PERIOD)
		}
	}

	initNotifications = () => {
		let onVoicemailNotification = (notification) => {
			let voicemail = notification
			let extensionId = this.state.selectedExtension.extension_id

			if (!["read_status", "delete"].includes(voicemail.type)) pushVoicemailNotification(voicemail, extensionId)

			this.notificationSubscriptions.voicemail.forEach((subscription) => subscription(notification))
		}

		let onFaxNotification = (notification) => {
			let fax = notification
			let extensionId = this.state.selectedExtension.extension_id

			if (!["read_status", "delivery_status", "delete"].includes(fax.type)) pushFaxNotification(fax, extensionId)

			this.notificationSubscriptions.faxes.forEach((subscription) => subscription(notification))
		}

		let onMessageNotification = (notification) => {
			let message = notification
			let extensionId = this.state.selectedExtension.extension_id

			if (!["read_status", "delete"].includes(message.type)) message = message.details
			if (message.type !== "read_status") pushMessageNotification(message, extensionId)

			this.notificationSubscriptions.messages.forEach((subscription) => subscription(notification))
		}

		let onReadStatusNotification = (notification) => {
			this.notificationSubscriptions.read_status.forEach((subscription) => subscription(notification))
		}

		PDCOpenConnection.on("voicemail", onVoicemailNotification)
		PDCOpenConnection.on("fax", onFaxNotification)
		PDCOpenConnection.on("messages", onMessageNotification)
		PDCOpenConnection.on("read_status", onReadStatusNotification)
	}

	async intWebRTCCalling() {
		console.log(PdcCallConsumer)
		console.log(this.context)
		await this.props.connect()

		API.configureCallListeners(PhoneComUser.getAPIAccountId())

		// window.onbeforeunload = (e) => {
		// 	// the absence of a returnValue property on the event will guarantee the browser unload happens
		// 	e.preventDefault()

		// 	// 	//only show alert if there is a call incoming or active.
		// 	// 	if (window.pdcCall.call.callState !== null) {
		// 	// 		return 'Are you sure you want to leave the page with an active call?'
		// 	// 	}
		// }
		window.addEventListener("beforeunload", (e) => {
			if (this.props.currentCall || this.props.backgroundCalls.length > 0) {
				console.log("hitting event listener")
				e.preventDefault()
				e.returnValue = ""
			}
		})
	}

	resetSubscription = (isForce) => {
		if (!PDCOpenConnection.connected) PDCOpenConnection.hardReset()
	}

	subscribeForNotifications = (type, callback, reinitialize = false) => {
		if (type === "messages") {
			if (reinitialize) this.notificationSubscriptions.messages = [this.setUnreadCounts.bind(this, null)]
			this.notificationSubscriptions.messages.push(callback)
		} else if (type === "voicemail") {
			if (reinitialize) this.notificationSubscriptions.voicemail = [this.setUnreadCounts.bind(this, null)]
			this.notificationSubscriptions.voicemail.push(callback)
		} else if (type === "fax") {
			if (reinitialize) this.notificationSubscriptions.faxes = [this.setUnreadCounts.bind(this, null)]
			this.notificationSubscriptions.faxes.push(callback)
		} else if (type === "read_status") {
			this.notificationSubscriptions.read_status.push(callback)
		}
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (this.state.userInfo && !this.state.selectedExtension) {
			let phoneNumbers = Object.keys(this.state.userInfo.extension.phone_number)
			PhoneComUser.changePhoneNumber(phoneNumbers)
			let selectedExtension = this.state.userInfo.extension
			selectedExtension.userId = this.state.userInfo.user_id
			this.setState({ selectedExtension })
		}
		this.fixUrlPath()
		this.setFavicon()
	}

	setFavicon = () => {
		if (this.state.unreadMessages || this.state.unreadVoicemails || this.state.unreadFaxes) {
			if (document.getElementsByName("favicon")[0].href !== theme.favicon.unread) {
				document.getElementsByName("favicon")[0].href = theme.favicon.unread
			}
		} else {
			if (document.getElementsByName("favicon")[0].href !== theme.favicon.default) {
				document.getElementsByName("favicon")[0].href = theme.favicon.default
			}
		}
		if (document.getElementsByName("apple-icon")[0].href !== theme.favicon.default) {
			document.getElementsByName("apple-icon")[0].href = theme.favicon.default
		}
		if (document.getElementsByName("apple-icon")[1].href !== theme.favicon.default) {
			document.getElementsByName("apple-icon")[1].href = theme.favicon.default
		}
	}

	sendElectronUnreadCounts = (unread) => {
		let allUnreadMEssages = unread.messages + unread.voicemails + unread.faxes
		sendElectronNotification("unreadMessages", allUnreadMEssages)
	}

	//todo rework and remove this. This should be in the init phone come util not here
	init = async () => {
		let config = await getAppConfig()
		window.APP_CONFIG = config
		let v5phonecom = {
			stage: config.stage,
			v4ApiRoot: config.v4ApiRoot,
			v5ApiRoot: config.v5ApiRoot,
			v5ToolsRoot: config.v5ToolsRoot,
			redirect_url: config.redirect_url,
		}
		window.V5PHONECOM = v5phonecom
		await this.initialLoad()
		window.dataLayer.push({ PDC_voip_id: window.V5PHONECOM.voip_phone_id })
		electronNotificationListener()
		// Check if safari

		window.safari =
			navigator.vendor &&
			navigator.vendor.indexOf("Apple") > -1 &&
			navigator.userAgent &&
			navigator.userAgent.indexOf("CriOS") == -1 &&
			navigator.userAgent.indexOf("FxiOS") == -1
		window.ios =
			["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
				navigator.platform
			) ||
			// iPad on iOS 13 detection
			(navigator.userAgent.includes("Mac") && "ontouchend" in document)
		this.setState({ safariPrompt: window.safari })
	}

	initialLoad = async (config) => {
		let [res, companyExtension] = await Promise.all([
			await initializePhoneCom(config),
			await Api.getCompanyExtension(),
		])
		if (!res) return
		if (window.Rollbar) {
			window.Rollbar.configure({
				payload: {
					person: {
						id: res.account_id, // required
						voip_id: res.account_id,
						voip_phone_id: res.extension_id,
						account_name: res.account.name,
					},
					fullstory_url:
						window.FS && typeof window.FS.getCurrentSessionURL === "function"
							? window.FS.getCurrentSessionURL(true)
							: null,
				},
			})
		}

		if (res.user_id) {
			if (window.userpilot) {
				window.userpilot.identify(res.user_id, {
					// Unique ID of each user in your database (e.g. 23443 or "590b80e5f433ea81b96c9bf6")
					email: res.email, // Used to connect data coming from various integrations
					name: `${res.first_name} ${res.last_name}`, // We will parse this to extra first and surnames (e.g. "James Doe")
					role: res.role, // Send properties useful for targeting types of users (e.g. "Admin")
					project: "mypdc-user", // Send any unique data for a user that might appear in any page URLs (e.g. 09876 or "12a34b56")
				})
				console.log("user identified")
			}
		} else if (res.extension_id) {
			if (window.userpilot) {
				window.userpilot.identify(`v${res.extension_id}`, {
					// Unique ID of each user in your database (e.g. 23443 or "590b80e5f433ea81b96c9bf6")
					role: res.role, // Send properties useful for targeting types of users (e.g. "Admin")
					project: "mypdc-legacy", // Send any unique data for a user that might appear in any page URLs (e.g. 09876 or "12a34b56")
					meta: {
						account_id: res.account_id,
						extension_id: res.extension_id,
					},
				})
				console.log("extension identified")
			}
		}

		res.extensions.forEach((extension) => {
			if (extension.extension_id === companyExtension.id) {
				extension.company_name = companyExtension.company_name
				extension.is_company = true
				extension.is_virtual = false
			}
		})

		console.log(`APP_CONFIG: ${JSON.stringify(window.APP_CONFIG)}`)
		console.log("ok we're ready!")

		if (res) {
			this.setSelectedExtensionFromUrl(res)
			this.fixUrlPath(res.extension.extension_id)
		}

		//added a 5sec timeout for the loader cover, incase the the app does not do a call back for when it is done loading
		setTimeout(() => {
			if (!this.state.appLoading) return
			this.setState({ appLoading: false })
			this.checkShowNotificaitionBar()
		}, 5000)

		this.setUnreadCounts(res.extension.extension_id)

		let phoneNumbers = Object.keys(res.extension.phone_number)
		PhoneComUser.changePhoneNumber(phoneNumbers)
		let selectedExtension = res.extension
		selectedExtension.userId = res.user_id
		if (res.user_id) window.name = `my.phone.com-${selectedExtension.extension_id}`
		this.setState({
			loading: false,
			userInfo: res,
			selectedExtension,
		})
		this.initNotifications()
		loadContacts(this)
		if (
			(!process.env.REACT_APP_IS_CALLING_DISABLED && !window.safari) ||
			(window.V5PHONECOM && window.V5PHONECOM.features.has("calling_enabled"))
		) {
			console.log("hit" + process.env.REACT_APP_IS_CALLING_DISABLED)
			console.log(process.env)

			this.intWebRTCCalling()
		}
		if (navigator.serviceWorker) {
			isUpdateAvailable((registration) => {
				console.log(registration)
				this.setState({ waitingSW: registration.waiting })
			})
		}
	}

	setSelectedExtensionFromUrl = (res) => {
		let pathname = window.location.pathname
		let pathnameSplit = pathname.split("/")
		pathnameSplit.shift()
		if (pathnameSplit.length === 0 || !this.isExtensionPathElement(pathnameSplit[0])) return
		let extensionId = parseInt(pathnameSplit[0].substring(1))
		let extensionElement = res.extensions.find((e) => e.extension_id === extensionId)
		if (!extensionElement) {
			return window.history.replaceState(
				"Extension Id",
				"Reset the extension id to default",
				`/e${res.extension_id}`
			)
		}
		res.extension = JSON.parse(JSON.stringify(extensionElement))
		res.extension_id = extensionElement.extension_id
	}

	isExtensionPathElement = (s) => {
		return s[0] === "e" && !isNaN(s.substring(1))
	}

	fixUrlPath = (extension_id=null) => {
		if (!this.state.userInfo) return
		let ext_id = extension_id || this.state.userInfo.extension_id
		let pathname = window.location.pathname
		let pathnameSplit = pathname.split("/").filter((e) => e)

		if (pathnameSplit.length === 0 || !this.isExtensionPathElement(pathnameSplit[0])) {
			pathname = `/e${ext_id}/${pathnameSplit.join("/")}`
		}

		if ([0, 1].includes(pathnameSplit.length)) {
			if (pathname[pathname.length - 1] !== "/") pathname += "/"
			pathname += theme.defaultRoute
		}

		let currentPathname = window.location.pathname
		if (currentPathname !== pathname) {
			window.history.replaceState("Extension Id", "Add missing extension id", pathname)
		}
	}

	getAppsHeight = () => `calc(100% - ${this.mobileAppBarSpacer()})`

	mobileAppBarSpacer() {
		if (this.state.screenViewType.isMobileView || this.state.screenViewType.isTabletView) {
			return `${this.props.theme.appBarHeight}px`
		}
		return "0px"
	}

	onExtensionSwitch = (extension) => {
		let userInfo = this.state.userInfo
		let extensionElement = userInfo.extensions.find((e) => e.extension_id === extension.extension_id)
		userInfo.extension = extensionElement
		userInfo.extension_id = extensionElement.extension_id

		let phoneNumbers = Object.keys(extension.phone_number)
		PhoneComUser.changePhoneNumber(phoneNumbers)

		let pathnameSplit = window.location.pathname.split("/").filter((e) => e)
		pathnameSplit.shift()
		let path = pathnameSplit[0] || "" // .join('/')
		window.history.replaceState("Extension", "Switched extension", `/e${extensionElement.extension_id}/${path}/`)
		window.location.reload()

		// this.setState({selectedExtension: extension, userInfo})
		// this.setUnreadCounts(extension.extension_id)
		// PDCOpenConnection.hardReset()
	}

	redirect = (redirectPath) => this.setState({ redirectPath })

	logout = () => {
		this.setState({ userInfo: null })

		window.APP_CONFIG.cp_session_id = ""
		window.V5PHONECOM.cp_token = ""

		// TODO: Redirect to login
	}

	onAppLoaded = () => {
		if (this.state.appLoading) this.checkShowNotificaitionBar()
		this.setState({ appLoading: false })
	}

	getCookie = (cname) => {
		let name = cname + "="
		let decodedCookie = decodeURIComponent(document.cookie)
		let ca = decodedCookie.split(";")
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i]
			while (c.charAt(0) === " ") {
				c = c.substring(1)
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length)
			}
		}
		return ""
	}

	checkShowNotificaitionBar = () => {
		let cookieValue = this.getCookie("mpdcdafn")
		let enabledNotificationBarShow =
			cookieValue !== "1" && window.Notification && Notification.permission === "default"
		return enabledNotificationBarShow ? this.setState({ showNotificationBar: true }) : null
	}

	hideNotificationBar = () => this.setState({ showNotificationBar: false })

	returnApp = (currentApp, currentAppName) => {
		if (this.state.currentAppName !== currentAppName) {
			// If it is only this.setState({currentAppName}) then we get react error that cannot set state from within render method
			setTimeout(() => this.setState({ currentAppName }), 0)
		}
		return currentApp
	}

	unsetRedirectPath = () => this.setState({ redirectPath: null })

	getContactsUtil = () => {
		return {
			contacts: this.state.contactsInfo.contacts,
			extraContacts: this.state.contactsInfo.extraContacts,
			groupTypes: this.state.contactsInfo.groupTypes,
			contactsLoaded: this.state.contactsInfo.contactsLoaded,
			extraContactsLoaded: this.state.contactsInfo.extraContactsLoaded,
			addGroup: addGroup.bind(this, this),
			updateGroupId: updateGroupId.bind(this, this),
			reload: () => loadContacts(this),
			loadMore: () => loadMore(this),
			getContactsApi: Api.loadContacts,
			loadExtraContacts: loadExtraContacts.bind(this, this),
			updateExtraContacts: updateExtraContacts.bind(this, this),
			deleteContact: deleteContact.bind(this, this),
			updateContact: updateContact.bind(this, this),
		}
	}

	goTo = (app, appData) => {
		this.redirect(`/e${this.state.userInfo.extension_id}/${app}`)
		let randomString = `${Math.floor(Math.random() * 1000000)}${Date.now()}`
		appData.randomString = randomString
		appData.onProcessed = () => this.setState({ appData: null })
		this.setState({ appData })
	}

	setIsDialerOpen = (isDialerOpen) => this.setState({ isDialerOpen })

	openDialer = () => {
		this.goTo("calls", { view: "dialer" })
	}

	openMakeACall = () => {
		this.goTo("calls", { view: "make_call" })
	}

	openCallLogList = () => {
		this.goTo("calls", { view: "select" })
	}

	makeCall = async (calleeNumber, callerId) => {
		try {
			this.setState({ triedCallWithoutMicPermissions: true })
			await this.props.call(calleeNumber, callerId)
		} catch (error) {
			console.log("make call, error caught", error)
			this.setState({placingCallError: true})
		}
	}

	answerById = async (callId) => {
		console.log("answer", callId)
		try {
			this.setState({ triedCallWithoutMicPermissions: true })
			await this.props.answerById(callId)
		} catch (error) {
			console.log("answer", error)
		}
	}

	dismissNotification = () => {
		this.setState({ triedCallWithoutMicPermissions: false })

		//TODO: not avail yet. experimental
		// navigator.permissions.request({ name: 'microphone' })
		// .then(permissions => console.log(permissions))
	}

	dismissSafariPrompt = () => {
		this.setState({ safariPrompt: false })
	}

	onUpdatePromptClose = () => {
		this.setState({ waitingSW: null })
	}

	setHasChange = (appHasChange) => this.setState({ appHasChange })
	discardChanges = () => this.setHasChange(false)
	onAudioWarningClose = () => this.setState({ triedCallWithoutMicPermissions: false })
	onPlacingCallErrorClose = () => this.setState({ placingCallError: false })
	render() {
		let { classes } = this.props
		let showLoader = (this.state.loading || this.state.appLoading) && !this.state.isOffline
		// if (!this.state.appLoading) console.log('HIDE WELCOME LOADER') // If you see this log twice that menas that content got loaded after 5 seconds
		let generalData = {
			isOffline: Boolean(this.state.isOffline),
			screenViewType: this.state.screenViewType,
			extension: this.state.selectedExtension,
			appData: this.state.appData,
			focused: this.state.focused,
			userActive: this.state.userActive,
			contactsUtil: this.getContactsUtil(),
			onLoaded: this.onAppLoaded,
			updateUnreadCounts: this.setUnreadCounts,
			resetSubscription: this.resetSubscription,
			subscribeForNotifications: this.subscribeForNotifications,
			redirect: this.redirect,
			makeCall: this.makeCall,
			setHasChange: this.setHasChange,

			openDialer: this.openDialer,
			openMakeACall: this.openMakeACall,
			openCallLogList: this.openCallLogList,
		}

		let callsApp = <Call {...generalData} setIsDialerOpen={this.setIsDialerOpen} />
		let messagesApp = <Messages {...generalData} changeMessageReadStatus={this.changeMessageReadStatus} />
		let voicemailsApp = <Voicemail {...generalData} changeVoicemailReadStatus={this.changeVoicemailReadStatus} />
		let faxesApp = <Faxes {...generalData} changeFaxReadStatus={this.changeFaxReadStatus} />
		let settingsApp = <Settings {...generalData} />
		let meetingsApp = <Meetings {...generalData} />
		let peopleApp = <People {...generalData} />
		let personalSettingsApp = <PersonalSettings {...generalData} />

		let isInactive =
			!process.env.REACT_APP_INACTIVE_DESIGN_DISABLED && (!this.state.focused || !this.state.userActive)

		return (
			<ThemeProvider theme={this.props.theme}>
				<div className={`${classes.mainDiv} ${isInactive ? "inactive" : ""}`}>
					{this.state.isOffline ? <OfflineBar /> : null}
					{this.state.userInfo && this.state.userInfo.csr ? <CSRBar /> : null}
					{this.state.showNotificationBar ? <NotificationBar hideBar={this.hideNotificationBar} /> : null}
					{/* {this.state.userInfo && !this.state.loading ?
						<InfoPopup showOnceIdentifier={'first_my_phone_com'} content={this.infoPopupContent} />
						: null} */}
					<AppLoader hidden={!showLoader} request={"Timing"} />
					{/* Turn this state pass into context */}
					{this.state.userInfo && !this.state.loading && (
						<Router>
							<Navigation
								userInfo={this.state.userInfo}
								screenViewType={this.state.screenViewType}
								currentUser={this.state.selectedExtension}
								unreadMessages={this.state.unreadMessages}
								unreadVoicemails={this.state.unreadVoicemails}
								unreadFaxes={this.state.unreadFaxes}
								currentAppName={this.state.currentAppName}
								appHasChange={this.state.appHasChange}
								onExtensionSwitch={this.onExtensionSwitch}
								logout={this.logout}
								goTo={this.goTo}
								redirect={this.redirect}
								discardChanges={this.discardChanges}
								extension={this.state.selectedExtension}
							>
								<div style={{ height: this.mobileAppBarSpacer() }} />
								<div style={{ height: this.getAppsHeight() }}>
									<Switch>
										<Route
											path={`/e${this.state.userInfo.extension_id}/messages/`}
											render={() => {
												if (this.state.redirectPath) {
													let path = this.state.redirectPath
													this.unsetRedirectPath()
													return <Redirect to={path} />
												}
												return this.returnApp(messagesApp, "messages")
											}}
										/>
										<Route
											path={`/e${this.state.userInfo.extension_id}/voicemail/`}
											render={() => {
												if (this.state.redirectPath) {
													let path = this.state.redirectPath
													this.unsetRedirectPath()
													return <Redirect to={path} />
												}
												return this.returnApp(voicemailsApp, "voicemail")
											}}
										/>
										<Route
											path={`/e${this.state.userInfo.extension_id}/calls/`}
											render={() => {
												if (this.state.redirectPath) {
													let path = this.state.redirectPath
													this.unsetRedirectPath()
													return <Redirect to={path} />
												}
												return this.returnApp(callsApp, "calls")
											}}
										/>
										<Route
											path={`/e${this.state.userInfo.extension_id}/faxes/`}
											render={() => {
												if (this.state.redirectPath) {
													let path = this.state.redirectPath
													this.unsetRedirectPath()
													return <Redirect to={path} />
												}
												return this.returnApp(faxesApp, "faxes")
											}}
										/>
										<Route
											path={`/e${this.state.userInfo.extension_id}/settings/`}
											render={() => {
												if (this.state.redirectPath) {
													let path = this.state.redirectPath
													this.unsetRedirectPath()
													return <Redirect to={path} />
												}
												return this.returnApp(settingsApp, "settings")
											}}
										/>
										<Route
											path={`/e${this.state.userInfo.extension_id}/people/`}
											render={() => {
												if (this.state.redirectPath) {
													let path = this.state.redirectPath
													this.unsetRedirectPath()
													return <Redirect to={path} />
												}
												return this.returnApp(peopleApp, "people")
											}}
										/>
										<Route
											path={`/e${this.state.userInfo.extension_id}/meetings/`}
											render={() => {
												if (this.state.redirectPath) {
													let path = this.state.redirectPath
													this.unsetRedirectPath()
													return <Redirect to={path} />
												}
												return this.returnApp(meetingsApp, "meetings")
											}}
										/>
										<Route
											path={`/e${this.state.userInfo.extension_id}/personal-settings/`}
											render={() => {
												if (this.state.redirectPath) {
													let path = this.state.redirectPath
													this.unsetRedirectPath()
													return <Redirect to={path} />
												}
												return this.returnApp(personalSettingsApp, "personalSettings")
											}}
										/>
										<Route
											path={`/`}
											render={() => {
												let pathnameSplit = window.location.pathname.split("/")
												let appName =
													pathnameSplit.slice(2, 3)[0] || theme.defaultRoute.replace("/", "")
												switch (appName) {
													case "calls":
														return (
															<Redirect
																to={`/e${this.state.userInfo.extension_id}/calls/`}
															/>
														)
													case "messages":
														return (
															<Redirect
																to={`/e${this.state.userInfo.extension_id}/messages/`}
															/>
														)
													case "voicemail":
														return (
															<Redirect
																to={`/e${this.state.userInfo.extension_id}/voicemails/`}
															/>
														)
													case "faxes":
														return (
															<Redirect
																to={`/e${this.state.userInfo.extension_id}/faxes/`}
															/>
														)
													case "settings":
														return (
															<Redirect
																to={`/e${this.state.userInfo.extension_id}/settings/`}
															/>
														)
													case "people":
														return (
															<Redirect
																to={`/e${this.state.userInfo.extension_id}/people/`}
															/>
														)
													case "meetings":
														return (
															<Redirect
																to={`/e${this.state.userInfo.extension_id}/meetings/`}
															/>
														)
													case "personal-settings":
														return (
															<Redirect
																to={`/e${this.state.userInfo.extension_id}/personalSettings/`}
															/>
														)
												}
											}}
										/>
									</Switch>
								</div>
							</Navigation>
						</Router>
					)}
					{!this.state.loading && !process.env.REACT_APP_CALLS_TAB_HIDDEN ? (
						<div>
							<PdcCallConsumer>
								{(context) => (
									<IncomingCallModal
										oldestIncomingCall={context.incomingCalls[0]}
										answerById={this.answerById}
										hangupById={context.hangupById}
										openDialer={this.openDialer}
									/>
								)}
							</PdcCallConsumer>
							{true ? <div></div> : null}
							{true ? <div></div> : null}
							{!this.state.appLoading ? (
								<PdcCallConsumer>
									{(context) => {
										if (
											!this.state.callContext ||
											context.callsCnt !== this.state.callContext.callsCnt
										) {
											this.setState({
												callContext: {
													calls: Object.keys(context.calls || {}),
													callsCnt: JSON.parse(JSON.stringify(context.callsCnt)),
												},
											})
										}
										return (
											<CallBar
												hideCallBar={this.state.isDialerOpen || context.callsCnt < 1}
												isRinging={
													(!context.currentCall && context.incomingCallsCnt > 0) ||
													(context.currentCall &&
														context.currentCall.callState === CallState.CONNECTING)
												}
												isDialerOpen={this.state.isDialerOpen}
												openDialer={this.openDialer}
												openMakeACall={this.openMakeACall}
												setIsDialerOpen={this.setIsDialerOpen}
												screenViewType={this.state.screenViewType}
												answerById={this.answerById}
												hangupById={context.hangupById}
												switchCall={context.switchCall}
												mergeCall={context.mergeCall}
												hold={context.hold}
												unhold={context.unhold}
												getIncomingCalls={context.getIncomingCalls}
												supportsById={context.supportsById}
												callStats={context.callStats}
												calls={context.calls}
												activeCallId={context.activeCallId}
												currentCall={context.currentCall}
												incomingCallsCnt={context.incomingCallsCnt}
												activeCallsCnt={context.activeCallsCnt}
												callsCnt={context.callsCnt}
												backgroundCalls={context.backgroundCalls}
												showCallSessionSelector={
													context.callsCnt > 1 ||
													(!context.activeCallId && context.callsCnt > 0) ||
													(!context.currentCall &&
														Object.values(context.calls).filter(
															(call) => call.callAnswered
														))
												}
												muteLocal={context.muteLocal}
												isMutedLocal={context.isMutedLocal}
												callsOnHold={context.callsOnHold}
												sendDTMF={context.sendDTMF}
											/>
										)
									}}
								</PdcCallConsumer>
							) : null}
						</div>
					) : null}
					<Prompt
						isOpen={this.state.waitingSW}
						color="primary"
						content={
							<div
								onClick={() => {
									this.state.waitingSW.postMessage({ type: "SKIP_WAITING" })
								}}
							>
								<div>Update Available!</div>
								<div>Click Here to Update</div>
							</div>
						}
						position="bottom-left"
						onClose={this.onUpdatePromptClose}
					/>
					<Dialog
						open={this.props.noDeviceFound && this.state.placingCallError}
						onClose={this.onPlacingCallErrorClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">{"Audio Permissions denied"}</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								There was an issue placing your call. 
								You are trying to call from a virtual extension that has not been assigned a device.
								Please contact your administrator to enable calling.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.onPlacingCallErrorClose} color="primary">
								Close
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						open={this.props.deniedAudioPermissions && this.state.triedCallWithoutMicPermissions}
						onClose={this.onAudioWarningClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">{"Audio Permissions denied"}</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								Please enable audio permissions and refresh the page to enable calling
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.onAudioWarningClose} color="primary">
								Disagree
							</Button>
						</DialogActions>
					</Dialog>
					<Prompt
						isOpen={this.state.safariPrompt && this.state.currentAppName === "calls"}
						color="important"
						content={
							<div onClick={this.dismissSafariPrompt}>
								<div>My Phone.com calling is disabled in Safari and iOS browsers.</div>
								<div>
									Please use one of our apps or a supported browser to make and receive phone calls.
								</div>
							</div>
						}
						position="bottom-left"
						onClose={this.dismissSafariPrompt}
					/>
				</div>
			</ThemeProvider>
		)
	}
}

export default withStyles(styles)(Communicator)
