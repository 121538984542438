import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './teammate-styles'
import ChooseANumberModal from './ChooseANumberModal'
import { convertNumberToE164, formatPhoneNumber } from 'phone-numbers'
import { switchTeammate } from '../../actions/users'
import {
	ContactAvatarIcon,
	ContactAvatarCamIcon,
	CallCircleFilledIcon,
	MessageCircleFilledIcon
} from 'pdc-svg-icons'
import { withStyles } from '@material-ui/core'
import gtmDataPush from 'gtm-events'

const GTM_APP_NAME = 'people;teammate-content'
const GTM_MAP = {
	CALL_EXTENSION: 1,
	CALL_NUMBER: 2,
	CALL_CHOOSE_RECIPIENT: 3,
	CONVERSATION_TO_NUMBER: 1,
	CONVERSATION_TO_CHOOSE_RECIPIENT: 2,
	CALL_CHOSEN_NUMBER: 1,
	MESSAGE_CHOSEN_NUMBER: 2,
	CLOSE_CALL_NUMBER_CHOOSE: 1,
	CLOSE_CONVERSATION_NUMBER_CHOOSE: 2
}

const mapStateToProps = state => ({
	teammates:			state.teammates,
	currentTeammate:	state.teammates.items.find(t => t.selected)
})
const mapDispatchToProps = dispatch => ({
	switchTeammate: teammateId => dispatch(switchTeammate(teammateId))
})

class TeammateContent extends Component {

	state = {chooseANumberModalType: null}

	renderHeader = () => {
		const { classes, currentTeammate } = this.props
		let teammateName = currentTeammate ? `${currentTeammate.first_name} ${currentTeammate.last_name}` : ''
		return (
			<div className={classes.panelHeader}>
				<div className='name-section'>{teammateName}</div>
			</div>
		)
	}

	renderTeammateDetailsSection = () => {
		const { classes, currentTeammate } = this.props
		if (!currentTeammate) return this.renderNoTeammateSelectedView()
		return (
			<div className={classes.teammateDetailsSection}>
				{this.renderTitleSection()}
				{this.renderTeammateDetails()}
			</div>
		)
	}

	makeCall = number => {
		console.log('number', number)
		this.props.makeCall(number)
	}

	onCallStartClick = () => {
		let { currentTeammate }	= this.props
		let personalPhoneNumber	= currentTeammate.personal_phone_number.trim()
		let directNumbers		= currentTeammate.relationships.numbers.data
		directNumbers			= directNumbers.filter(n => n.route_type === 'direct-line').map(n => n.e164_number)
		let allNumbers			= [personalPhoneNumber].concat(directNumbers).filter(e => e)
		let extension			= null
		console.log(currentTeammate)
		if (currentTeammate.relationships.extension &&
			currentTeammate.relationships.extension.data &&
			currentTeammate.relationships.extension.data.extension_number
		) extension = currentTeammate.relationships.extension.data.extension_number.toString()
		// if (allNumbers.length === 0 && !extension) return
		if (allNumbers.length === 0) {
			gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'start-call-click', PDC_Value: GTM_MAP.CALL_EXTENSION})
			return this.makeCall(extension)
		}
		if (allNumbers.length === 1 && !extension) {
			gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'start-call-click', PDC_Value: GTM_MAP.CALL_NUMBER})
			return this.makeCall(convertNumberToE164(allNumbers[0]))
		}
		gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'start-call-click', PDC_Value: GTM_MAP.CALL_CHOOSE_RECIPIENT})
		this.setState({chooseANumberModalType: 'call'})
	}

	onConversationStartClick = () => {
		let { currentTeammate }	= this.props
		let personalPhoneNumber	= currentTeammate.personal_phone_number.trim()
		let directNumbers		= currentTeammate.relationships.numbers.data
		directNumbers			= directNumbers.filter(n => n.route_type === 'direct-line').map(n => n.e164_number)
		let allNumbers = [personalPhoneNumber].concat(directNumbers).filter(e => e)
		// if (allNumbers.length === 0) return
		if (allNumbers.length === 1) {
			gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'start-conversation-click', PDC_Value: GTM_MAP.CONVERSATION_TO_NUMBER})
			return this.props.startConversation(convertNumberToE164(allNumbers[0]))
		}
		gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'start-conversation-click', PDC_Value: GTM_MAP.CONVERSATION_TO_CHOOSE_RECIPIENT})
		this.setState({chooseANumberModalType: 'message'})
	}

	renderTitleSection = () => {
		const { classes, currentTeammate } = this.props
		let teammateName	= currentTeammate ? `${currentTeammate.first_name} ${currentTeammate.last_name}` : ''
		let phoneNumber		= currentTeammate.personal_phone_number.trim()
		let extension		= null
		if (currentTeammate.relationships.extension &&
			currentTeammate.relationships.extension.data &&
			currentTeammate.relationships.extension.data.extension_number
		) extension = currentTeammate.relationships.extension.data.extension_number
		let directNumbers = currentTeammate.relationships.numbers.data.filter(n => n.route_type === 'direct-line')
		return (
			<div className={classes.titleSection}>
				<div className='avatar-icon-wrapper'>
					<ContactAvatarIcon className='avatar-icon'/>
					{/* <ContactAvatarCamIcon className='cam-icon'/> */}
				</div>
				<div className='contact-name'>{teammateName}</div>
				{phoneNumber || directNumbers.length || (extension && !this.props.isVirtualExtension) ?
					<div className='actions-wrapper'>
						{extension && !this.props.isVirtualExtension ? <CallCircleFilledIcon onClick={this.onCallStartClick}/> : null}
						{(phoneNumber || directNumbers.length) ? <MessageCircleFilledIcon onClick={this.onConversationStartClick}/> : null}
					</div>
				: null}
			</div>
		)
	}

	renderNoTeammateSelectedView = () => <div className={this.props.classes.noSelectionView}>No teammate selected.</div>

	renderDirectNumbers = () => {
		let currentTeammate	= this.props.currentTeammate
		let numbers = currentTeammate.relationships.numbers.data
		numbers = numbers.filter(n => n.route_type === 'direct-line').map(n => n.e164_number)
		if (!numbers.length) return
		return (
			<div className='field-wrapper'>
				<span className='title'>Direct numbers</span>
				{numbers.map((n, i) => <span key={i} className='value'>{formatPhoneNumber(n)}</span>)}
			</div>
		)
	}

	renderTeammateDetails = () => {
		const { classes }	= this.props
		let currentTeammate	= this.props.currentTeammate
		let extension		= currentTeammate.relationships.extension.data
		return (
			<div className={classes.detailsWrapper}>
				<div className='details-wrapper'>
					{currentTeammate.personal_phone_number ?
						<div className='field-wrapper'>
							<span className='title'>Personal phone number</span>
							<span className='value'>{formatPhoneNumber(currentTeammate.personal_phone_number)}</span>
						</div>
					: null}
					{this.renderDirectNumbers()}
					{currentTeammate.email ?
						<div className='field-wrapper'>
							<span className='title'>Email</span>
							<span className='value'>{currentTeammate.email}</span>
						</div>
					: null}
					<div className='field-wrapper'>
						<span className='title'>Extension</span>
						<span className='value'>{extension.extension_name} - {extension.extension_number}</span>
					</div>
				</div>
			</div>
		)
	}

	onNumberChoose = number => {
		if (this.state.chooseANumberModalType === 'call') {
			gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'number-modal-choose', PDC_Value: GTM_MAP.CALL_CHOSEN_NUMBER})
			this.makeCall(number)
		} else if (this.state.chooseANumberModalType === 'message') {
			gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'number-modal-choose', PDC_Value: GTM_MAP.MESSAGE_CHOSEN_NUMBER})
			this.props.startConversation(number)
		}
		this.setState({chooseANumberModalType: null})
	}

	renderChooseANumberModal = () => {
		let { chooseANumberModalType } = this.state
		let { currentTeammate }	= this.props
		if (!currentTeammate) return null
		// Numbers
		let personalPhoneNumber	= currentTeammate.personal_phone_number.trim()
		personalPhoneNumber		= personalPhoneNumber ? convertNumberToE164(personalPhoneNumber) : personalPhoneNumber
		let directNumbers		= currentTeammate.relationships.numbers.data
		directNumbers			= directNumbers.filter(n => n.route_type === 'direct-line').map(n => n.e164_number)
		// Extension
		let extension			= null
		if (currentTeammate.relationships.extension &&
			currentTeammate.relationships.extension.data &&
			currentTeammate.relationships.extension.data.extension_number
		) extension = currentTeammate.relationships.extension.data.extension_number.toString()
		// On close
		let onClose = () => {
			gtmDataPush({
				PDC_Action:	GTM_APP_NAME,
				PDC_Label:	'number-modal-close',
				PDC_Value:	chooseANumberModalType === 'call' ? GTM_MAP.CLOSE_CALL_NUMBER_CHOOSE : GTM_MAP.CLOSE_CONVERSATION_NUMBER_CHOOSE
			})
			this.setState({chooseANumberModalType: null})
		}
		return (
			<ChooseANumberModal
				type		= {chooseANumberModalType}
				isShown		= {Boolean(chooseANumberModalType)}
				numbers		= {{personalPhoneNumber, directNumbers, extension}}
				onClose		= {onClose}
				onChoose	= {this.onNumberChoose}
			/>
		)
	}

	render() {
		const { classes } = this.props
		return (
			<div className={classes.wrapper}>
				{this.renderHeader()}
				{this.renderTeammateDetailsSection()}
				{this.renderChooseANumberModal()}
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TeammateContent))