import React, { Component } from 'react'

import { withRouter } from 'react-router-dom'

import AvatarIcon from 'avatar-icon'
import { XIcon, ChevronRight, NewTabTargetIcon } from 'pdc-svg-icons'
import Button from 'button'
import ConfirmModal from 'confirm-modal'
import { logout } from 'phonecom'

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Chip from '@material-ui/core/Chip';
import MenuIcon from '@material-ui/icons/Menu'
import Typography from '@material-ui/core/Typography/Typography'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Toolbar from '@material-ui/core/Toolbar/Toolbar'
import AppBar from '@material-ui/core/AppBar/AppBar'


import styles from './styles'
import { withStyles } from '@material-ui/core/styles'

import { theme } from 'get-theme'

class Navigation extends Component {

	constructor(props) {
		super(props)
		this.state = {
			openStatusDrawer:	false,
			discardChangesData:	null
		}

	}

	renderAppBar = () => {
		const { classes } = this.props
		let { isMobileView, isTabletView } = this.props.screenViewType
		if (!isMobileView && !isTabletView) return null
		return (
			<AppBar className={classes.appBar}>
				<Toolbar classes={{ root: classes.toolbar }}>
					<IconButton
						color='inherit'
						aria-label='Open drawer'
						edge='start'
						onClick={this.props.handleDrawerToggle}
						className={classes.menuButton}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant='h6' noWrap>
						{this.props.appBarLabel}
					</Typography>
					{/*{this.props.showAvatar*/}
						{/*?*/}
							{/*<div data-navbar='avatar' onClick={()=>this.setState({openStatusDrawer: true})} className={classes.avatarWrapper}>*/}
								{/*<AvatarIcon*/}
									{/*imageUrl	= {window.V5PHONECOM.avatar_url ? window.V5PHONECOM.avatar_url : null}*/}
									{/*height		= {35}*/}
									{/*width		= {35}*/}
									{/*type		= {'available'}*/}
									{/*noBorder	= {true}*/}
								{/*/>*/}
							{/*</div>*/}
						{/*:*/}
							{/*null*/}
					{/*}*/}
					
				</Toolbar>
			</AppBar>
		)
	}

	renderUserStatusDrawerContent = () => {
		const { classes } = this.props

		return (
			<div data-drawer='status' className={classes.statusDrawer}>
				<div className={classes.statusDrawerHeader}>
					<XIcon onClick={()=>{this.setState({openStatusDrawer: false})}} className={classes.xIcon}/>
					<AvatarIcon
						imageUrl	= {window.V5PHONECOM.avatar_url ? window.V5PHONECOM.avatar_url : null}
						type		= {'available'}
						noBorder	= {true}
						opacity		= {1}
					/>
				</div>
					<div className={classes.statusDrawerItem}>
						<div className='text'>
							My Settings
						</div>
						<div className='icon'>
							<ChevronRight/>
						</div>
					</div>
					<div className={classes.statusDrawerItem}>
					<div className='text'>
						Help
					</div>
					<div className='icon'>
						<ChevronRight/>
					</div>
				</div>
				<div className={classes.statusDrawerFooter}>
					<Button 
						onClick={() => {logout()}} 
						data-button='sign-out' 
						variant='outlined' 
						icon='logout'
						color='primary'>Sign out
					</Button>

				</div>
			</div>
		)

	}

	renderUserStatusDrawer = () => {
		const { classes } = this.props
		return <SwipeableDrawer
					classes={{paperAnchorBottom: classes.statusDrawerStyle}}
					anchor='bottom'
					open={this.state.openStatusDrawer}
					onClose={()=>this.setState({openStatusDrawer: false,})}
					onOpen={()=>this.setState({openStatusDrawer: true})}
				>
					{this.renderUserStatusDrawerContent()}
				</SwipeableDrawer>
	}

	renderMobileDrawer = () => {
		const { classes } = this.props
		return (
			<div>
				<SwipeableDrawer
					onOpen		= {this.props.handleDrawerToggle}
					container	= {this.props.container}
					variant		= 'temporary'
					anchor		= {theme.direction === 'rtl' ? 'right' : 'left'}
					open		= {this.props.mobileOpen}
					onClose		= {this.props.handleDrawerToggle}
					classes		= {{paper: classes.drawerPaper}}
					// Better open performance on mobile.
					ModalProps	= {{keepMounted: true}}
				>
					{this.renderDrawerContent()}
				</SwipeableDrawer>
			</div>
		)
	}

	renderDrawerContent = () => {
		const { classes } = this.props
		return (
			<div className={classes.drawableContent}>
				{this.props.renderNavHeader()}
				{this.renderDrawerTabs()}
			</div>
		)
	}

	renderLinks = () => {
		let { classes, links } = this.props
		if (!links || !links.length) return null
		return (
			<div className={classes.linksSection}>
				{links.map((link, i) => <div key={i}><a target='_blank' rel="noopener noreferrer" href={link.address}>{link.text}<NewTabTargetIcon/></a></div>)}
			</div>
		)
	}

	renderDrawerTabs = () => {
		const { classes } = this.props
		return (
			<div className={classes.drawerWrapper}>
				<List className={classes.drawerList}>
					{this.props.tabs.map((route, index) => {
						if (!route.enabled) return
						let to = route.link.indexOf('http') === -1 ? route.link : '#'
						let activeClass = route.active ? 'active' : ''
						let separateClass = route.text === 'Control Panel' ? 'separate': ''
						let Icon = route.icon
						let unreadInfoText = route.unread > 99 ? '99+' : route.unread
						return (
							<div
								data-test-id={route.testId}
								key={route.text}
								onClick={route.onClick ? route.onClick : () => this.handleMenuChange(route, to)}
								className={classes.appLinkStyle}
								ref={route.ref ? route.ref : null}
							>
								<ListItem button className={`${classes.drawerItem} ${activeClass} ${separateClass}`}>
									<ListItemIcon>
										<Icon
											active={route.active ? 1 : 0}
											read={!route.unread ? 1 : 0}
											className={classes.drawIcon}
										/>
									</ListItemIcon>

									<ListItemText classes={{root: classes.navItemRoot, primary: classes.navItemText}} primary={route.text} />
									{route.unread ? <div className={classes.unreadInfo}><span title={route.unread}>{unreadInfoText}</span></div> :  route.badge ?
										<Chip label={ route.badge } color="secondary" size="small" />
								 	: null }
									{!route.enabled && (
										<span className={classes.disabledLabel}>
											Coming Soon!
										</span>
									)}
								</ListItem>
							</div>
						)
					})}
				</List>
				{this.renderLinks()}
			</div>
		)
	}

	handleMenuChange = (route, to) => {
		if (!route.enabled) return
		if (this.props.appHasChange) return this.setState({discardChangesData: {route, to}})
		this.goToRoute(route, to)
	}

	goToRoute = (route, to) => {
		this.props.history.push(to)
		this.props.handleDrawerToggle()
		this.props.onMenuChange(route)
	}

	renderDesktopDrawer = () => {
		const { classes } = this.props
		return (
			<div>
				<div style={{width: theme.drawerWidth}} />
				<SwipeableDrawer
					classes={{paper: classes.drawerPaper}}
					variant='permanent'
					onOpen={() => {}}
					onClose={() => {}}
					open
				>
					{this.renderDrawerContent()}
				</SwipeableDrawer>
			</div>
		)
	}

	discardChanges = () => {
		let { route, to } = this.state.discardChangesData
		this.setState({discardChangesData: null})
		this.props.discardChanges()
		this.goToRoute(route, to)
	}

	renderDiscardChangesModal = () => {
		return (
			<ConfirmModal
				title			= 'You have unsaved changes'
				isShown			= {Boolean(this.state.discardChangesData)}
				content			= 'Do you wish to continue? All changes will be lost. '
				noButtonText	= 'Cancel'
				yesButtonText	= 'Continue'
				onReject		= {() => this.setState({discardChangesData: null})}
				onConfirm		= {this.discardChanges}
				size			= 'size550'
			/>
		)
	}

	render() {
		const { classes } = this.props
		let { isMobileView, isTabletView } = this.props.screenViewType
		let isSmallView = isMobileView || isTabletView
		return (
			<div className={classes.root}>
				{this.renderAppBar()}
				{isSmallView ? this.renderMobileDrawer() : this.renderDesktopDrawer()}
				{/*{isSmallView ? this.renderUserStatusDrawer() : null}*/}
				<div className={`${classes.content} ${isSmallView ? 'mobile' : ''}`}>
					{this.props.children}
				</div>
				{this.renderDiscardChangesModal()}
			</div>
		)
	}
}

export default withStyles(styles)(withRouter(Navigation))
