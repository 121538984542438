const styles = theme => ({
	root: {
		display:	'flex',
		position:	'relative',
		height:		'100%',
		minHeight:	0
	},
	drawableContent: {
		display:		'flex',
		flexDirection:	'column',
		height:			'100%'
	},
	drawerWrapper: {
		display:		'flex',
		flexDirection:	'column',
		justifyContent:	'space-between',
		height:			'100%',
		overflowY:		'auto'
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: theme.drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: theme.appBar,
	menuButton: {
		marginRight:	theme.spacing(2),
		outline:		'none !important'
	},
	toolbar: {
		minHeight: 56
	},
	drawerPaper: theme.drawerPaper,
	content: {
		height:		'100%',
		maxWidth:	'100%',
		flexGrow:	1,
		'&:not(.mobile)': {
			maxWidth: `calc(100% - ${theme.drawerPaper.width}px)`
		}
	},
	drawerList: {
		// paddingLeft: '10px !important',
		// paddingRight: '10px !important'
	},
	drawIcon: {
		color: theme.drawerPaper.color,
		width: 35
	},
	drawerItem: {
		marginBottom:	'2px !important',
		padding:		'11px 31px',
		position:		'relative',
		'&:not(.active):hover': {backgroundColor: 'rgba(136, 150, 158, 0.35)'},
		'&.active': {backgroundColor: theme.palette.primary[-300], color: theme.palette.primary['+100']},
		'&.separate': {marginTop: 42}
	},
	disabledLabel: {
		marginTop: '-10px',
		fontSize: theme.tinyText.fontSize,
		color: theme.disabledLabel.color
	},
	appLinkStyle: {
		textDecoration: 'none',
		color: theme.drawerPaper.color,
		'&:hover': {
			textDecoration: 'none',
			color: theme.drawerPaper.color,
		}
	},
	unreadIcon: {
		backgroundColor: theme.notificationDotColor,
		width: '10px',
		height: '10px',
		borderRadius: '50%'
	},
	avatarWrapper: {
		marginLeft:	'auto',
		cursor:		'pointer'
	},
	statusDrawerStyle: {
		borderTopRightRadius:	20,
		borderTopLeftRadius:	20
	},
	statusDrawer: {
		display:		'flex',
		flexDirection:	'column',
		height:			'100%'
	},
	statusDrawerHeader: {
		display:		'flex',
		alignItems:		'center',
		width:			'100%',
		justifyContent:	'center',
		alignItems:		'center',
		height:			130,
		borderBottom:	'1px solid #DEE1E3',
		position:		'relative'
	},
	statusDrawerItem: {
		display:		'flex',
		height:			70,
		borderBottom:	`1px solid ${theme.palette.secondary['-200']}`,
		alignItems:		'center',
		justifyContent:	'space-between',
		cursor:			'pointer',
		'&:hover': {
			background: theme.palette.primary['-300']
		},
		'& .text':{
			paddingLeft:	40,
			fontWeight:		600,
			fontSize:		'16px',
			lineHeight:		'24px'
		},
		'& .icon':{
			paddingRight:	40
		},
		'& svg': {
			color: '#85929A'
		}
	},
	statusDrawerFooter: {
		display:		'flex',
		width:			'100%',
		justifyContent:	'space-around',
		alignItems:		'center',
		minHeight:		130
	},
	xIcon: {
		position: 'absolute',
		right: 26, 
		top: 26,
		cursor: 'pointer',
		color: theme.palette.secondary['0']
	},
	navItemRoot: {margin: 0},
	navItemText: {
		fontFamily:	'Montserrat-medium !important',
		fontSize:	14,
		fontWeight:	500
	},
	unreadInfo: {
		display:		'flex',
		alignItems:		'center',
		justifyContent:	'center',
		width:			24,
		height:			24,
		borderRadius:	'50%',
		background:		theme.palette.attention[0],
		position:		'absolute',
		right:			12,
		boxShadow:		'-1px 1px 0px rgba(0, 0, 0, 0.5)',
		'& > span': {
			color:			'white',
			fontFamily:		'NTR',
			fontSize:		14.5,
			letterSpacing:	0.1,
			lineHeight:		1,
			marginTop:		2
		}
	},
	linksSection: {
		display:		'flex',
		flexDirection:	'column',
		marginBottom:	45,
		paddingLeft:	25,
		'& svg': {
			marginLeft:	9,
			color:		theme.palette.secondary[0]
		},
		'& a': {
			textDecoration:	'none',
			fontFamily:		'Montserrat-medium',
			fontSize:		14,
			fontWeight:		500,
			lineHeight:		'42px',
			color:			theme.palette.secondary[-100],
			'&:hover': {
				color: theme.palette.primary[-300],
				'& svg': {
					color: theme.palette.primary[-300]
				}
			}
		}
	}
})

export default styles