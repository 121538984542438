export function updateCalls(calls) {
	return {
		type: 'UPDATE_CALLS',
		calls
	}
}

export function updateCall(call) {
	return {
		type: 'UPDATE_CALL',
		call
	}
}

export function deleteCall(call) {
	return {
		type: 'DELETE_CALL',
		call
	}
}

export function addCall(call) {
	return {
		type: 'ADD_CALL',
		call
	}
}

export function switchCall(call) {
	return {
		type: 'SWITCH_CALL',
		call
	}
}

export function addRecordings(recordings) {
	return {
		type: 'ADD_RECORDINGS',
		recordings
	}
}

export function addContactsToCalls(contacts) {
	return {
		type: 'ADD_CONTACTS_TO_CALLS',
		contacts
	}
}

export function removeContactFromCalls(contactId) {
	return {
		type: 'REMOVE_CONTACT_FROM_CALLS',
		contactId
	}
}

export function switchCallerId(callerId) {
	return {
		type: 'SWITCH_CALLER_ID',
		callerId
	}
}