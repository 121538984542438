import React, { Component } from "react"
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk"
import Timer from "timer"
import { formatPhoneNumber } from "phone-numbers"
import CallEndIcon from "@material-ui/icons/CallEnd"
import { withStyles } from "@material-ui/core"
import { theme } from "get-theme"
import CallMergeIcon from "@material-ui/icons/CallMerge";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const styles = (theme) => ({
	container: {
		//width: 265,
		"padding-left": "2em",
		"padding-right": "2em",
		// width: 265,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		cursor: "pointer",

		// "&:hover": {
		// 	backgroundColor: "#26859D",
		// 	opacity: 0.65,
		// },
		// "&:active": {
		// 	opacity: 1,
		// },
	},
	callMenuSelector: {
		height: "45px",
		// width: 299,
		display: "flex",
		"padding-left": "10px",
		"padding-right": "10px",

		flexDirection: "row",
		// justifyContent: "space-around",
		alignItems: "center",
		cursor: "pointer",
		borderRadius: "5px",
		backgroundColor: theme.palette.tertiary["-100"],
		"&:hover": {
			backgroundColor: theme.palette.tertiary["-100"],
			opacity: 0.65,
		},
		"&:active": {
			opacity: 1,
		},
		animation: `$incomingPulsate 1s`,
	},
	"@keyframes incomingPulsate": {
		"0%": {
			boxShadow: `0 0 0 32px , 0 0 0 50px ${theme.palette.tertiary['0']}`,
		},
		"40%": {
			boxShadow: `0 0 0 24px rgba(72, 185, 213, 0), 0 0 0 30px ${theme.palette.tertiary['0']}`,
		},
		"80%": {
			boxShadow: "0 0 0 12px rgba(72, 185, 213, 0), 0 0 0 15px rgba(72, 185, 213, 0)",
		},
		"100%": {
			boxShadow: "0 0 0 0 rgba(72, 185, 213, 0), 0 0 0 0px rgba(72, 185, 213, 0)",
		},
	},
	endCallBtn: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		//todo: NOT IN PALETTE
		// background: "#FF6352",
		background: theme.palette.attention["-100"],
		borderRadius: "50%",
		cursor: "pointer",
		width: 50,
		height: 50,
		borderStyle: "solid",
		"&:hover": {
			backgroundColor: theme.palette.attention["+100"],
			opacity: 0.65,
		},
		"&:active": {
			opacity: 1,
		},
	},
})
class CallBarActiveCallArea extends Component {
	render() {
		const { classes } = this.props

		return (
			<div className={classes.container} data-test-id={'call-bar-active-call-area'}>
				{this.props.currentCall ? (
						<div
							className={classes.callMenuSelector}
							onClick={this.props.toggleShowCallSessionSelector}
							data-test-id={"call-session-toggle-button-desktop"}
						>
							{this.props.currentCall.participants.length > 1 ? (
								<CallMergeIcon style={{ marginLeft: 30 }} />
							) : (
								<PhoneInTalkIcon style={{ marginLeft: 30 }} />
							)}
							{this.props.currentCall && this.props.currentCall.callState === "ACTIVE" ? (
								<div>
									<span style={{ marginLeft: 5, width: 120 }}>
											<Timer startTime={this.props.currentCall.callStartTime} />
									</span>
									<span style={{ marginLeft: 5, width: "11em", marginRight: 18, fontSize: 14, fontWeight: 'bold',  }}>
									{this.props.currentCall.participants.length > 1
										? `${this.props.currentCall.participants.length} CALLERS`
										: this.props.currentCall.participants.length > 0
										? formatPhoneNumber(this.props.currentCall.participants[0].callerId)
										: "NO CALLER ID"}
									</span>
								</div> ) 
							: <div style={{ marginLeft: 5, width: 120 }}>
								{ this.props.currentCall.callState === 'INACTIVE' ? 'RINGING' :
								this.props.currentCall.callState === 'CONNECTING' ? 'CONNECTING' : null }
							</div> }
							
							{ this.props.isExpanded ? 
							<ArrowDropUpIcon className={classes.goToCallIcon} onClick={this.props.toggleShowCallSessionSelector} data-test-id={'dialer-multi-expand'}/>
							:
							<ArrowDropDownIcon className={classes.goToCallIcon} onClick={this.props.toggleShowCallSessionSelector} data-test-id={'dialer-multi-expand'}/>
							}

							{ this.props.callsOnHold > 0 ? 
								<span>({this.props.callsOnHold})</span> :
								null
							}

						</div>
				) : (
					<div className={classes.callMenuSelector} onClick={this.props.toggleShowCallSessionSelector} data-test-id={"call-session-toggle-button-desktop"}>
						{"Pending Calls"}
					</div>
				)}
			</div>
		)
	}
}
export default withStyles(styles)(CallBarActiveCallArea)
