import React, { Component } from 'react'
import { CheckmarkIcon2 } from 'pdc-svg-icons'
import styles from './styles'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

class SelectionControl extends Component {

	componentDidMount() {
		this.setSelection()
	}

	componentDidUpdate(prevProps) {
		if (this.props.checked !== prevProps.checked) this.setSelection()
	}

	setSelection = () => {
		let elements = Array.from(document.getElementsByName(this.props.name))
		let element = elements.find(e => e.getAttribute('value') === this.props.value.toString())
		element.checked = this.props.checked
	}

	renderSwitch = () => {
		const { classes, checked, disabled, name, value, className } = this.props
		let checkedClass	= checked ? 'checked' : 'unchecked'
		let disabledClass	= disabled ? 'disabled' : ''
		let classNames		= `${classes.switchWrapper} ${checkedClass} ${disabledClass}`
		if (className && className.wrapper) classNames += ` ${className}`
		return (
			<div
				data-selection-conrol='switch'
				className	= {classNames}
				onClick		= {this.props.onClick}
			>
				<div className='switch'>
					<div className='thumb'>
						<CheckmarkIcon2/>
						<input readOnly type='checkbox' name={name} value={value} id={value.toString()} checked={checked} style={{display: 'none'}}/>
					</div>
				</div>
			</div>
		)
	}

	renderCheckbox = () => {
		const { classes, checked, disabled, name, value, disableHover, className } = this.props
		let checkedClass		= checked ? 'checked' : 'unchecked'
		let disabledClass		= disabled ? 'disabled' : ''
		let disableHoverClass	= disableHover ? 'no-hover' : ''
		let classNames			= `${classes.checkboxWrapper} ${checkedClass} ${disabledClass} ${disableHoverClass}`
		if (className && className.wrapper) classNames += ` ${className.wrapper}`
		return (
			<div
				data-selection-conrol='checkbox'
				className	= {classNames}
				onClick		= {this.props.onClick}
			>
				<div className='checkbox'><CheckmarkIcon2/></div>
				<input readOnly type='checkbox' name={name} value={value} id={value.toString()} checked={checked} style={{display: 'none'}}/>
			</div>
		)
	}

	renderRadio = () => {
		const { classes, checked, disabled, name, value, className } = this.props
		let checkedClass	= checked ? 'checked' : 'unchecked'
		let disabledClass	= disabled ? 'disabled' : ''
		let classNames		= `${classes.radioWrapper} ${checkedClass} ${disabledClass}`
		if (className && className.wrapper) classNames += ` ${className.wrapper}`
		return (
			<div
				data-selection-conrol='radio'
				className	= {classNames}
				onClick		= {this.props.onClick}
			>
				<div className='radio'>
					<div className='check-circle'></div>
				</div>
				<input readOnly type='radio' name={name} value={value} id={value.toString()} checked={checked} style={{display: 'none'}}/>
			</div>
		)
	}

	render() {
		const { variant } = this.props
		return (
			variant === 'switch' ? this.renderSwitch() :
			variant === 'checkbox' ? this.renderCheckbox() :
			variant === 'radio' ? this.renderRadio() : null
		)
	}
}

SelectionControl.propTypes = {
	/**
	 * The variant of the SelectionControl
	 * 'switch', 'checkbox' or 'radio'
	 */
	variant: PropTypes.string,
	/**
	 * Is it checked
	 * true or false
	 */
	checked: PropTypes.bool,
	/**
	 * Is it disabled
	 * true or false
	 */
	disabled: PropTypes.bool,
	/**
	 * The name of the group
	 */
	name: PropTypes.string.isRequired,
	/**
	 * A unique value
	 */
	value: PropTypes.string.isRequired,
	/**
	 * A function which will be called when the component is clicked.
	 */
	onClick: PropTypes.func,
	/**
	 * The object props can be in: ['wrapper']
	 */
	className: PropTypes.object
}

SelectionControl.defaultProps = {
	variant:	'vertical',
	disabled:	false,
	checked:	false
}

export default withStyles(styles)(SelectionControl)