import React, { Component } from 'react'
import Spinner from 'spinner'

import { RemovePillIcon } from 'pdc-svg-icons'

import { isValidNumber as isValidNumberCustom } from 'libphonenumber-js/custom'
import metadata from 'libphonenumber-js/metadata.full.json'
import Dialpad from 'dialpad'
import DialpadIcon from '@material-ui/icons/Dialpad'
import styles from './styles'
import { withStyles } from '@material-ui/core'
import { detectOS } from "os-detector"
import gtmDataPush from 'gtm-events'

export const isValidNumber = (...args) => isValidNumberCustom(...args, metadata)

const GTM_MAP = {NOT_ALLOWED: 2, ALREADY_SHOWN: 0, SHOW_DIALPAD: 1}

class TagsInput extends Component {

	state = {
		selectInput:	false,
		isDialpadShown:	false
	}

	componentDidUpdate() {
		let tagsInputElement = document.getElementById('tags-input')
		if (this.state.selectInput) {
			this.setState({selectInput: false})
			if (this.props.textError) this.props.onChangeInput('')
			let tagsInputElement = document.getElementById('tags-input')
			if (tagsInputElement && detectOS() !=='iOS') tagsInputElement.focus()
		}
		if (this.props.textError && tagsInputElement) tagsInputElement.blur()
	}

	renderLoader() {
		const { classes } = this.props
		return (
			<div className={classes.pdcLoader} style={this.props.items.length ? {top: '9px'} : {}}>
				<Spinner/>
			</div>
		)
	}

	showDialpad = () => {
		if (this.props.notAllowed) {
			gtmDataPush({PDC_Action: this.props.origin, PDC_Label: 'dialpad-icon-click', PDC_Value: GTM_MAP.NOT_ALLOWED})
			return
		}
		this.setState({isDialpadShown: true})
		gtmDataPush({PDC_Action: this.props.origin, PDC_Label: 'dialpad-icon-click', PDC_Value: this.state.isDialpadShown ? GTM_MAP.ALREADY_SHOWN : GTM_MAP.SHOW_DIALPAD})
	}

	onDialpadClickAway = () => {
		this.setState({isDialpadShown: false})
		gtmDataPush({PDC_Action: this.props.origin, PDC_Label: 'dialpad-away-click'})
	}

	onDialpadItemClick = value => {
		this.props.onChangeInput(`${this.props.inputValue}${value}`)
		gtmDataPush({PDC_Action: this.props.origin, PDC_Label: `dialpad-item-click;${value}`})
	}

	renderDialpad = () => {
		const { classes } = this.props
		return (
			<div className={`${classes.dialpad} ${this.props.notAllowed ? 'not-allowed' : ''}`}>
				<DialpadIcon id='tags-dialpad-button' classes={{root: classes.dialpadIcon}} onClick={this.showDialpad}/>
				<Dialpad
					open		= {this.state.isDialpadShown}
					anchorEl	= {document.getElementById('tags-dialpad-button')}
					onClickAway	= {this.onDialpadClickAway}
					onClick		= {this.onDialpadItemClick}
					onSubmit	= {() => this.props.submitEntry(this.props.inputValue)}
				/>
			</div>
		)
	}

	render() {
		const { classes }	= this.props
		let placeholder		= this.props.inputPlaceholder
		let showInput		= !this.props.singleSelect || !this.props.items.length
		let prompt			= `${this.props.prompt}:`
		return (
			<div className={`${classes.tagsInput} ${!showInput ? 'no-input' : ''}`} onClick={this.focusInput}>
				<span className={classes.toStyle}>{prompt}</span> {this.renderTagItems()}
				{showInput ?
					<input
						className	= {`${classes.tagsInputField} ${this.props.textError ? 'text-error' : ''} ${this.props.notAllowed ? 'not-allowed' : ''} fs-block`}
						id			= 'tags-input'
						type		= 'text'
						placeholder	= {placeholder}
						onChange	= {this.onInputChange}
						value		= {this.props.inputValue}
						onKeyDown	= {this.onKeyPress} // onKeyPress didn't work with 'Enter'
						size		= {placeholder.length}
						disabled	= {this.props.notAllowed}
					/>
				: null}
				{showInput && this.props.showDialpad ? this.renderDialpad() : null}
				{this.props.showLoading && this.renderLoader()}
			</div>
		)
	}

	focusInput = () => this.setState({selectInput: true})

	onInputChange = e => this.props.onChangeInput(e.target.value)

	renderTagItems() {
		const { classes } = this.props
		return this.props.items.map((value, i) => {
			return (
				<div key={`${i}${value.mainText}`} className={`${classes.tagItem} ${!value.additionalText ? 'only-main' : ''}`}>
					<div
						className		= {`${classes.content} fs-block`}
						onDoubleClick	= {this.onDoubleClick.bind(this, value.mainText)}
						title			= 'Doubleclick to edit'
					>
						{value.additionalText ? <span className={classes.additionalText}>{value.additionalText}</span> : null}
						<span className='main-text'>{value.mainText}</span>
					</div>
					<div className={classes.removeContactButton} onClick={() => this.onRemoveClick(value.mainText)}>
						<RemovePillIcon style={{width: '100%', height: '100%'}}/>
					</div>
				</div>
			)
		})
	}

	onRemoveClick = value => {
		this.removeItem(value)
		gtmDataPush({PDC_Action: this.props.origin, PDC_Label: 'tag-remove-click'})
	}

	onDoubleClick(value, e) {
		e.persist()
		this.props.onChangeInput(value.substring(0, value.length - 1))
		this.setState({selectInput: true})
		this.removeItem(value)
		gtmDataPush({PDC_Action: this.props.origin, PDC_Label: 'tag-double-click'})
	}

	removeItem = value => {
		let newItems = this.props.items.filter(i => i.mainText !== value)
		this.props.updateItems(newItems)
	}

	onKeyPress = e => {
		if (e.key === ',') {
			this.props.submitEntry(e.target.value)
			e.preventDefault()
		} else if (e.key === 'Backspace' && e.target.value === '' && this.props.items.length) {
			let inputValue = this.props.items[this.props.items.length - 1].mainText
			this.props.onChangeInput(inputValue)
			this.removeItem(inputValue)
			gtmDataPush({PDC_Action: this.props.origin, PDC_Label: 'tag-backspace-press'})
		} else if (e.key === ' ' && e.target.value) {
			if (isValidNumber(e.target.value) || isValidNumber(e.target.value, 'US')) {
				this.props.submitEntry(e.target.value)
				e.preventDefault()
			}
		}
	}
}

export default withStyles(styles)(TagsInput)