import React, { Component } from "react"
import { withStyles } from "@material-ui/core"
import { theme } from "get-theme"
import Dialpad from "dialpad"
import DialpadIcon from "@material-ui/icons/Dialpad"
import ringSound from "./sounds/ring.mp3"
import PersonIcon from "@material-ui/icons/Person"
import { DefaultArrowTooltip } from "tooltips"
import { formatPhoneNumber } from "phone-numbers"
import CallSessionSelector from "call-session-selector"
import { pushCallNotification } from "notification-pusher"
import CallBarContentMobile from "./CallBarContentMobile"
import CallBarContentDesktop from "./CallBarContentDesktop"
import CallProvider, { PdcCallConsumer } from "../../../pages/communicator-app/src/PdcCallProvider"
import gtmDataPush from 'gtm-events'

const GTM_APP_NAME = 'call-bar'

const styles = (theme) => ({
	notificationContainer: {
		width: "100%",
		// height: "fit-content",
		minHeight: 36,
		// padding: 10,
		// backgroundImage: "linear-gradient(-315deg, #4f5e69, #212527)",
		zIndex: 3,
		display: "flex",
		// alignItems: "center",
		justifyContent: "space-between",
		textAlign: "center",
		color: "white",
		backgroundColor: theme.palette.secondary["-300"],
		position: "relative",
		"&.secondary": {
			padding: 10,
		},
		"& .link": {
			textDecoration: "underline",
			color: "white",
			cursor: "pointer",
		},
	},
	notificationContainerDesktop: {
		width: "100%",
		height: "fit-content",
		minHeight: 65,
		zIndex: 3,
		display: "flex",
		justifyContent: "space-between",
		textAlign: "center",
		color: "white",
		background: `linear-gradient(90deg, ${theme.palette.tertiary["+100"]} 0%, ${theme.palette.tertiary["-100"]} 99.6%)`,
		// TODO: not in palette
		// background: "linear-gradient(90deg, #278DA7 0%, #34A4C0 99.6%)",
		position: "relative",
		"&.secondary": {
			padding: 10,
		},
		"& .link": {
			textDecoration: "underline",
			color: "white",
			cursor: "pointer",
		},
	},
	notificationBarContent: {
		width: "100%",
		height: "100%",
		minHeight: 36,
		// padding: 10,
		// backgroundImage: "linear-gradient(-315deg, #4f5e69, #212527)",
		zIndex: 3,
		display: "flex",
		// alignItems: "center",
		justifyContent: "space-between",
		textAlign: "center",
		color: "white",
		position: "relative",
		"&.secondary": {
			padding: 10,
		},
		"& .link": {
			textDecoration: "underline",
			color: "white",
			cursor: "pointer",
		},
	},
	callBarContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
	},
	section: {
		flex: 1,
		fontSize: 18,
		"&.mobile": {
			fontSize: 14,
		},
	},
	leftSection: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontWeight: 500,
		fontSize: 22,
		"& > span:first-child": {
			marginRight: 10,
		},
		"&.mobile": {
			flexDirection: "column",
			fontSize: 14,
		},
		"&.tablet": {
			fontSize: 18,
		},
	},
	middleSection: {
		position: "absolute",
		left: "50%",
		transform: "translateX(-50%)",
	},
	dialpadWrapper: {
		height: 24,
		width: 24,
		float: "left",
		// marginRight: 10,
	},
	linkSeparator: {
		padding: "0 5px",
	},
	closeNotificationBar: {
		position: "absolute",
		right: 15,
		top: "50%",
		transform: "translateY(-50%)",
		fontSize: 26,
		cursor: "pointer",
	},
	goToCallIcon: {
		width: 24,
		height: 24,
		cursor: "pointer",
		// marginLeft: 10,
		color: theme.palette.secondary["+200"],
	},
	goToCallIconMobile: {
		width: 24,
		height: 24,
		cursor: "pointer",
		// marginLeft: 10,
		color: "white",
	},
	selectorToggle: {
		alignSelf: "end",
		borderLeftColor: "black",
		borderLeftStyle: "solid",
		borderLeftWidth: "0.25px",
		height: "100%",
		width: "60px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: theme.palette.secondary["-200"],
	},
	callControlCallBarButton: {
		width: "40px",
		height: "40px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginLeft: "1em",
		// opacity: "0.65",
		borderRadius: "5px",
		backgroundColor: theme.palette.tertiary["-100"],
		"&:hover": {
			backgroundColor: theme.palette.tertiary["-100"],
			opacity: 0.6,
		},
		"&:active": {
			backgroundColor: theme.palette.tertiary["-100"],
			opacity: 0.3,
		},
		cursor: "pointer",
	},
	// mobile version of above
	callControlCallBarContainerWithOnClick: {
		width: "28px",
		height: "28px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"&:active": {
			opacity: 1,
			backgroundColor: theme.palette.secondary["-200"],
		},
		cursor: "pointer",
		marginLeft: 15,
		color: "black",
	},
	callControlCallBarContainer: {
		width: "28px",
		height: "28px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer",
		marginLeft: 15,
		color: "black",
	},
	dialPadIcon: { height: 30, width: 25, cursor: "pointer", color: "white" },
	callMenuContainerMobile: {
		maxWidth: 450,
		position: "absolute",
		zIndex: 7,
		bottom: 27,
		left: 0,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
		borderTopLeftRadius: 7,
		borderTopRightRadius: 7,
		overflow: "hidden",
		marginBottom: 10,
	},
	callMenuContainer: {
		maxWidth: 450,
		position: "absolute",
		zIndex: 7,
		bottom: 55,
		left: "calc(50% - 240px)",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
		borderTopLeftRadius: 7,
		borderTopRightRadius: 7,
		overflow: "hidden",
		marginBottom: 10,
	},
	callMenuContainerTail: {
		borderLeft: "20px solid transparent",
		borderRight: "20px solid transparent",
		borderTop: "20px solid white",
		boxSizing: "border-box",
		// border: "1em solid black",
		borderColor: "transparent transparent white white",
		transformOrigin: "0 0",
		transform: "rotate(-45deg)",
		//TODO: do these count towards palette? diff random black lvls
		boxShadow: "-28px 12px 24px rgba(0, 0, 0, 0.2)",
	},
	callMenuContent: {
		backgroundColor: "white",
		width: "100%",
		minHeight: "51px",
		boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 12px, rgba(0, 0, 0, 0.25) 0px 4px 20px",
		borderBottomRightRadius: "7px",
		borderBottomLeftRadius: "7px",
		overflow: "hidden",
	},
	callBarControlsContainer: {
		maxWidth: "300px",
		display: "flex",
		flexDirection: "row",
		//justifyContent: "space-around",
		width: "100%",
		alignItems: "center",
		height: "100%",
	},
	holdAreaEmpty: {
		backgroundColor: theme.palette.tertiary["0"],
		// backgroundColor: "#26859D",
		maxWidth: 275,
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
	},
	holdArea: {
		backgroundColor: theme.palette.attention["-100"],
		//TODO: not in palette
		// backgroundColor: "#FF7A6B",
	},
	desktopOnHoldCallContainer: {
		width: 40,
		height: 46,
		"&:hover": {
			backgroundColor: theme.palette.attention["+100"],
		},
		"&:active": {
			opacity: 1,
		},
		cursor: "pointer",
	},
	activeMic: { width: 24, height: 24, color: theme.palette.attention["0"] },
	inactiveMic: { width: 24, height: 24, color: "white" },
	inactiveMicMobile: { width: 24, height: 24, color: theme.palette.secondary["-100"] },
})

const CallState = {
	INACTIVE: "INACTIVE",
	ACTIVE: "ACTIVE",
	INCOMING: "INCOMING",
	CONNECTING: "CONNECTING",
}
class CallBar extends Component {
	state = {
		isDialpadShown: false,
		isExpanded: false,
	}
	name = theme.appName

	componentDidMount = async () => {
		console.log(this.props.callContext)

		if (
			process.env.REACT_APP_IS_CALLING_DISABLED || window.safari &&
			!(window.V5PHONECOM && window.V5PHONECOM.features.has("calling_enabled"))
		) {
			return
		}
	}

	componentDidUpdate(oldProps, oldState) {
		if (this.state.isExpanded && !this.props.currentCall && this.props.backgroundCalls.length === 0) {
			this.toggleShowCallSessionSelector()
		}
		if (this.props.incomingCallsCnt > oldProps.incomingCallsCnt) {
			let calls = this.props.getIncomingCalls()
			let call = calls[calls.length - 1]
			let participant = call.participants[0]
			let callerId = participant.callerId || participant.phoneNumber
			let phoneNumber = participant.phoneNumber
			let answerAction = () => {
				this.props.answerById(phoneNumber)
			}
			let hangupAction = () => {
				this.props.hangupById(phoneNumber)
			}

			pushCallNotification(callerId, phoneNumber, answerAction, hangupAction)
		}

		//if there is no longer a reason to have controls expanded, collapse so icon changes.
		if ((!this.props.backgroundCalls || this.props.backgroundCalls.length < 1) && !this.props.currentCall && this.state.isExpanded) {
			this.setState({ isExpanded: false })
		}
	}

	logConnectionStats() {
		this.setState({ connectionStatusActive: true })
	}

	answerCall = async () => {
		//specific to callbar, should not be auto answering without ID anywhere else.
		//TODO: could we auto assign current call when there isnt one or would that conflict with another scenario?
		if (this.props.currentCall) {
			//change answerbyId
			await this.props.answerById(this.props.currentCall.callId)
		} else if (this.props.incomingCallsCnt > 0) {
			let info = null
			let calls = this.props.getIncomingCalls()
			let call = calls[0]
			if (call) {
				let participant = call.participants[0]
				info = participant.phoneNumber
			}
			if (info) {
				await this.props.answerById(info)
			}
		}
	}

	openDialer = () => {
		//should this be added to openDialer() instead?
		if (this.props.currentCall) {
			this.props.openDialer()
		} else {
			console.log("no active callID, dialer route blocked")
		}
	}

	renderDialpad = () => {
		const { classes, screenViewType } = this.props
		return (
			<div
				className={`${classes.dialpadWrapper} ${
					!screenViewType.isMobileView
						? classes.callControlCallBarButton
						: classes.callControlCallBarContainer
				}`}
				onClick={this.showDialpad}
			>
				<DialpadIcon
					id="call-bar-dialpad-button"
					style={{ height: 30, width: 25, cursor: "pointer", color: "white" }}
					classes={{ root: classes.dialpadIcon }}
					style={{ color: screenViewType.isMobileView ? "black" : "white" }}
					// onClick={this.showDialpad}
					data-test-id={"call-bar-dialer-button"}
				/>
				<Dialpad
					enableDTMF={true}
					style={{ zIndex: 10001, position: "relative" }}
					open={this.state.isDialpadShown}
					anchorEl={document.getElementById("call-bar-dialpad-button")}
					onClickAway={() => {
						gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'hide-dialpad'})
						this.setState({ isDialpadShown: false })
					}}
					// onClick		= {value => this.props.onChangeInput(`${this.props.inputValue}${value}`)}
					onSubmit={() => this.props.submitEntry(this.props.inputValue)}
					sendDTMF={this.props.sendDTMF}
					currentCall={this.props.currentCall}
				/>
			</div>
		)
	}

	showDialpad = () => {
		gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'show-dialpad'})
		this.setState({ isDialpadShown: true })
	}

	toggleShowCallSessionSelector = () => {
		this.setState({ isExpanded: !this.state.isExpanded })
	}

	onMuteToggle = () => {
		if (this.props.currentCall) {
			this.props.muteLocal(!this.props.isMutedLocal)
		}
	}

	onEndCallClick = (e) => {
		// ignore toggling call menu when clicking end call
		e.stopPropagation()
		const { currentCall } = this.props
		if (this.state.isExpanded) this.toggleShowCallSessionSelector()
		this.props.hangupById(currentCall.callId, true)
	}

	renderCallSelector = () => {
		const { activeCallId, switchCall, mergeCall, backgroundCalls, currentCall, hold, unhold } = this.props
		if (this.state.isExpanded && (currentCall || backgroundCalls.length > 0)) {
			return (
				<CallSessionSelector
					sessions={backgroundCalls}
					currentCall={currentCall}
					activeCallId={activeCallId}
					switchCall={switchCall}
					mergeCall={mergeCall}
					answerById={this.props.answerById}
					hangupById={this.props.hangupById}
					hold={this.props.hold}
					unhold={this.props.unhold}
					isMobile={this.props.screenViewType.isMobileView}
					isExpanded={this.state.isExpanded}
					toggleShowCallSessionSelector={this.toggleShowCallSessionSelector}
					supportsById={this.props.supportsById}
				/>
			)
		}
	}

	renderCallerInfo = () => {
		let phoneNumber = ""
		let callerId = ""
		const { currentCall, incomingCallsCnt, getIncomingCalls } = this.props
		if (currentCall && currentCall.participants[0]) {
			let participant = currentCall.participants[0]
			callerId = participant.callerId || participant.phoneNumber
			phoneNumber = participant.phoneNumber
		} else if (incomingCallsCnt > 0) {
			let calls = getIncomingCalls()
			let call = calls[0]
			if (call) {
				let participant = call.participants[0]
				callerId = participant.callerId || participant.phoneNumber
				phoneNumber = participant.phoneNumber
			}
		}
		return (
			<DefaultArrowTooltip title={formatPhoneNumber(phoneNumber)} placement="top">
				<span>{callerId}</span>
			</DefaultArrowTooltip>
		)
	}

	renderCallBar = () => {
		const { isExpanded } = this.state
		return (
			<CallBarContentDesktop
				isExpanded={isExpanded}
				toggleShowCallSessionSelector={this.toggleShowCallSessionSelector}
				currentCall={this.props.currentCall}
				backgroundCalls={this.props.backgroundCalls}
				renderCallSelector={this.renderCallSelector}
				onEndCallClick={this.onEndCallClick}
				hangupById={this.props.hangupById}
				openDialer={this.openDialer}
				isMutedLocal={this.props.isMutedLocal}
				onMuteToggle={this.onMuteToggle}
				openMakeACall={this.props.openMakeACall}
				supportsById={this.props.supportsById}

				callStats={this.props.callStats}
				renderDialpad={this.renderDialpad}
				callsOnHold={this.props.callsOnHold}
			/>
		)
	}

	renderMobileCallBar = () => {
		const { isExpanded } = this.state
		return (
			<CallBarContentMobile
				isExpanded={isExpanded}
				isDialerOpen={this.props.isDialerOpen}
				toggleShowCallSessionSelector={this.toggleShowCallSessionSelector}
				currentCall={this.props.currentCall}
				backgroundCalls={this.props.backgroundCalls}
				renderCallSelector={this.renderCallSelector}
				onEndCallClick={this.onEndCallClick}
				hangupById={this.props.hangupById}
				openDialer={this.openDialer}
				isMutedLocal={this.props.isMutedLocal}
				onMuteToggle={this.onMuteToggle}
				openMakeACall={this.props.openMakeACall}
				callsOnHold={this.props.callsOnHold}
				supportsById={this.props.supportsById}
			/>
		)
	}

	render() {
		const { screenViewType } = this.props
		let smallView = this.props.screenViewType.isMobileView
		let middleView = this.props.screenViewType.isTabletView

		let showCallBar = (context) => {
			//hide the callbar if the provider says outbound calling is supported
			//i hardcoded is_virtual to false because we want to show the callbar
			//for virtual extensions so users learn about the feature and can upgrade
			let callFeatures = context.supports(false);
			return callFeatures ? callFeatures.get('outboundCalling') : false;
		}

		return (
			<PdcCallConsumer>
				{(context) => (
					<div>
						{ showCallBar(context) ?
							!screenViewType.isMobileView ? this.renderCallBar() : this.renderMobileCallBar()
							: null
						}
						{this.props.isRinging ? <audio id="ringtone" src={ringSound} loop autoPlay></audio> : ""}
					</div>
				)}
			</PdcCallConsumer>
		)
	}
}

export default withStyles(styles)(CallBar)
