import React, { Component } from 'react'
import { formatAsYouType } from 'phone-numbers'
import { ExpandTriangle, XIcon } from 'pdc-svg-icons'
import styles from './styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { withStyles } from '@material-ui/core'

class TextField extends Component {

	constructor(props) {
		super(props)
		this.state = {active: false}
		this.inputRef = props.inputRef || React.createRef()
	}

	componentDidUpdate = prevProps => {
		if (this.caretPos || this.caretPos === 0) {
			if (this.props.type === 'phone-number-us') this.fixCaretPhoneNumberUs(prevProps)
			delete this.caretPos
		}
	}

	onFocus = () => {
		if (this.props.onInputFocus) this.props.onInputFocus()
		// We need the 1ms timeout because in some cases
		// the ClickAwayListener fires immediately after this and instead of active it becomes inactive
		setTimeout(() => this.setState({active: true}), 1)
	}

	fixCaretPhoneNumberUs = prevProps => {
		if (this.props.content.length - this.caretPos > 2 || prevProps.content.length >= this.props.content.length ) {
			this.setCaretPosition(this.inputRef.current, this.caretPos)
		}
	}

	getCaretPosition = elem => {
		let iCaretPos = 0
		// IE support
		if (document.selection) {
			elem.focus()
			let oSel = document.selection.createRange()
			oSel.moveStart('character', -elem.value.length)
			iCaretPos = oSel.text.length
		}
		// Other browsers support
		else if (elem.selectionStart || elem.selectionStart == '0') {
			iCaretPos = elem.selectionDirection === 'backward' ? elem.selectionStart : elem.selectionEnd
		}

		return iCaretPos
	}

	setCaretPosition = (elem, caretPos) => {
		if (elem === null) return
		if (elem.createTextRange) {
			let range = elem.createTextRange()
			range.move('character', caretPos)
			range.select()
		} else if (elem.selectionStart) {
			elem.focus()
			elem.setSelectionRange(caretPos, caretPos)
		} else elem.focus()
	}

	onInputChange = e => {
		let { type, onInputChange } = this.props
		let element = e.target
		let value = element.value
		let caretPos
		if (type === 'phone-number-us') {
			let number	= value.match(/\d/g) || []
			number		= number.join('')
			if (number === '1') return number
			caretPos		= this.getCaretPosition(element)
			this.caretPos	= caretPos
			if (isNaN(number)) value = this.props.content
			else if (number.length > 10) {
				if (this.isDigitChanged(value, this.props.content)) value = formatAsYouType(number.substring(0, 10))
				else value = this.props.content
			}
			else value = formatAsYouType(number)
		}
		onInputChange(value)
	}

	isDigitChanged = (s1, s2) => {
		let s1a = Array.from(s1)
		let s2a = Array.from(s2)
		let pos = -1
		s1a.forEach((c, i) => {
			if (pos !== -1) return
			if (c !== s2a[i]) pos = i
		})
		return ![0, 4, 5, 9].includes(pos)
	}

	renderInput = () => {
		let { id, label, content, multiline, type, uppercase, lowercase } = this.props
		let activeClass		= this.state.active ? 'active' : ''
		let hasLabelClass	= label ? 'has-label' : ''
		let uppercaseClass	= uppercase ? 'uppercase' : ''
		let lowercaseClass	= lowercase ? 'lowercase' : ''
		let TagName			= multiline ? 'textarea' : 'input'
		let classNames		= `edit-element ${TagName} ${activeClass} ${hasLabelClass} ${uppercaseClass} ${lowercaseClass}`
		let idProp			= id ? {id} : {}
		if (type === 'phone-number-us') content = formatAsYouType(content)
		return (
			<TagName
				ref			= {this.inputRef}
				className	= {classNames}
				value		= {content}
				type		= 'text'
				onChange	= {this.onInputChange}
				onFocus		= {this.onFocus}
				onKeyPress	= {e => this.props.onKeyPress ? this.props.onKeyPress(e) : null}
				disabled	= {Boolean(this.props.disabled)}
				{...idProp}
			/>
		)
	}

	onClick = e => {
		let editable = (!('editable' in this.props)) ? true : this.props.editable
		if (editable) {
			this.focusSearch()
			this.setState({active: true})
		} else {
			this.setState({active: !this.state.active})
		}
		if (this.props.onClick) this.props.onClick(e)
	}

	focusSearch = () => this.inputRef.current.focus()

	onXClick = () => {

		let canEditInput = (function () {
			try {
				var t = document.createElement('textarea')
				document.body.appendChild(t)
				t.focus()
				document.execCommand('insertText', false, 'x')
				document.body.removeChild(t)
				return t.value === 'x'
			} catch (e) {return false}
		})()

		if (canEditInput) {
			// We do it like this (focus -> select -> insertText) in order undo / redo to work fine
			this.focusSearch()
			this.inputRef.current.select()
			document.execCommand('insertText', false, '')
			setTimeout(this.focusSearch, 1)
		} else {
			// This happens on Firefox. Because of this the edit history will be lost i.e. the user can't undo / redo.
			this.props.onInputChange('')
		}
	}

	onClickAway = () => this.setState({active: false})

	onBlur = e => {
		e.persist()
		this.setState({active: false})
	}

	onWrapperFocus = e => {
		e.persist()
		let target = e.target
		let editable = (!('editable' in this.props)) ? true : this.props.editable
		let isTheWrapper = Array.from(target.classList).find(c => c.includes('TextField-wrapper'))
		if (isTheWrapper && editable) this.focusSearch()
	}

	render() {
		let {
			classes,
			label,
			content,
			error,
			showExpandIcon,
			boxShadow,
			fullWidth,
			active,
			multiline,
			disabled,
			className,
			flipped
		} = this.props
		let editable = (!('editable' in this.props)) ? true : this.props.editable
		active					= !disabled && [true, false].includes(active) ? active : this.state.active
		let activeClass			= active ? 'active' : ''
		let topClass			= (content || active) ? 'top' : ''
		let boxShadowClass		= boxShadow ? 'box-shadow' : ''
		let textAreaClass		= multiline && (active || content) ? 'textarea-field' : ''
		let textFieldClassNames	= `text-field ${activeClass} ${boxShadowClass} ${textAreaClass}`
		let errorClass			= error ? 'error' : ''
		let wrapperClassNames	= `${classes.wrapper} ${errorClass} ${activeClass}`
		if (className && className.wrapper) wrapperClassNames += ` ${className.wrapper}`
		if (fullWidth) wrapperClassNames += ' full-width'
		if (disabled) wrapperClassNames += ' disabled'
		if (flipped) textFieldClassNames += ` ${classes.frontIndex}`

		let underline = <div className={`button-underline ${activeClass}`}/>
		return (
			<ClickAwayListener onClickAway={this.onClickAway}>
				<div
					className	= {wrapperClassNames}
					onBlur		= {this.onBlur}
					onFocus		= {this.onWrapperFocus}
					tabIndex	= {-1}
				>
					{flipped && underline}
					<div data-test-id={this.props["data-test-id"]} className={textFieldClassNames} onClick={this.onClick}>
						{label ? <div className={`label ${topClass}`}>{label}</div> : null}
						{editable ?
							this.renderInput() :
							content
						}
						{showExpandIcon ? <ExpandTriangle className={`${this.props.flipped ? classes.flippedExpandIcon : classes.expandIcon} expand-icon`}/> : null}
						{editable && !this.props.disabled ? <XIcon className='x-icon' onClick={this.onXClick}/> : null}
					</div>
					{!flipped && underline}
					{error ? <div className='error-label'>{error}</div> : null}
				</div>
			</ClickAwayListener>
		)
	}
}

export default withStyles(styles)(TextField)