import React, { Component } from 'react'
import { PersonAvatar } from 'pdc-svg-icons'
import { withStyles } from '@material-ui/core'
import { theme } from 'get-theme'

const styles = theme => ({
	defaultAvatar: {
		width:			'1em',
		height:			'1em',
		padding:		'0.03em',
		borderRadius:	'50%',
		background:		theme.palette.secondary[0],
		'& > div': {
			width:			'100%',
			height:			'100%',
			background:		theme.palette.secondary[-300],
			borderRadius:	'50%',
			display:		'flex',
			justifyContent:	'center',
			alignItems:		'center',
			padding:		'0.15em',
			'& > svg': {
				width:	'100%',
    			height:	'100%'
			}
		},
		'&.tertiaryAvatar': {
			background: theme.palette.tertiary[-100],
			'& > div': {
				background: theme.palette.tertiary[-200]
			}
		}
	},
	imageAvatar: {
		width:			'1em',
		height:			'1em',
		borderRadius:	'50%'
	}
})

class PersonAvatarComponent extends Component {
	render() {
		let { classes, color, size, url } = this.props
		size = size || 1
		let classNames = `${classes.defaultAvatar} ${color}Avatar`
		return (
			url ? <div><img className={classes.imageAvatar} src={url} style={{fontSize: size}}/></div> :
			<div className={classNames} style={{fontSize: size}}>
				<div>
					<PersonAvatar color={theme.palette[color]['+100']}/>
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(PersonAvatarComponent)