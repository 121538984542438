import React, { Component } from "react"
import { connect } from "react-redux"
import SelectorItem from "selector-item"
import { VoicemailIcon } from "pdc-svg-icons"
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver"
import { updateCall, switchCall } from "../actions/calls"
import ConfirmDeleteModal from "confirm-delete-modal"
import { switchView } from "../actions/view"
import { withStyles } from "@material-ui/core"
import { theme } from "get-theme"
import api from "../util/api_v5.js"
import { convertNumberToE164, isValidPhoneNumber } from 'phone-numbers'
import gtmDataPush from 'gtm-events'

const GTM_APP_NAME = 'calls-selector-item'

const mapStateToProps = (state) => ({
	calls: state.calls,
	currentCall: state.currentCall,
})

const mapDispatchToProps = (dispatch) => ({
	updateCall: (call) => dispatch(updateCall(call)),
	switchCall: (call) => dispatch(switchCall(call)),
	switchView: (view) => dispatch(switchView(view)),
})

const styles = (theme) => ({
	beforeMainText: {
		marginRight: "7px",
		"&>svg": {
			width: "20px",
			height: "20px",
			"&:not(:first-child):last-child": {
				marginLeft: 5,
			},
		},
	},
	recIcon: {
		color: "#88969E",
	},
})

class CallselectorItem extends Component {
	state = { showDeleteCallDialog: false }

	renderRecordingIcon = () => {
		const { classes, call } = this.props
		let recordingUrl = call.recording.url
		let voicemailUrl = call.voicemail.url
		if (!recordingUrl && !voicemailUrl) return
		return (
			<span className={classes.beforeMainText}>
				{recordingUrl ? <RecordVoiceOverIcon classes={{ root: classes.recIcon }} /> : null}
				{voicemailUrl ? <VoicemailIcon /> : null}
			</span>
		)
	}

	getParticipants = () => {
		let call = this.props.call
		let number = call.type === "incoming" ? call.from.number : call.to.number
		let name = ""
		this.props.extraContacts.forEach((cd) => {
			let foundNumber = cd.numbers.find((n) => n.number === number)
			if (!foundNumber) return
			name = cd.name.display
		})
		return [{ number, name }]
	}

	// onDetailButtonClick = (e) => {
	// 	e.stopPropagation()
	// 	let call = this.props.call
	// 	let number	= call.type === 'incoming' ? call.from.number : call.to.number
	// 	this.props.placeCall(call)
	// 	this.props.openDialer()
	// }

	onCallRecentClick = async (e) => {
		e.stopPropagation()
		let call = this.props.call
		let original_number = call.type === "incoming" ? call.from.number : call.to.number

		//update the caller id, then place call.
		let myNumber = call.type === "incoming" ? call.to.number : call.from.number

		//verify ext exists in obj or not necessary?
		let ext = this.props.extension
		console.log(ext)

		let number = convertNumberToE164(original_number)
		console.log("place call", number, myNumber, ext)
		if(isValidPhoneNumber(myNumber) || myNumber.toLowerCase() === 'private') {
			if(this.props.myCallerInfo && myNumber !== this.props.myCallerInfo.phoneNumber) 
				await this.props.setMyCallerInfo(myNumber)
			if(isValidPhoneNumber(number)){
				this.props.placeCall(number)
			} else {
				this.props.placeCall(original_number)
			}
			this.props.openDialer()
		}

		gtmDataPush({PDC_Action: GTM_APP_NAME, PDC_Label: 'call-button-click'})

		//select row from selector.
		//grab outgoing number, and our callerID at the time.

		//set that number as active caller ID, then plce call to that number
		// this.props.placeCall()
	}

	getCallTypeLabel(callType) {
		let callTypes = {
			'incoming': 'Incoming',
			'outgoing': 'Outgoing',
		};
		if (callTypes[callType]) {
			return callTypes[callType];
		}
		return 'Unknown';
	}

	render() {
		let { call } = this.props
		let styles = {
			mainText: {
				textTransform: "uppercase",
				fontWeight: "bold",
				color: theme.palette.primary.main,
			},
		}

		return (
			<>
				<SelectorItem
					mainText={this.getCallTypeLabel(call.type)}
					beforeMainText={this.renderRecordingIcon()}
					participants={this.getParticipants()}
					timestamp={call.start_time}
					showMyNums={false}
					active={this.props.active}
					isNew={false}
					bold={false}
					onClick={this.props.onClick}
					styles={styles}
					renderDropdownMenu={null}
					setRef={this.props.setRef}
					onDetailButtonClick={this.onCallRecentClick}
					isDetailButtonDisabled={!this.props.canPlaceOutgoingCall}
					dataTestId={this.props.dataTestId}
				/>
				<ConfirmDeleteModal
					itemType="call"
					isShown={this.state.showDeleteCallDialog}
					acknowledgedTitle="Permanently delete call"
					notAcknowledgedTitle="Permanently delete call"
					acknowledgedMainContent=""
					notAcknowledgedMainContent=""
					onClose={this.hideDialog}
					onDelete={this.deleteAndSwitchCall}
				/>
			</>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CallselectorItem))
